import { CarIndex, Car } from '../app/types/admin';
import { OptionBase } from '../app/common/InputField/SelectTypes';
import { adminSearch, fetchWithAuthorization } from './api';
import { handleApiCallAndParseData } from './utils';

export const createOptionFromResponseCar = (car: Car) => {
    return {
        label: getCarLabel(car),
        value: car.id,
    };
};

export const createIndexOptionFromResponseCar: (
    car: CarIndex
) => OptionBase<CarIndex> = (car: CarIndex) => {
    return {
        label: getCarIndexLabel(car),
        value: car,
    };
};

export const getCarIndexLabel = (car: CarIndex) =>
    `#${car.carNumber} ${car.model} (${car.licensePlate})`;

export const getCarLabel = (car: Car) =>
    `#${car.carNumber} ${car.model?.name} (${car.licensePlate})`;

export type NamedEntity = {
    name: string;
};
export const createIndexOptionFromResponseNamedEntity = <
    EntityType extends NamedEntity
>(
    entity: EntityType
) => {
    return {
        label: `${entity.name}`,
        value: entity,
    };
};

export function simpleAdminSearch(
    url: string,
    query: string,
    auth: string,
    options?: Object
) {
    return fetchWithAuthorization(
        `${url}search?size=20${
            query ? `&q=${encodeURIComponent(`${query}`.toLowerCase())}` : ''
        }${
            options !== undefined && Object.entries(options).length
                ? Object.entries(options)
                      .filter(
                          ([field, value]: [string, any]) => value !== undefined
                      )
                      .map(
                          ([field, value]: [string, any]) =>
                              `&${encodeURIComponent(
                                  field
                              )}=${encodeURIComponent(value)}`
                      )
                      .join('')
                : ''
        }`,
        auth
    );
}

export function extraSearchOptionSearch(
    authorization: string | undefined,
    inputValue: string,
    url: string
): Promise<OptionBase<any>[]> {
    return new Promise((resolve) =>
        handleApiCallAndParseData(
            adminSearch(url, inputValue, authorization),
            undefined,
            (data: { label: string }[]) =>
                resolve(
                    data.map((d) => ({
                        label: d.label,
                        value: d,
                    }))
                ),
            console.error
        )
    );
}
