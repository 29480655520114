import React, { useCallback, useEffect, useState } from 'react';
import * as L from 'leaflet';
import {
    DivIcon,
    GeoJSON,
    LatLng,
    LatLngBounds,
    latLngBounds,
    LatLngBoundsExpression,
    LocationEvent,
    Marker,
} from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import circleIcon from '../../../resources/mapcircle.png';
import circleShadow from '../../../resources/mapcircleShadow.png';
// OBS, det kan være et problem med å importe leaflet.css på denne måte, vi trenger da å gjøre som dette: (https://github.com/PaulLeCam/react-leaflet/issues/255)
import '../../../../node_modules/leaflet/dist/leaflet.css';
import cityBikeCircleIcon from '../../../app/common/icon/cityBikeInCircle.svg';
import { ReactComponent as CityBikeIcon } from '../../../app/common/icon/cityBike.svg';
import { ReactComponent as CityBikeStopIcon } from '../../../app/common/icon/cityBikeStop.svg';
import { renderToString } from 'react-dom/server';
import {
    geojsonAreDifferent,
    geoJsonToLatLng,
    geoJsonToLayer,
} from '../../../utilities/utils';
import useEffectOnce from '../../../utilities/UseEffectOnce';
import { Reservation } from '../../types/common';
import { DeleLocation, MapPosition } from '../../types/application';
import { CityBikeSpot, Theme } from '../../../utilities/types';
import { AppDispatch, State } from '../../../state/store';
import { selectTheme } from '../../duck/selectors';
import { connect } from 'react-redux';
import {
    selectCityBikes,
    selectSelectedCar,
} from '../../pages/SearchPage/duck/selectors';
import { Car } from '../../types/search';
import { setMapPosition } from '../../pages/SearchPage/duck/operations';

interface ExtendedMarkerOptions {
    carId?: string;
    isChosen?: boolean;
    maxAvailability?: number;
}

type CarMarker = Marker & ExtendedMarkerOptions;

type MapProps = {
    selectedPosition?: DeleLocation;
    getCarAvailabilityById?: (licensePlate: string) => number;
    carMarkers?: CarMarker[];
    cityBikes?: CityBikeSpot[];
    showCityBikes?: boolean;
    parkingLocation?: GeoJSON.GeoJsonObject;
    noUserLocation?: boolean;
    reservation?: Reservation;
    setMapPosition: (position: MapPosition) => void;
    mapPosition: MapPosition;
    locationMarker?: DeleLocation;
    noAttribution?: boolean;
    bounds?: LatLngBounds;
    theme: Theme;
    getCarIcon?: (
        url: string | undefined,
        availability: number,
        isChosen: boolean
    ) => DivIcon;
    selectedCar?: Car;
};

const MapComponent = (props: MapProps) => {
    const [locating, setLocating] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [previousPosition, setPreviousPosition] = useState<
        LatLng | undefined
    >(undefined);

    const userLocationRef = React.useRef<LatLng | null>(null);

    const mapRef = React.useRef<L.Map | null>(null);
    const previousBoundsRef = React.useRef<LatLngBounds | null>(null);

    const oldMarkers = React.useRef<CarMarker[]>([]);
    const oldCarMarkers = React.useRef<L.MarkerClusterGroup | null>(null);

    const bikeOverlaysRef = React.useRef<L.ImageOverlay[]>([]);
    const locationMarkerRef = React.useRef<L.CircleMarker | L.GeoJSON | null>(
        null
    );

    const selectedPositionMarkerRef = React.useRef<Marker | null>(null);
    const oldSelectedPositionRef = React.useRef<DeleLocation | null>(null);
    const previousMapPositionRef = React.useRef<MapPosition | null>(null);

    const currentPositionRef = React.useRef<Marker | null>(null);
    const currentAccuracyRef = React.useRef<L.Circle | null>(null);

    const carMarkers = props.carMarkers ?? [];
    const cityBikes = props.showCityBikes ? props.cityBikes : [];

    useEffectOnce(() => {
        const mbUrl =
            'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmlscGFyYXBseWVuIiwiYSI6ImNsM2Uzb2ZwbTA4ZnIza253cTJxOGx1cGoifQ.duC_3RI6EeXrIz2JDKs_Zg';
        const streets = L.tileLayer(mbUrl, {
            id: 'mapbox/streets-v11',
            tileSize: 512,
            zoomOffset: -1,
            attribution: props.noAttribution
                ? undefined
                : 'Map data &copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors, ' +
                  '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                  'Imagery © <a href="https://mapbox.com">Mapbox</a>',
        });

        mapRef.current = L.map('map', {
            layers: [streets],
            center: props.mapPosition.latlng,
            maxBounds: props.bounds,
            minZoom: 2,
            maxZoom: 18,
            zoom: props.mapPosition.zoom,
        });

        setupMapListeners();
        userLocationSetup();
        if (!props.noUserLocation) findUserLocation();
        calculateBounds();
        // React.forceUpdate(); // in order to render markers after map has been created
        return () => {
            mapRef.current?.remove();
            mapRef.current = null;
        };
    });

    const calculateBounds = useCallback(() => {
        let markerBounds = latLngBounds([]);
        if (userLocationRef.current) {
            markerBounds.extend(userLocationRef.current);
        }
        if (props.mapPosition.latlng) {
            markerBounds.extend(props.mapPosition.latlng);
        }

        if (props.parkingLocation) {
            markerBounds.extend(L.geoJSON(props.parkingLocation).getBounds());
        }

        if (
            markerBounds.isValid() &&
            previousBoundsRef.current &&
            !markerBounds.equals(previousBoundsRef.current)
        ) {
            mapRef.current?.fitBounds(markerBounds);
            previousBoundsRef.current = markerBounds;
        }
    }, [props.mapPosition, props.parkingLocation]);

    useEffect(() => {
        calculateBounds();
        setUpdated(true);
    }, [calculateBounds, updated]); //TODO check deps

    const markersHaveChanged = (newMarkers: CarMarker[]) => {
        if (
            !Array.isArray(oldMarkers.current) ||
            oldMarkers.current.length !== newMarkers.length
        )
            return true;
        return newMarkers.some((newMarker) =>
            hasNoEqualInGroup(newMarker, oldMarkers.current)
        );
    };

    const hasNoEqualInGroup = (newMarker: CarMarker, oldMarkers: CarMarker[]) =>
        !oldMarkers.some((m) => markersAreEqual(m, newMarker));

    const markersAreEqual = (m1: CarMarker, m2: CarMarker) =>
        m1.carId === m2.carId;

    const updateMarkerIcons = () => {
        // @ts-ignore
        oldCarMarkers.current?._featureGroup.eachLayer((l: L.MarkerCluster) => {
            if (l.getAllChildMarkers) {
                l.getAllChildMarkers().forEach((m: CarMarker) => {
                    updateMarkerIcon(m);
                });
                // @ts-ignore
                l._updateIcon && l._updateIcon();
            } else {
                updateMarkerIcon(l);
            }
        });
    };

    const updateMarkerIcon = (m: CarMarker) => {
        if (!props.getCarAvailabilityById || !props.getCarIcon) return;
        if (m.setIcon && m.options && m.options.title) {
            const chosen = props.selectedCar?.licensePlate === m.options.title;
            let wasChosen = m.isChosen;
            const updatedAvailability = props.getCarAvailabilityById(
                m.options.title
            );
            if (
                chosen !== wasChosen ||
                (m.maxAvailability !== undefined &&
                    !availabilityValuesAreEquivalent(
                        m.maxAvailability,
                        updatedAvailability
                    ))
            ) {
                m.isChosen = chosen;
                m.maxAvailability = updatedAvailability;
                m.setIcon(
                    props.getCarIcon(m.options.alt, updatedAvailability, chosen)
                );
            }
        }
    };

    const availabilityValuesAreEquivalent = (val1: number, val2: number) =>
        val1 === val2 || (val1 < 100 && val2 < 100 && val1 > 0 && val2 < 0);

    const handleParkingLocation = () => {
        mapRef.current?.addLayer(geoJsonToLayer(props.parkingLocation));
    };

    const handleBikeMarkers = () => {
        const oldOverlayMap = bikeOverlaysRef.current || null;
        const newOverlayMap: L.ImageOverlay[] = [];
        const oldOverlayCount = oldOverlayMap.length;

        if (
            cityBikes !== undefined &&
            Array.isArray(cityBikes) &&
            cityBikes.length > 0 &&
            cityBikes.length !== oldOverlayCount
        ) {
            cityBikes.forEach((bikeSpot) =>
                handleBikeSpot(bikeSpot, newOverlayMap, oldOverlayMap)
            );

            bikeOverlaysRef.current = newOverlayMap;
            return;
        }

        if (
            bikeOverlaysRef.current &&
            bikeOverlaysRef.current.length > 0 &&
            mapRef.current &&
            (cityBikes === undefined || cityBikes.length === 0)
        ) {
            for (const obj of bikeOverlaysRef.current) {
                obj.removeFrom(mapRef.current);
            }
            bikeOverlaysRef.current = [];
        }
    };

    const checkOverlay = (
        bikeSpot: CityBikeSpot,
        newOverlayMap: L.ImageOverlay[],
        oldOverlayMap: L.ImageOverlay[]
    ) => {
        // @ts-ignore
        const oldOverlay = oldOverlayMap[bikeSpot.id] as
            | {
                  overlay: L.ImageOverlay;
                  bikesAvailable: number;
                  spacesAvailable: number;
              }
            | undefined;
        if (!oldOverlay) return true;

        if (
            oldOverlay.bikesAvailable === bikeSpot.bikesAvailable &&
            oldOverlay.spacesAvailable === bikeSpot.spacesAvailable
        ) {
            // @ts-ignore
            newOverlayMap[bikeSpot.id] = oldOverlay;
            newOverlayMap.push(oldOverlay.overlay);
            return false;
        }

        if (mapRef.current !== null) {
            oldOverlay.overlay.removeFrom(mapRef.current);
        }
        return true;
    };

    const handleBikeSpot = (
        bikeSpot: CityBikeSpot,
        newOverlayMap: L.ImageOverlay[],
        oldOverlayMap: L.ImageOverlay[]
    ) => {
        const shouldDrawNewBikeStopMarker = checkOverlay(
            bikeSpot,
            newOverlayMap,
            oldOverlayMap
        );
        if (!shouldDrawNewBikeStopMarker) return;
        const size = 0.0003;

        const lat = bikeSpot.latitude,
            lng = bikeSpot.longitude;
        const imageBounds: LatLngBoundsExpression = [
            [lat + size, lng + size],
            [lat - size, lng - size],
        ];
        const overlay: L.ImageOverlay = L.imageOverlay(
            cityBikeCircleIcon,
            imageBounds,
            {
                interactive: true,
            }
        );
        const bikesAvailable = bikeSpot.bikesAvailable;
        const spacesAvailable = bikeSpot.spacesAvailable;

        // @ts-ignore
        newOverlayMap[bikeSpot.id] = {
            overlay,
            bikesAvailable,
            spacesAvailable,
        };
        newOverlayMap.push(overlay);
        const popupContent = renderToString(
            <div className="bikePopup">
                <div
                    className={'bikePopup__heading'}
                    style={props.theme.text?.h5}
                >
                    {bikeSpot.name}
                </div>
                <div
                    className={'bikePopup__infoGroup'}
                    style={props.theme.text?.body1}
                >
                    <CityBikeIcon />
                    <span>{bikeSpot.bikesAvailable}</span>
                    <span>
                        {bikeSpot.bikesAvailable === 1 ? 'sykkel' : 'sykler'}
                    </span>
                </div>
                <div
                    className={'bikePopup__infoGroup'}
                    style={props.theme.text?.body1}
                >
                    <CityBikeStopIcon />
                    <span>{bikeSpot.spacesAvailable}</span>
                    <span>ledige plasser</span>
                </div>
                <a
                    className={'bikePopup__link'}
                    href={'https://bergenbysykkel.no/'}
                    target={'_blank'}
                    rel="noopener noreferrer"
                    style={props.theme.text?.link}
                >
                    bergenbysykkel.no
                </a>
            </div>
        );

        overlay.bindPopup(popupContent, {});
        if (mapRef.current) overlay.addTo(mapRef.current);
    };

    const handleLocationMarker = (locationMarker: DeleLocation) => {
        if (!locationMarker || !mapRef.current) return;

        if (locationMarkerRef.current) {
            if (
                // @ts-ignore
                locationMarkerRef.current.options.title ===
                    locationMarker.name &&
                !geojsonAreDifferent(
                    // @ts-ignore
                    locationMarkerRef.current?.options.geoJson,
                    locationMarker.geojson
                )
            )
                return;

            locationMarkerRef.current.removeFrom(mapRef.current);
        }

        let markerProperties = {
            color: 'red',
            fillColor: 'red',
            fillOpacity: 0.5,
            title: locationMarker.name,
            geoJson: locationMarker.geojson,
        };

        if (locationMarker.geojson?.type === 'Point') {
            if (
                !locationMarker.id ||
                !(locationMarker.id === 'gps-position-object-id')
            ) {
                let latlng: LatLng = geoJsonToLatLng(locationMarker.geojson);
                locationMarkerRef.current = L.circleMarker(latlng, {
                    ...markerProperties,
                    radius: 10,
                }).addTo(mapRef.current);
            }
        } else {
            locationMarkerRef.current = L.geoJSON(locationMarker.geojson, {
                style: markerProperties,
            }).addTo(mapRef.current);
        }
    };

    const handleCarMarkers = () => {
        if (markersHaveChanged(carMarkers)) {
            oldMarkers.current = carMarkers;

            const carMarkersLayer = L.markerClusterGroup({
                iconCreateFunction: function (cluster) {
                    // cluster is green if it contains at least one green car.
                    // if not, it's yellow if it contains at least one yellow car.
                    // if not, it's white: all its cars are white.
                    let maxAvailability = cluster
                        .getAllChildMarkers()
                        .reduce((acc, marker: CarMarker) => {
                            return Math.max(acc, marker.maxAvailability ?? 0);
                        }, 0);
                    let availabilityClass =
                        maxAvailability === 0
                            ? 'marker-cluster-unavailable'
                            : maxAvailability < 100
                            ? 'marker-cluster-partial'
                            : '';

                    return L.divIcon({
                        html: `<div><span>${cluster.getChildCount()}</span></div>`,
                        className:
                            'marker-cluster marker-cluster-small ' +
                            availabilityClass,
                        iconSize: new L.Point(40, 40),
                    });
                },
                maxClusterRadius: 40,
                spiderfyDistanceMultiplier: 1.5,
            });

            if (oldCarMarkers.current && mapRef.current) {
                mapRef.current.removeLayer(oldCarMarkers.current);
            }
            carMarkers.forEach((layer) => {
                carMarkersLayer.addLayer(layer);
            });

            mapRef.current?.addLayer(carMarkersLayer);
            oldCarMarkers.current = carMarkersLayer;
        }
        updateMarkerIcons();

        if (props.selectedPosition) {
            if (
                !oldSelectedPositionRef.current ||
                !(oldSelectedPositionRef.current === props.selectedPosition)
            ) {
                if (selectedPositionMarkerRef.current != null)
                    mapRef.current?.removeLayer(
                        selectedPositionMarkerRef.current
                    );
                if (props.selectedPosition) {
                    handleLocationMarker(props.selectedPosition);
                }
                oldSelectedPositionRef.current = props.selectedPosition;
            }
        }
    };

    const handleMapCentering = () => {
        const newMapPosition = props.mapPosition;
        if (
            JSON.stringify(previousMapPositionRef.current) !==
            JSON.stringify(newMapPosition)
        ) {
            if (newMapPosition.accuracy) {
                updateUserLocation(
                    newMapPosition.latlng,
                    newMapPosition.accuracy
                );
            }
            previousMapPositionRef.current = newMapPosition;
            mapRef.current?.setView(newMapPosition.latlng, newMapPosition.zoom);
        }
    };

    const setupMapListeners = () => {
        if (!mapRef.current) return;
        mapRef.current.on('moveend', () => {
            if (updated && mapRef.current) {
                const mapCenter = mapRef.current.getCenter();
                const zoom = mapRef.current.getZoom();
                // const latlng = { lat: mapCenter.lat, lng: mapCenter.lng };
                props.setMapPosition &&
                    props.setMapPosition({ latlng: mapCenter, zoom });
            }
        });
    };

    const userLocationSetup = () => {
        mapRef.current?.on('locationfound', onUserLocationFound);
        mapRef.current?.on('locationerror', onFindUserLocationError);
    };

    const findUserLocation = () => {
        if (!locating) {
            setLocating(true);
            mapRef.current?.locate();
        }
    };

    const onFindUserLocationError = (_: any) => {
        setLocating(false);
    };

    const onUserLocationFound = (e: LocationEvent) => {
        if (mapRef.current) {
            updateUserLocation(e.latlng, e.accuracy);
            userLocationRef.current = e.latlng;
        }
        setLocating(false);
    };

    // UPDATE USER LOCATION MARKER:
    const updateUserLocation = (latlng: LatLng, accuracy: number) => {
        if (!mapRef.current) return;
        if (
            !previousPosition ||
            JSON.stringify(previousPosition) !== JSON.stringify(latlng)
        ) {
            if (currentPositionRef.current !== null) {
                currentPositionRef.current.setLatLng(latlng);
            } else {
                currentPositionRef.current = L.marker(latlng, {
                    icon: getUserLocationIcon(),
                }).addTo(mapRef.current);
            }
            setPreviousPosition(latlng);
            if (currentAccuracyRef.current) {
                mapRef.current.removeLayer(currentAccuracyRef.current);
            }
            const radius = !!accuracy ? accuracy / 2 : 100;

            currentAccuracyRef.current = L.circle(latlng, {
                stroke: false,
                interactive: false,
                fillColor: '#00aeff',
                fillOpacity: 0.2,
                radius: radius,
            }).addTo(mapRef.current);
            let firstUserPositionUpdate = !userLocationRef.current;
            userLocationRef.current = latlng;
            if (firstUserPositionUpdate) {
                calculateBounds();
            }
        }
    };

    const getUserLocationIcon = () => {
        const radius = 12;
        const iconDiameter = radius * 2;
        const shadowRadius = radius * 1.4;
        const shadowDiameter = shadowRadius * 2;

        return L.icon({
            iconUrl: circleIcon,
            shadowUrl: circleShadow,
            shadowSize: [shadowDiameter, shadowDiameter],
            iconSize: [iconDiameter, iconDiameter],
            shadowAnchor: [shadowRadius * 0.95, shadowRadius * 0.95],
            iconAnchor: [radius, radius],
        });
    };

    if (!!mapRef.current) {
        handleMapCentering();
        if (props.parkingLocation) handleParkingLocation();
        if (carMarkers) handleCarMarkers();
        if (props.locationMarker) handleLocationMarker(props.locationMarker);
        handleBikeMarkers();
    }
    return <div className={'map'} id="map" />;
};

export const Map = connect(
    (state: State) => ({
        theme: selectTheme(state),
        selectedCar: selectSelectedCar(state),
        cityBikes: selectCityBikes(state),
    }),
    (dispatch: AppDispatch) => ({
        setMapPosition: (pos: MapPosition) => dispatch(setMapPosition(pos)),
    })
)(MapComponent);
export default Map;
