import actions from './actions';
import searchActions from '../pages/SearchPage/duck/actions';
import {
    doAcceptInvitation,
    doFetchOrganizations,
    doGcreLogin,
    doJoin,
    doLinkVippsUser,
    doLogin,
    doLogout,
    doResetPassword,
    doSendPasswordRetrievalEmail,
    doVippsJoin,
    fetchCars,
    postSubResource,
    whoAmI,
} from '../../utilities/api';
import {
    selectAuthentication,
    selectPerformAfterLogin,
    selectSelectedMembership,
    selectShowIncorrectLoginFeedback,
    selectSwapCarFilters,
    selectSwapCarFiltersAreActive,
    selectSwapCarPosition,
    selectSwapCarTimes,
    selectWhoamiResponse,
} from './selectors';
import {
    HEADING_ADMIN,
    HEADING_ADMIN_ASSIGNMENTS,
    HEADING_ADMIN_BILLING,
    HEADING_ADMIN_BILLING_SUMMARY,
    HEADING_ADMIN_CAR,
    HEADING_ADMIN_CARS,
    HEADING_ADMIN_DAMAGE,
    HEADING_ADMIN_DAMAGES,
    HEADING_ADMIN_FILE_UPLOAD,
    HEADING_ADMIN_LOCATION,
    HEADING_ADMIN_LOCATIONS,
    HEADING_ADMIN_MEMBER_CARD,
    HEADING_ADMIN_MEMBER_CARDS,
    HEADING_ADMIN_MEMBERSHIP,
    HEADING_ADMIN_MEMBERSHIPS,
    HEADING_ADMIN_HELP_OVERVIEW,
    HEADING_ADMIN_HELP_EDIT,
    HEADING_ADMIN_HELP_EDIT_SPECIFIC,
    HEADING_ADMIN_MODEL,
    HEADING_ADMIN_MODELS,
    HEADING_ADMIN_PERSON,
    HEADING_ADMIN_PERSONS,
    HEADING_ADMIN_RESERVATION,
    HEADING_ADMIN_RESERVATIONS,
    HEADING_ADMIN_TRIPLETEX_ERRORS,
    HEADING_CHOOSE_CAR,
    HEADING_FORGOT_PASSWORD,
    HEADING_INVOICES,
    HEADING_JOIN,
    HEADING_MEMBERSHIP,
    HEADING_MESSAGES,
    HEADING_PROFILE,
    HEADING_RESERVATIONS,
    HEADING_SHARED_RESERVATION,
    PAGE_ADMIN,
    PAGE_ADMIN_ASSIGNMENTS,
    PAGE_ADMIN_BILLING,
    PAGE_ADMIN_BILLING_SUMMARY,
    PAGE_ADMIN_CAR,
    PAGE_ADMIN_CARS,
    PAGE_ADMIN_DAMAGE,
    PAGE_ADMIN_DAMAGES,
    PAGE_ADMIN_FILE_UPLOAD,
    PAGE_ADMIN_LOCATION,
    PAGE_ADMIN_LOCATIONS,
    PAGE_ADMIN_MEMBER_CARD,
    PAGE_ADMIN_MEMBER_CARDS,
    PAGE_ADMIN_MEMBERSHIP,
    PAGE_ADMIN_MEMBERSHIPS,
    PAGE_ADMIN_HELP_OVERVIEW,
    PAGE_ADMIN_HELP_EDIT,
    PAGE_ADMIN_HELP_EDIT_SPECIFIC,
    PAGE_ADMIN_MODEL,
    PAGE_ADMIN_MODELS,
    PAGE_ADMIN_PERSON,
    PAGE_ADMIN_PERSONS,
    PAGE_ADMIN_RESERVATION,
    PAGE_ADMIN_RESERVATIONS,
    PAGE_ADMIN_TRIPLETEX_ERRORS,
    PAGE_CHOOSE_CAR,
    PAGE_CHOOSE_CAR__BOOKING_PAGE,
    PAGE_FORGOT_PASSWORD,
    PAGE_INVOICES,
    PAGE_JOIN,
    PAGE_MEMBERSHIP,
    PAGE_MESSAGES,
    PAGE_PROFILE,
    PAGE_RESERVATIONS,
    PAGE_SHARED_RESERVATION,
    PAGE_ADMIN_HELP_SPECIFIC_OVERVIEW,
    HEADING_ADMIN_HELP_SPECIFIC_OVERVIEW,
    PAGE_ADMIN_CAR_IMAGES,
    HEADING_ADMIN_CAR_IMAGES,
    PAGE_ADMIN_NEW_CAR_IMAGE,
    HEADING_ADMIN_NEW_CAR_IMAGE,
    PAGE_ADMIN_TRIPLETEX_CONFIG,
    HEADING_ADMIN_TRIPLETEX_CONFIG,
} from './constants';
import {
    checkConcurrentReservationLimit,
    getFilters,
    getGpsPosition,
    initSearch,
    updateDefaultPosition,
} from '../pages/SearchPage/duck/operations';
import { fetchLocations, selectLocations } from '../pages/LocationPage/api';
import {
    checkIfFiltersAreActive,
    emptyStringsToNull,
    filterSearchFilters,
    handleApiCallAndParseData,
    isSame,
} from '../../utilities/utils';
import {
    fetchActiveReservations,
    fetchMembershipReservations,
} from '../pages/ReservationsPage/duck/operations';
import moment from 'moment';
import { fetchUserInfo } from '../pages/ProfilePage/duck/operations';
import {
    identifyUser,
    reportAppLogin,
    reportAppLogout,
} from '../../utilities/wrapperUtils';
import { createApiCallObject, handleErrors } from '../../utilities/apiUtils';
import { peekWaypoint } from '../common/WaypointLink/WaypointLink';
import {
    clearReservationData,
    setShouldRedirectToReservations,
} from '../pages/ReservationsPage/duck/reservationReducers';

export const setTheme = actions.setTheme;

// Fetching data should only be fetched once
export const initApp = () => {
    return (dispatch) => {
        dispatch(actions.initApp());

        if (!navigator.onLine) return;

        dispatch(getFilters());
        dispatch(fetchLocations());
        dispatch(fetchOrganizations());
    };
};

export function setPage(page, optionalText) {
    return (dispatch) => {
        switch (page) {
            case PAGE_SHARED_RESERVATION:
                dispatch(
                    actions.setPage({
                        page: PAGE_SHARED_RESERVATION,
                        heading: HEADING_SHARED_RESERVATION,
                    })
                );
                break;
            case PAGE_RESERVATIONS:
                dispatch(
                    actions.setPage({
                        page: PAGE_RESERVATIONS,
                        heading: HEADING_RESERVATIONS,
                    })
                );
                break;
            case PAGE_INVOICES:
                dispatch(
                    actions.setPage({
                        page: PAGE_INVOICES,
                        heading: HEADING_INVOICES,
                    })
                );
                break;
            case PAGE_MESSAGES:
                dispatch(
                    actions.setPage({
                        page: PAGE_MESSAGES,
                        heading: HEADING_MESSAGES,
                    })
                );
                break;
            case PAGE_FORGOT_PASSWORD:
                dispatch(
                    actions.setPage({
                        page: PAGE_FORGOT_PASSWORD,
                        heading: HEADING_FORGOT_PASSWORD,
                    })
                );
                break;
            case PAGE_PROFILE:
                dispatch(
                    actions.setPage({
                        page: PAGE_PROFILE,
                        heading: HEADING_PROFILE,
                    })
                );
                break;
            case PAGE_MEMBERSHIP:
                dispatch(
                    actions.setPage({
                        page: PAGE_MEMBERSHIP,
                        heading: HEADING_MEMBERSHIP,
                    })
                );
                break;
            case PAGE_JOIN:
                dispatch(
                    actions.setPage({
                        page: PAGE_JOIN,
                        heading: HEADING_JOIN,
                    })
                );
                break;
            case PAGE_CHOOSE_CAR__BOOKING_PAGE:
                dispatch(
                    actions.setPage({
                        page: PAGE_CHOOSE_CAR__BOOKING_PAGE,
                        heading: HEADING_CHOOSE_CAR,
                    })
                );
                break;
            case PAGE_ADMIN:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: HEADING_ADMIN,
                    })
                );
                break;
            case PAGE_ADMIN_RESERVATIONS:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: HEADING_ADMIN_RESERVATIONS,
                    })
                );
                break;
            case PAGE_ADMIN_RESERVATION:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: optionalText
                            ? optionalText
                            : HEADING_ADMIN_RESERVATION,
                    })
                );
                break;
            case PAGE_ADMIN_DAMAGES:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: optionalText
                            ? optionalText
                            : HEADING_ADMIN_DAMAGES,
                    })
                );
                break;
            case PAGE_ADMIN_DAMAGE:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: optionalText
                            ? optionalText
                            : HEADING_ADMIN_DAMAGE,
                    })
                );
                break;
            case PAGE_ADMIN_MEMBERSHIPS:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: HEADING_ADMIN_MEMBERSHIPS,
                    })
                );
                break;
            case PAGE_ADMIN_MEMBERSHIP:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: optionalText
                            ? optionalText
                            : HEADING_ADMIN_MEMBERSHIP,
                    })
                );
                break;
            case PAGE_ADMIN_HELP_SPECIFIC_OVERVIEW:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: HEADING_ADMIN_HELP_SPECIFIC_OVERVIEW,
                    })
                );
                break;
            case PAGE_ADMIN_HELP_OVERVIEW:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: HEADING_ADMIN_HELP_OVERVIEW,
                    })
                );
                break;
            case PAGE_ADMIN_HELP_EDIT:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: HEADING_ADMIN_HELP_EDIT,
                    })
                );
                break;
            case PAGE_ADMIN_HELP_EDIT_SPECIFIC:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: HEADING_ADMIN_HELP_EDIT_SPECIFIC,
                    })
                );
                break;
            case PAGE_ADMIN_BILLING:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: HEADING_ADMIN_BILLING,
                    })
                );
                break;
            case PAGE_ADMIN_BILLING_SUMMARY:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: HEADING_ADMIN_BILLING_SUMMARY,
                    })
                );
                break;
            case PAGE_ADMIN_PERSONS:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: HEADING_ADMIN_PERSONS,
                    })
                );
                break;
            case PAGE_ADMIN_PERSON:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: optionalText
                            ? optionalText
                            : HEADING_ADMIN_PERSON,
                    })
                );
                break;
            case PAGE_ADMIN_ASSIGNMENTS:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: optionalText
                            ? optionalText
                            : HEADING_ADMIN_ASSIGNMENTS,
                    })
                );
                break;
            case PAGE_ADMIN_CARS:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: optionalText
                            ? optionalText
                            : HEADING_ADMIN_CARS,
                    })
                );
                break;
            case PAGE_ADMIN_CAR:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: optionalText
                            ? optionalText
                            : HEADING_ADMIN_CAR,
                    })
                );
                break;
            case PAGE_ADMIN_MODELS:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: optionalText
                            ? optionalText
                            : HEADING_ADMIN_MODELS,
                    })
                );
                break;
            case PAGE_ADMIN_LOCATION:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: optionalText
                            ? optionalText
                            : HEADING_ADMIN_LOCATION,
                    })
                );
                break;
            case PAGE_ADMIN_LOCATIONS:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: optionalText
                            ? optionalText
                            : HEADING_ADMIN_LOCATIONS,
                    })
                );
                break;
            case PAGE_ADMIN_FILE_UPLOAD:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: optionalText
                            ? optionalText
                            : HEADING_ADMIN_FILE_UPLOAD,
                    })
                );
                break;
            case PAGE_ADMIN_MODEL:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: optionalText
                            ? optionalText
                            : HEADING_ADMIN_MODEL,
                    })
                );
                break;
            case PAGE_ADMIN_MEMBER_CARDS:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: optionalText
                            ? optionalText
                            : HEADING_ADMIN_MEMBER_CARDS,
                    })
                );
                break;
            case PAGE_ADMIN_MEMBER_CARD:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: optionalText
                            ? optionalText
                            : HEADING_ADMIN_MEMBER_CARD,
                    })
                );
                break;
            case PAGE_ADMIN_TRIPLETEX_ERRORS:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: HEADING_ADMIN_TRIPLETEX_ERRORS,
                    })
                );
                break;
            case PAGE_ADMIN_TRIPLETEX_CONFIG:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: HEADING_ADMIN_TRIPLETEX_CONFIG,
                    })
                );
                break;
            case PAGE_ADMIN_CAR_IMAGES:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: HEADING_ADMIN_CAR_IMAGES,
                    })
                );
                break;
            case PAGE_ADMIN_NEW_CAR_IMAGE:
                dispatch(
                    actions.setPage({
                        page: PAGE_ADMIN,
                        heading: HEADING_ADMIN_NEW_CAR_IMAGE,
                    })
                );
                break;
            default:
                //case PAGE_CHOOSE_CAR
                dispatch(
                    actions.setPage({
                        page: PAGE_CHOOSE_CAR,
                        heading: optionalText
                            ? optionalText
                            : HEADING_CHOOSE_CAR,
                    })
                );
                break;
        }
    };
}

export function setShouldAuthenticate(shouldAuthenticate) {
    return (dispatch) => {
        dispatch(actions.setShouldAuthenticate(shouldAuthenticate));
    };
}

export function updateWhoAmIAndLogoutIfAuthExpired(fetchUserInfoIfLoggedIn) {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            let auth = selectAuthentication(getState());
            if (navigator.onLine && auth) {
                whoAmI(auth).then((response) => {
                    handleErrors(response, dispatch).then(
                        (okResponse) => {
                            okResponse.json().then(
                                (json) => {
                                    dispatch(actions.whoamiResponse(json));
                                    dispatch(updateMembershipSelection(json));
                                    if (fetchUserInfoIfLoggedIn)
                                        dispatch(fetchUserInfo());
                                    resolve();
                                },
                                (e) => {
                                    console.log(e);
                                    dispatch(logout());
                                }
                            );
                        },
                        (e) => {
                            console.log(e);
                            dispatch(logout());
                        }
                    );
                });
            }
        });
    };
}

export const updateShellAppLogin = ({ id, name, email, cards }) => {
    const chipId =
        cards === undefined || cards.length < 1 ? '' : cards[0].chipId;
    identifyUser(id, name, email, chipId);
};

export const join = (membership, invitationToken) => {
    return (dispatch, getState) => {
        const apiCall = invitationToken ? doAcceptInvitation : doJoin;
        const body = invitationToken
            ? { invitationToken }
            : emptyStringsToNull(membership);
        dispatch(actions.joinPending());
        apiCall(selectAuthentication(getState()), body).then(
            (response) => {
                response.json().then(
                    (json) => {
                        if (response.ok) {
                            dispatch(actions.joinCompleted(json.entity));
                        } else {
                            if (json.errors?.length > 0)
                                dispatch(actions.joinError(json.errors));
                            else dispatch(actions.joinError(json.error));
                        }
                    },
                    (error) => {
                        console.log(error);
                        dispatch(actions.joinError(error));
                    }
                );
            },
            (error) => {
                console.log(error);
                dispatch(actions.joinError());
            }
        );
    };
};

export const resetJoinResult = () => {
    return (dispatch) => {
        dispatch(actions.resetJoinResult());
    };
};

export function updateMembershipSelection(whoAmIResponseArg) {
    return (dispatch, getState) => {
        let whoamiResponse = whoAmIResponseArg
            ? whoAmIResponseArg
            : selectWhoamiResponse(getState());
        let selectedMembership = selectSelectedMembership(getState());
        if (!whoamiResponse) return;
        let memberships = whoamiResponse.memberships;

        // Check if a membership is selected which the user should no longer be part of
        let removedFromMembership = false;
        if (
            selectedMembership &&
            memberships &&
            (memberships.length === 0 ||
                !memberships.find((m) => m.id === selectedMembership.id))
        ) {
            selectedMembership = undefined;
            removedFromMembership = true;
        }

        if (removedFromMembership) {
            dispatch(actions.setSelectedMembership(undefined));
        } else if (memberships && memberships.length > 0) {
            // If memberships are available, select one
            let memberships = whoamiResponse.memberships;
            let membership;
            if (selectedMembership)
                membership = memberships.find(
                    (m) => m.id === selectedMembership.id
                );
            if (!membership) membership = memberships.find((m) => m.default);
            if (!membership) membership = memberships[0];
            if (!isSame(membership, selectedMembership))
                // only set selected membership again if there is change
                dispatch(setSelectedMembership(membership));
        }
    };
}

export function apiResponseError(error) {
    return (dispatch) => {
        // todo: redirect to error page
        dispatch(actions.apiResponseError(error));
    };
}

export const setAuth = (auth) => (dispatch) => {
    dispatch(checkAuthorities(auth));
    dispatch(actions.authenticationCompleted(auth));
};

export const login = (userName, password, vippsId, vippsPhoneNo) => {
    return (dispatch) => {
        dispatch(actions.authenticationPending());
        doLogin(userName, password, vippsId).then(
            (response) => {
                if (response.headers.has('authorization')) {
                    let auth = response.headers.get('authorization');
                    dispatch(actions.authenticationCompleted(auth));
                    dispatch(actions.setUserName(userName));
                    dispatch(performActionAfterLogin());
                    dispatch(fetchUserInfo());
                    dispatch(checkAuthorities());
                    dispatch(
                        setShouldRedirectToReservations(
                            peekWaypoint(auth) === '/' &&
                                response.headers.has(
                                    'x-has-active-reservations'
                                )
                        )
                    );
                    reportAppLogin();
                    if (vippsId) dispatch(linkVippsUser(vippsId, vippsPhoneNo));
                } else {
                    if (response.status === 401 || response.status === 403) {
                        dispatch(setShowIncorrectLoginFeedback(true));
                    }
                    dispatch(actions.authenticationError());
                }
            },
            () => {
                console.log('Authentication error in doLogin');
                dispatch(actions.authenticationError());
            }
        );
    };
};

export const gcreLogin = (token) => {
    return (dispatch) => {
        dispatch(actions.gcreAuthPending());
        doGcreLogin(token).then((response) => {
            if (response.ok) {
                response.json().then((json) => {
                    if (json.authToken && json.authToken.length > 0) {
                        dispatch(actions.gcreAuthCompleted(json));
                        dispatch(
                            actions.authenticationCompleted(json.authToken)
                        );
                        dispatch(checkAuthorities());
                        reportAppLogin();
                    } else {
                        dispatch(actions.gcreAuthError(json));
                    }
                });
            } else {
            }
        });
    };
};

export const completeVippsLogin = (username, token) => {
    return (dispatch) => {
        dispatch(actions.authenticationCompleted(token));
        dispatch(actions.setUserName(username));
        dispatch(performActionAfterLogin());
        dispatch(checkAuthorities());
        reportAppLogin();
    };
};

export const linkVippsUser = (sub, phoneNo) => (dispatch, getState) => {
    dispatch(actions.linkVippsUserPending());
    return handleApiCallAndParseData(
        doLinkVippsUser(
            selectAuthentication(getState()),
            createApiCallObject(dispatch, 'linkVippsUser'),
            sub,
            phoneNo
        ),
        dispatch,
        (parsedData) =>
            parsedData.errors && parsedData.errors.length > 0
                ? dispatch(actions.linkVippsUserError(parsedData.errors))
                : dispatch(actions.linkVippsUserCompleted()),
        (error) => {
            dispatch(actions.linkVippsUserError([error]));
        }
    );
};

export const vippsJoin = (user) => (dispatch) => {
    dispatch(actions.vippsJoinPending());
    doVippsJoin(user).then((response) => {
        response.json().then(
            (json) => {
                if (json.authToken) {
                    dispatch(completeVippsLogin(json.username, json.authToken));
                    dispatch(actions.vippsJoinCompleted(json.authToken));
                } else {
                    console.error(json.errors);
                    dispatch(actions.vippsJoinError(json.errors));
                }
            },
            (e) => {
                console.error(e);
                dispatch(actions.vippsJoinError(e));
            }
        );
    });
};

export const resetVippsJoinError = () => (dispatch) =>
    dispatch(actions.resetVippsJoinError());

export const sendPasswordRetrievalEmail = (usernameEmailOrPhone) => {
    return (dispatch) => {
        dispatch(actions.sendPasswordResetEmailPending());
        doSendPasswordRetrievalEmail(usernameEmailOrPhone).then(
            (response) => {
                if (response.ok) {
                    dispatch(actions.sendPasswordResetEmailCompleted());
                } else {
                    console.log(response);
                    dispatch(actions.sendPasswordResetEmailError());
                }
            },
            (error) => {
                console.log(error);
                dispatch(actions.sendPasswordResetEmailError());
            }
        );
    };
};

export const resetSendPasswordResetEmailResult = () => {
    return (dispatch) => dispatch(actions.resetSendPasswordResetEmailResult());
};

export const resetPassword = (resetPasswordObject) => {
    return (dispatch) => {
        dispatch(actions.resetPasswordPending());
        doResetPassword(resetPasswordObject).then(
            (response) => {
                if (response.ok) {
                    dispatch(actions.resetPasswordCompleted());
                } else {
                    console.log(response);
                    dispatch(actions.resetPasswordError());
                }
            },
            (error) => {
                console.log(error);
                dispatch(actions.resetPasswordError());
            }
        );
    };
};

export const resetResetPasswordResult = () => {
    return (dispatch) => dispatch(actions.resetResetPasswordResult());
};

export function checkAuthorities(auth) {
    return (dispatch, getState) => {
        if (!auth) auth = selectAuthentication(getState());
        if (auth) {
            whoAmI(auth).then((response) => {
                handleErrors(response, dispatch).then(
                    (okResponse) => {
                        okResponse.json().then((json) => {
                            dispatch(actions.whoamiResponse(json));
                            dispatch(updateMembershipSelection(json));
                            const perms = json.organizationPermissions;
                            if (perms) {
                                dispatch(actions.setAdmin(perms?.length > 0));
                            }
                        });
                    },
                    (e) => {
                        console.error(e);
                        dispatch(logout());
                    }
                );
            });
        }
    };
}

export function setSelectedMembership(membership) {
    return (dispatch) => {
        dispatch(actions.setSelectedMembership(membership));
        if (membership) {
            dispatch(updateDefaultPosition(false, membership));
            dispatch(checkConcurrentReservationLimit(membership));
            dispatch(fetchMembershipReservations());
        }
    };
}

export function setSelectedOrganization(org) {
    return (dispatch) => {
        dispatch(actions.setSelectedOrganization(org));
    };
}

export function setPerformAfterLogin(action) {
    return (dispatch) => {
        dispatch(actions.setPerformAfterLogin(action));
    };
}

export function performActionAfterLogin() {
    return (dispatch, getState) => {
        let operation = selectPerformAfterLogin(getState());
        if (operation) {
            operation(dispatch, getState);

            dispatch(actions.clearPerformAfterLogin());
        }
    };
}

export const clearPerformAfterLogin = actions.clearPerformAfterLogin;

export const setShowIncorrectLoginFeedback = (bool) => {
    return (dispatch, getState) => {
        if (selectShowIncorrectLoginFeedback(getState()) !== bool) {
            dispatch(actions.setShowIncorrectLoginFeedback(bool));
        }
    };
};

export const logout = () => {
    return (dispatch, getState) => {
        doLogout(selectAuthentication(getState()));
        dispatch(actions.clearDataState());
        dispatch(clearReservationData());
        dispatch(setSelectedMembership(undefined));
        dispatch(getGpsPosition());
        dispatch(initSearch());
        updateShellAppLogin({ id: '', name: '', email: '', chipId: '' });
        reportAppLogout();
    };
};

export const toggleShowHamburgerMenu = actions.toggleShowHamburgerMenu;

export const showHamburgerMenu = (show) => (dispatch) =>
    dispatch(actions.showHamburgerMenu(show));

// Operations for testing
export function setInvalidAuthorization() {
    return (dispatch) => {
        let wrongAuth = 'Bearer fake-auth-for-testing';
        dispatch(actions.authenticationCompleted(wrongAuth));
        return `Authorization set to: '${wrongAuth}'`;
    };
}

export function fetchOrganizations() {
    return (dispatch) => {
        dispatch(actions.fetchOrganizationsPending());
        return handleApiCallAndParseData(
            doFetchOrganizations(
                createApiCallObject(dispatch, 'fetchOrganizations')
            ),
            dispatch,
            (parsedData) => {
                dispatch(actions.fetchOrganizationsCompleted(parsedData));
            },
            (error) => {
                dispatch(actions.fetchOrganizationsError());
                console.log(error);
            }
        );
    };
}

// SWAP CAR OPERATIONS

export function setSwapCarReservation(reservation) {
    return (dispatch) => dispatch(actions.setSwapCarReservation(reservation));
}

export function swapCarSearch(searchDetails) {
    return (dispatch, getState) => {
        const location =
            searchDetails.location ||
            selectLocations(getState()).find(
                (l) => l.name === searchDetails.locationName
            );
        if (!location) {
            console.log('NO LOCATION in swapCarSearch!');
            return false;
        }

        dispatch(actions.setSwapCarPosition(location));

        let times = {
            start: searchDetails.start,
            end: searchDetails.end,
        };
        if (!times || !times.start || !times.end) {
            console.log('NO TIME in swapCarSearch!');
            return false;
        }
        dispatch(actions.setSwapCarTimes(times));
        dispatch(findSwapCarOptions({ location, ...times }));
    };
}

export function setSwapCarPosition(location) {
    return (dispatch) => {
        dispatch(actions.setSwapCarPosition(location));
        dispatch(findSwapCarOptions({ location }));
    };
}

export function findSwapCarOptions(searchDetails) {
    return (dispatch, getState) => {
        dispatch(searchActions.fetchSwappableCarsPending());
        let position = searchDetails
            ? searchDetails.location
            : selectSwapCarPosition(getState());

        if (!position) return dispatch(updateDefaultPosition());
        let searchParams = { ...selectSwapCarTimes(getState()) };

        if (moment(searchParams.start) < moment()) {
            searchParams.start = moment();
        }

        return handleApiCallAndParseData(
            fetchCars(
                searchParams.start,
                searchParams.end,
                position,
                filterSearchFilters(selectSwapCarFilters(getState())),
                '100',
                selectAuthentication(getState()),
                createApiCallObject(dispatch, 'findSwapCarOptions')
            ),
            dispatch,
            (parsedData) => {
                dispatch(
                    searchActions.fetchSwappableCarsCompleted(parsedData.cars)
                );
                dispatch(searchActions.setShowingSwapCarOptions(true));
            },
            (error) => {
                dispatch(searchActions.fetchSwappableCarsError());
                console.log(error);
            }
        );
    };
}

export function updateSwapCarFiltersAreActive(filters) {
    return (dispatch, getState) => {
        let filtersWereActive = selectSwapCarFiltersAreActive(getState());
        if (!filters) filters = selectSwapCarFilters(getState());
        let filtersAreActive = !!checkIfFiltersAreActive(filters);
        if (filtersAreActive !== filtersWereActive)
            dispatch(actions.setSwapCarFiltersAreActive(filtersAreActive));
    };
}

export function setSwapCarFilters(swapCarFilters) {
    return (dispatch) => {
        dispatch(updateSwapCarFiltersAreActive(swapCarFilters));
        dispatch(actions.setSwapCarFilters(swapCarFilters));
        dispatch(findSwapCarOptions());
    };
}

export function clearSwapCarFilters() {
    return (dispatch, getState) => {
        let newFilters = { ...selectSwapCarFilters(getState()) };
        newFilters.groups = newFilters.groups.map((group) => {
            let newGroup = { ...group };
            newGroup.values = newGroup.values.map((value) => {
                let newValue = { ...value };
                newValue.selected = false;
                return newValue;
            });
            return newGroup;
        });
        newFilters.maxSeats = 9;
        newFilters.minSeats = 1;
        dispatch(setSwapCarFilters(newFilters));
    };
}

export function userSwapCar(reservationId, newCarId, onSuccess, shared) {
    return (dispatch, getState) => {
        dispatch(actions.swapCarPending());
        return handleApiCallAndParseData(
            postSubResource(
                selectAuthentication(getState()),
                'reservations',
                reservationId,
                'change-car',
                { newCarId },
                createApiCallObject(dispatch, 'userSwapCar')
            ),
            dispatch,
            (parsedData) => {
                if (parsedData.entity) onSuccess(parsedData.entity);
                dispatch(actions.swapCarCompleted());
                shared || dispatch(fetchActiveReservations());
            },
            (error) => {
                const errorMessage =
                    error.errors && error.errors[0] && error.errors[0].message;
                dispatch(actions.swapCarError(errorMessage));
                console.log(error);
            }
        );
    };
}

export const handlePagination = (page, { totalPages }) => (dispatch) =>
    !totalPages ||
    dispatch(
        actions.fetchingNewPageCompleted({
            page,
            lastPage: (page ?? 0) > totalPages - 2,
        })
    );

export const resetPagination = () => (dispatch) =>
    dispatch(actions.resetPagination());
