import { createApi } from '@reduxjs/toolkit/query/react';
import { TripletexConfiguration, TripletexError } from './types';
import { Metadata } from '../../../../types/admin';
import { authenticatedQuery } from '../../../../../utilities/RTK/RTKUtils';
import { PublicId } from '../../../../types/common';
import {
    CursorPaginatedResult,
    OperationResult,
} from '../../../../../utilities/RTK/RTKTypes';
import { AdminListQueryParams } from '../../../../common/AdminList/AdminListRTK';
import { apiCallSize } from '../../../../../utilities/constants';

export const tripletexApi = createApi({
    reducerPath: 'admin-tripletex',
    baseQuery: authenticatedQuery('/api/admin/tripletex'),
    tagTypes: ['TripletexError', 'TripletexConfig'],
    endpoints: (builder) => ({
        getMetaData: builder.query<Metadata<TripletexError>, void>({
            query: () => `error/metadata`,
        }),
        getTripletexErrors: builder.query<
            CursorPaginatedResult<TripletexError>,
            AdminListQueryParams
        >({
            query: (params) =>
                `error?size=${apiCallSize}${
                    params.cursor ? `&cursor=${params.cursor}` : ''
                }${params.orgId ? `&orgId=${params.orgId}` : ''}${
                    params.filter
                }`,
        }),
        getTripletexErrorCount: builder.query<number, void>({
            query: () => `error/count`,
        }),
        pauseTripletexError: builder.mutation<TripletexError, PublicId>({
            query: (id) => ({
                url: `error/${id}/pause`,
                method: 'PUT',
            }),
        }),
        startTripletexError: builder.mutation<TripletexError, PublicId>({
            query: (id) => ({
                url: `error/${id}/start`,
                method: 'PUT',
            }),
        }),
        syncOrderNumber: builder.mutation<
            OperationResult,
            { errorId: PublicId; orderNumber?: string }
        >({
            query: ({ errorId, orderNumber }) => ({
                url: `error/${errorId}/sync-order-number${
                    orderNumber ? `?orderNumber=${orderNumber}` : ''
                }`,
                method: 'PUT',
            }),
        }),
        syncInvoiceNumber: builder.mutation<
            OperationResult,
            { errorId: PublicId; invoiceNumber: string }
        >({
            query: ({ errorId, invoiceNumber }) => ({
                url: `error/${errorId}/sync-invoice-number?invoiceNumber=${invoiceNumber}`,
                method: 'PUT',
            }),
        }),
        syncProductNumber: builder.mutation<OperationResult, PublicId>({
            query: (id) => ({
                url: `error/${id}/sync-product-number`,
                method: 'PUT',
            }),
        }),
        syncCustomerNumber: builder.mutation<OperationResult, PublicId>({
            query: (id) => ({
                url: `error/${id}/sync-customer-number`,
                method: 'PUT',
            }),
        }),
        markProductAsSynced: builder.mutation<OperationResult, PublicId>({
            query: (id) => ({
                url: `error/${id}/mark-product-synced`,
                method: 'PUT',
            }),
        }),
        markCustomerAsSynced: builder.mutation<OperationResult, PublicId>({
            query: (id) => ({
                url: `error/${id}/mark-customer-synced`,
                method: 'PUT',
            }),
        }),
        overrideInvoiceMethod: builder.mutation<
            OperationResult,
            { errorId: PublicId; invoiceMethod: string }
        >({
            query: ({ errorId, invoiceMethod }) => ({
                url: `error/${errorId}/override-invoice-method?invoiceMethod=${invoiceMethod}`,
                method: 'PUT',
            }),
        }),

        getTripletexConfiguration: builder.query<
            TripletexConfiguration,
            PublicId
        >({
            query: (orgId) => `configuration?organizationId=${orgId}`,
            providesTags: ['TripletexConfig'],
        }),

        putTripletexConfiguration: builder.mutation<
            OperationResult,
            { orgId: PublicId; config: TripletexConfiguration }
        >({
            query: ({ orgId, config }) => ({
                url: `configuration?organizationId=${orgId}`,
                method: 'PUT',
                body: config,
            }),
            invalidatesTags: ['TripletexConfig'],
        }),

        deleteTripletexError: builder.mutation<OperationResult, PublicId>({
            query: (errorId) => ({
                url: `error/delete/${errorId}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetMetaDataQuery,
    useGetTripletexErrorsQuery,
    useGetTripletexErrorCountQuery,
    usePauseTripletexErrorMutation,
    useStartTripletexErrorMutation,
    useSyncOrderNumberMutation,
    useSyncInvoiceNumberMutation,
    useSyncProductNumberMutation,
    useSyncCustomerNumberMutation,
    useMarkProductAsSyncedMutation,
    useMarkCustomerAsSyncedMutation,
    useGetTripletexConfigurationQuery,
    usePutTripletexConfigurationMutation,
    useOverrideInvoiceMethodMutation,
    useDeleteTripletexErrorMutation,
} = tripletexApi;
