import { createApi } from '@reduxjs/toolkit/query/react';
import {
    idQuery,
    constantQuery,
    unauthenticatedQuery,
} from '../../../utilities/RTK/RTKUtils';
import { LocationIndex, PublicId, SimpleLocation } from '../../types/common';
import { State } from '../../../state/store';

export const locationApi = createApi({
    reducerPath: 'locations',
    baseQuery: unauthenticatedQuery('/api/locations'),
    endpoints: (builder) => ({
        getLocation: builder.query<SimpleLocation, PublicId>({
            query: idQuery,
        }),
        getLocations: builder.query<LocationIndex[], void>({
            query: constantQuery,
        }),
    }),
});

export const selectLocations = (state: State): LocationIndex[] =>
    locationApi.endpoints.getLocations.select()(state)?.data ?? [];
export const fetchLocations = locationApi.endpoints.getLocations.initiate;

export const { useGetLocationQuery, useGetLocationsQuery } = locationApi;
