import React from 'react';
import { PAGE_ADMIN, PAGE_ADMIN_DAMAGE } from '../../duck/constants';
import NavButton from '../Buttons/NavButton';
import AdminNewNavItems from './AdminNewNavItems';
import DropdownMenu from '../DropdownMenu';
import { Read, Write } from '../Security/permissions';
import { AuthorizedNavButton } from '../Security/authorizedComponents';
import { Theme } from '../../../utilities/types';
import { connect, useSelector } from 'react-redux';
import { setPage, showHamburgerMenu } from '../../duck/operations';
import {
    selectAdmin,
    selectAuthentication,
    selectPage,
    selectSelectedOrganization,
    selectTheme,
} from '../../duck/selectors';
import { useLocation } from 'react-router-dom';
import { AppDispatch, State } from '../../../state/store';

type Props = {
    theme: Theme;
    auth?: string;
    admin?: boolean;
    page: string;
    showHamburgerMenuFunction: (show: boolean) => void;
    setPage: (page: string) => void;
};

let ref: any;
const AdminNavItemsComponent = (props: Props) => {
    const selectedOrg = useSelector(selectSelectedOrganization);
    const location = useLocation();
    if (!props.auth || !props.admin || props.page !== PAGE_ADMIN) return <></>;

    let navButtons = [
        <NavButton
            key={'adminPage'}
            link={'/admin'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'OVERSIKT'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={true}
            theme={props.theme}
        />,
        <AuthorizedNavButton
            key={'adminReservations'}
            link={'/admin/reservation-management/reservations'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'RESERVASJONER'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={false}
            theme={props.theme}
            requireAny={Read.RESERVATION}
        />,
        <AuthorizedNavButton
            key={'adminReservationsWorkList'}
            link={'/admin/reservation-management/work-list'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'ARBEIDSLISTE'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={false}
            theme={props.theme}
            requireAny={Read.RESERVATION}
        />,
        <AuthorizedNavButton
            key={'adminReservationMessages'}
            link={'/admin/reservation-management/messages'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'MELDINGER'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={false}
            theme={props.theme}
            requireAny={Read.MESSAGE}
        />,
        <NavButton
            key={'adminPersons'}
            link={'/admin/user-management/persons'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'BRUKERE'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={false}
            theme={props.theme}
        />,
        <AuthorizedNavButton
            key={'adminMemberships'}
            link={'/admin/user-management/memberships'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'MEDLEMSKAP'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={false}
            theme={props.theme}
            requireAny={Read.MEMBERSHIP}
        />,
        <NavButton
            key={'adminMemberCards'}
            link={'/admin/user-management/member-cards'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'NØKKELKORT'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={false}
            theme={props.theme}
        />,
        <AuthorizedNavButton
            key={'adminCars'}
            link={'/admin/fleet-management/cars'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'BILER'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={false}
            theme={props.theme}
            requireAny={Read.FLEET_MANAGEMENT}
        />,
        <AuthorizedNavButton
            key={'adminAssignments'}
            link={'/admin/fleet-management/assignments'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'PLASSERINGER'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={false}
            theme={props.theme}
            requireAny={Read.FLEET_MANAGEMENT}
        />,
        <AuthorizedNavButton
            key={'adminLocations'}
            link={'/admin/fleet-management/locations'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'LOKASJONER'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={false}
            theme={props.theme}
            requireAny={Read.FLEET_MANAGEMENT}
        />,
        <AuthorizedNavButton
            key={'adminModels'}
            link={'/admin/fleet-management/models'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'BILMODELLER'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={false}
            theme={props.theme}
            requireAny={Read.FLEET_MANAGEMENT}
        />,
        <AuthorizedNavButton
            key={'adminCarImages'}
            link={'/admin/fleet-management/car-images'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'BIL-BILDER'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={true}
            theme={props.theme}
            requireAny={Read.FLEET_MANAGEMENT}
        />,
        <AuthorizedNavButton
            key={'adminEntityMessages'}
            link={'/admin/fleet-management/entity-messages'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'POPUPMELDINGER'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={false}
            theme={props.theme}
            requireAny={Read.FLEET_MANAGEMENT}
        />,
        <AuthorizedNavButton
            key={'adminBilling'}
            link={{
                pathname: '/admin/billing-management/billing',
                search: 'needsReview=true',
            }}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'FAKTURAGRUNNLAG'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={true}
            theme={props.theme}
            requireAny={Read.BILLING}
        />,
        <AuthorizedNavButton
            key={'adminImport'}
            link={'/admin/billing-management/import'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'FILIMPORT'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={true}
            theme={props.theme}
            requireAny={Write.BILLING}
        />,
        <AuthorizedNavButton
            key={'adminBillingExpenses'}
            link={'/admin/billing-management/expenses'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'UTGIFTER'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={true}
            theme={props.theme}
            requireAny={Read.BILLING}
        />,
        <AuthorizedNavButton
            key={'adminInvoice'}
            link={'/admin/billing-management/invoice'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'SEND FAKTURAER'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={true}
            theme={props.theme}
            requireAny={Write.BILLING}
        />,
        <AuthorizedNavButton
            key={'adminInvoices'}
            link={'/admin/billing-management/invoices'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'FAKTURAER'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={true}
            theme={props.theme}
            requireAny={Read.BILLING}
        />,
        <AuthorizedNavButton
            key={'adminProducts'}
            link={'/admin/billing-management/products'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'PRODUKTER'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={true}
            theme={props.theme}
            requireAny={Read.BILLING}
        />,
        <AuthorizedNavButton
            key={'tripletexErrors'}
            link={'/admin/billing-management/tripletex-errors'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'TRIPLETEX-FEIL'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={true}
            theme={props.theme}
            requireAny={Read.BILLING}
            condition={selectedOrg?.accountingSystem === 'TRIPLETEX'}
        />,
        <AuthorizedNavButton
            key={'tripletexConfiguration'}
            link={'/admin/billing-management/tripletex-configuration'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'TRIPLETEX-OPPSETT'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={true}
            theme={props.theme}
            requireAny={Write.ADMIN_SETTINGS}
            condition={selectedOrg?.accountingSystem === 'TRIPLETEX'}
        />,
        <AuthorizedNavButton
            key={'damages'}
            link={'/admin/damages'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN_DAMAGE);
            }}
            label={'SKADER'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={true}
            theme={props.theme}
            requireAny={Read.FLEET_MANAGEMENT}
        />,
        <AuthorizedNavButton
            key={'adminHelpSpecific'}
            link={'/admin/help/overview'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'OVERSIKT'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={false}
            theme={props.theme}
            requireAny={Read.HELP}
        />,
        <AuthorizedNavButton
            key={'adminHelp'}
            link={'/admin/help/edit'}
            onClick={() => {
                props.showHamburgerMenuFunction(false);
                props.setPage(PAGE_ADMIN);
            }}
            label={'REDIGER'}
            styleOverride={{
                ...props.theme.text?.icon_menu,
            }}
            exact={false}
            theme={props.theme}
            requireAny={Read.HELP}
        />,
    ];

    let splitPath = (str: string) => str.split('/').filter((s) => s.length > 0);
    let currentPathLevels = splitPath(location.pathname);
    navButtons = navButtons.filter((navButton) => {
        let link = navButton.props.link;
        link = link.pathname || link;
        let buttonLinkLevels = splitPath(link);
        return buttonLinkLevels[1] === currentPathLevels[1];
    });
    if (navButtons.length === 1) navButtons = [];
    return (
        <div className="adminNavBar u-menuShadow u-displayDesktopOnly">
            <div
                className={'adminNavBar__leftContent'}
                style={{ display: 'flex' }}
                ref={(node) => (ref = node)}
                onWheel={(event) => ref && ref.scrollBy(event.deltaY, 0)}
            >
                {navButtons}
            </div>
            <div style={{ display: 'flex', position: 'relative' }}>
                <DropdownMenu
                    onClick={() => {
                        props.showHamburgerMenuFunction(false);
                    }}
                    menuButton={
                        <NavButton
                            key={'adminCreate'}
                            type={'admin'}
                            label={'NY'}
                            styleOverride={{
                                ...props.theme.text?.icon_menu,
                                backgroundColor: '#5E5E6F',
                            }}
                            exact={true}
                            theme={props.theme}
                        />
                    }
                >
                    <AdminNewNavItems {...props} theme={props.theme} />
                </DropdownMenu>
                <AuthorizedNavButton
                    key={'adminSearch'}
                    type={'admin'}
                    link={'/admin/search'}
                    onClick={() => {
                        props.showHamburgerMenuFunction(false);
                        props.setPage(PAGE_ADMIN);
                    }}
                    label={'SØK'}
                    styleOverride={{
                        ...props.theme.text?.icon_menu,
                        backgroundColor: '#5E5E6F',
                    }}
                    exact={true}
                    theme={props.theme}
                    requireAny={[
                        Read.RESERVATION,
                        Read.FLEET_MANAGEMENT,
                        Read.MEMBERSHIP,
                    ]}
                />
            </div>
        </div>
    );
};

const AdminNavItems = connect(
    (state: State) => ({
        theme: selectTheme(state),
        auth: selectAuthentication(state),
        admin: selectAdmin(state),
        page: selectPage(state),
    }),
    (dispatch: AppDispatch) => ({
        showHamburgerMenuFunction: (show: boolean) =>
            dispatch(showHamburgerMenu(show)),
        setPage: (page: string) => dispatch(setPage(page)),
    })
)(AdminNavItemsComponent);

export default AdminNavItems;
