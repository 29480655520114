import { AppDispatch, State } from '../../state/store';
import { Theme } from '../../utilities/types';
import {
    CarSearchFilters,
    ErrorMessage,
    Membership,
    Organization,
    PublicId,
    Reservation,
    WhoAmI,
} from '../types/common';
import { ApiCallResult, DeleLocation } from '../types/application';
import { GcreAuthResponse } from '../components/Partners/Gcre/GcreLandingComponent';
import { GrantedPermissions } from '../common/Security/permissions';
import { Moment } from 'moment';

export const selectTheme = (state: State): Theme => state.app.ui.theme;
export const selectShowHamburgerMenu = (state: State): boolean =>
    state.app.ui.showHamburgerMenu;
export const selectHeading = (state: State): string => state.app.ui.heading;

/* Pagination selectors */
export const selectFetchingNewPage = (state: State): boolean =>
    state.app.pagination.fetchingNewPage;
export const selectLastPage = (state: State): boolean | undefined =>
    state.app.pagination.lastPage;
export const selectPageNumber = (state: State): number | undefined =>
    state.app.pagination.pageNumber;

export const selectJoinPending = (state: State): boolean =>
    state.app.data.joinPending;
export const selectJoinResult = (state: State): ApiCallResult =>
    state.app.data.joinResult;
export const selectJoinedMembership = (state: State): Membership | undefined =>
    state.app.data.joinedMembership;
export const selectJoinErrors = (state: State) => state.app.data.joinErrors;

export const selectGcreAuthPending = (state: State): boolean | undefined =>
    state.app.data.gcreAuthPending;
export const selectGcreAuthResult = (state: State): ApiCallResult =>
    state.app.data.gcreAuthResult;
export const selectGcreAuthResponse = (
    state: State
): GcreAuthResponse | undefined => state.app.data.gcreAuthResponse;

export const selectAuthentication = (state: State): string | undefined =>
    state.app.data.authentication;
export const selectShouldAuthenticate = (state: State): boolean =>
    state.app.data.shouldAuthenticate;

export const selectVippsJoinPending = (state: State): boolean =>
    state.app.data.vippsJoinPending;
export const selectVippsJoinResult = (state: State): ApiCallResult =>
    state.app.data.vippsJoinResult;
export const selectVippsJoinErrors = (
    state: State
): ErrorMessage[] | undefined => state.app.data.vippsJoinErrors;

export const selectLinkVippsUserPending = (state: State): boolean =>
    state.app.data.linkVippsUserPending;
export const selectLinkVippsUserResult = (state: State): ApiCallResult =>
    state.app.data.linkVippsUserResult;
export const selectLinkVippsUserErrors = (state: State) =>
    state.app.data.linkVippsUserErrors;

export const selectShowIncorrectLoginFeedback = (
    state: State
): boolean | undefined => state.app.ui.showIncorrectLoginFeedback;
export const selectPerformAfterLogin = (
    state: State
): ((dispatch: AppDispatch, getState: () => State) => void) | undefined =>
    state.app.data.performAfterLogin;
export const selectUserName = (state: State): string | undefined =>
    state.app.data.userName;
export const selectAdmin = (state: State): boolean | undefined =>
    state.app.data.admin;

export const selectSendPasswordResetEmailPending = (
    state: State
): boolean | undefined => state.app.data.sendPasswordResetEmailPending;
export const selectSendPasswordResetEmailResult = (
    state: State
): ApiCallResult => state.app.data.sendPasswordResetEmailResult;

export const selectResetPasswordPending = (state: State): boolean | undefined =>
    state.app.data.resetPasswordPending;
export const selectResetPasswordResult = (state: State): ApiCallResult =>
    state.app.data.resetPasswordResult;

export const selectPage = (state: State): string => state.app.ui.page;

export const selectWhoamiResponse = (state: State): WhoAmI | undefined =>
    state.app.data.whoamiResponse;

export const selectPermissions = (state: State): GrantedPermissions[] =>
    selectWhoamiResponse(state)?.organizationPermissions ?? [];

export const selectOrganizations = (state: State): Organization[] =>
    state.app.data.organizations || [];

export const selectOrganizationForReservation = (
    state: State
): Organization | undefined => {
    if (!state.reservationsReducer.data.reservation) return;
    const organizations = selectOrganizations(state);
    for (const organization of organizations) {
        if (
            organization.id ===
            state.reservationsReducer.data.reservation.organizationId
        )
            return organization;
    }
};

export const selectSelectedOrganization = (
    state: State
): Organization | undefined => {
    if (state.app.data.selectedOrganization) {
        return state.app.data.selectedOrganization;
    }
    const organizations = selectOrganizations(state);
    if (organizations.length === 1) {
        return organizations[0];
    }
};

export const selectMemberships = (state: State): Membership[] =>
    selectWhoamiResponse(state)?.memberships ?? [];

export const selectFetchingOrganizations = (state: State): boolean =>
    state.app.data.fetchingOrganizations;

export const selectFetchOrganizationsResult = (state: State): ApiCallResult =>
    state.app.data.fetchOrganizationsResult;

export const selectSelectedMembership = (
    state: State
): Membership | undefined => state.app.data.selectedMembership;

export const selectSelectedMembershipId = (
    state: State
): PublicId | undefined => {
    return selectSelectedMembership(state)?.id;
};

export const selectMembershipOrPersonName = (state: State): string => {
    return (
        selectSelectedMembership(state)?.name ??
        selectWhoamiResponse(state)?.person?.name ??
        ' '
    );
};

export const selectSortedMemberships = (state: State): Membership[] => {
    const m = selectMemberships(state);
    if (!m) return [];
    return [...m].sort((m1, m2) =>
        m1.isOwner && !m2.isOwner ? -1 : m2.isOwner && !m1.isOwner ? 1 : 0
    );
};

export const selectSwapCarReservation = (
    state: State
): Reservation | undefined => state.app.swapCar.swapCarReservation;
export const selectSwappingCar = (state: State): boolean =>
    state.app.swapCar.swappingCar;
export const selectSwapCarResult = (state: State): ApiCallResult =>
    state.app.swapCar.swapCarResult;
export const selectSwapCarErrorMessage = (state: State): string | undefined =>
    state.app.swapCar.swapCarErrorMessage;

export const selectSwapCarPosition = (state: State): DeleLocation | undefined =>
    state.app.swapCar.swapCarPosition;
export const selectSwapCarTimes = (
    state: State
): { start: Moment; end: Moment } | undefined => state.app.swapCar.swapCarTimes;

export const selectSwapCarFilters = (
    state: State
): CarSearchFilters | undefined => state.app.swapCar.swapCarFilters;
export const selectSwapCarFiltersAreActive = (
    state: State
): boolean | undefined => state.app.swapCar.swapCarFiltersAreActive;
