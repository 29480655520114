/*eslint-disable no-useless-escape*/
import * as L from 'leaflet';
import moment from 'moment';
import {
    doFetchProducts,
    fetchAdminCars,
    fetchAdminMemberships,
    fetchAdminPersons,
    logErrorFromPromise,
    logErrorString,
    searchAdminReservations,
    simpleFetchAdminLocations,
    simpleFetchAdminModels,
    simpleFetchAdminReservations,
} from './api';
import {
    apiResponseError,
    setShouldAuthenticate,
} from '../app/duck/operations';
import { apiCallError, apiCallSuccess } from './constants';
import { reportUnauthorizedAttempt } from '../app/pages/ErrorPage/duck/reducer';
import {
    createIndexOptionFromResponseCar,
    createIndexOptionFromResponseNamedEntity,
} from './searchUtils';
import { CLOCK_FORMAT } from './timeUtils';

export const exists = (v) => ![null, undefined].includes(v);

export const strcmp = (str1, str2) => (str1 < str2 ? -1 : +(str1 > str2));

export const getSafeMutableInvoice = (billingData, invoiceId) => {
    let invoiceIndex = billingData.findIndex((i) => i.id === invoiceId);
    return (billingData[invoiceIndex] = { ...billingData[invoiceIndex] });
};

export const getFetcher = (url, authorization) => async () => {
    const init = authorization ? { headers: { authorization } } : undefined;
    const result = await fetch(url, init);
    return result.json();
};

export const parseSearch = (search) =>
    search
        .substr(1)
        .split('&')
        .map((s) => s.split('='))
        .reduce((acc, pair) => {
            return {
                ...acc,
                [pair[0]]: pair[1],
            };
        }, {});

export const stringClean = (s) => (s ? s.trim() : '');

export const nullOrEmptyStringToDash = (str) =>
    str && str.length > 0 ? str : '-';

export const isNotBlank = (str) => !!str && !!str.trim();
export const isBlank = (str) => !isNotBlank(str);

export const notNull = (value) => value || '';
export const concat = (...strings) => {
    let stringArray =
        strings[0] && Array.isArray(strings[0]) ? strings[0] : strings;
    return stringArray.map(notNull).join('').trim();
};

export const trimToNull = (value) => {
    if (Array.isArray(value)) return value.map(emptyStringsToNull);
    if (value === false || moment.isMoment(value)) return value;
    if (!value) return null;
    switch (typeof value) {
        case 'object':
            return emptyStringsToNull(value);
        case 'string':
            value = value.trim();
            if (!value) return null;
            break;
        default:
            break;
    }
    return value;
};

export const emptyStringsToNull = (objArg) => {
    if (typeof objArg == 'string') return trimToNull(objArg);
    if (!objArg) return;
    let obj = {};
    Object.keys(objArg).forEach((key) => (obj[key] = trimToNull(objArg[key])));
    return obj;
};

export const accentFold = (inStr) => {
    return inStr
        .toLowerCase()
        .replace(/([àáâãä])/, 'a')
        .replace(/([ç])/, 'c')
        .replace(/([èéêë])/, 'e')
        .replace(/([ìíîï])/, 'i')
        .replace(/([ñ])/, 'n')
        .replace(/([òóôõö])/, 'o')
        .replace(/([ß])/, 's')
        .replace(/([ùúûü])/, 'u')
        .replace(/([ÿ])/, 'y')
        .trim();
};

export const locationToAdminListQuery = (location) =>
    location.location.search.replace('?', '&');

export const getCarAirDistanceString = (car) =>
    getDistanceString(car.location.distance);

export const shortName = (nameArg) => {
    let name = nameArg.split(' ').join('').toUpperCase();
    return name.substr(0, 4);
};

export const getDistanceString = (distance) => {
    distance = Math.round(distance);
    let unit = 'm';
    if (!!distance || distance === 0) {
        if (distance >= 1000) {
            unit = 'km';
            // Slice of meters when distance is above 9km
            // This is done because the space on small screens is very limiting
            distance =
                distance > 9900
                    ? distance.toString().slice(0, -3)
                    : roundToOneDecimal(distance / 1000);
        }
        return `${distance} ${unit}`;
    }
    return '?';
};

export const roundToOneDecimal = (number) => {
    return Math.round(number * 10) / 10;
};

export const fetchGpsPosition = () => {
    return new Promise((resolve, reject) => {
        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (geolocationResult) => {
                    resolve(gpsCoordinatesToGeoJson(geolocationResult));
                },
                reject,
                {
                    timeout: 20000,
                }
            );
        } else {
            reject({
                code: 2,
                message:
                    'Geolocation unavailable: not supported in this browser.',
            });
        }
    });
};

export const filterSearchFilters = (filters) => {
    if (!filters) return;
    let groups = filters.groups
        .map((group) => {
            return {
                ...group,
                values: group.values.filter((value) => value.selected),
            };
        })
        .filter(
            (group) => group.values.filter((value) => value.selected).length > 0
        );

    return {
        ...filters,
        groups,
    };
};

export const reservationIsActive = (reservation) => {
    return (
        !reservation ||
        (!reservation.ended &&
            reservation.state !== 'CANCELED' &&
            reservation.state !== 'VOIDED')
    );
};

export const getReservationEffectiveEndString = (reservation) => {
    let { end, ended } = reservation;
    if (!ended) ended = end;
    return getFirstTimeStringAsDateString(end, ended);
};

export const getReservationEffectiveStartString = (reservation) => {
    let { start, started } = reservation;
    if (!started) started = start;
    return getFirstTimeStringAsDateString(start, started);
};

export const getSafeDateOrNull = (dateArg) => {
    if (!dateArg) return null;
    if (dateArg.isValid && dateArg.isValid()) return dateArg;
    const m = moment(dateArg);
    if (m.isValid()) return m;
    return null;
};

const getFirstTimeStringAsDateString = (...times) =>
    getDateStringFromMoment(moment.min(times.map((t) => moment(t))));

export const getCancellationFeeString = (reservation) => {
    let nofee_txt = 'Ingen avbestillingsgebyr siden ';
    let return_txt =
        'Du må betale avbestillingsgebyr; 50% av timene som ' +
        'faller innenfor de neste 48 timer.';
    if (timeStringAgeAsMinutes(reservation.created) <= 60) {
        return_txt =
            nofee_txt + 'du kansellerer innen en time etter bestilling.';
    } else if (
        moment.duration(moment(reservation.start).diff(moment())).asHours() > 48
    ) {
        return_txt = nofee_txt + 'du kansellerer mer enn 48 timer før start.';
    }
    return return_txt;
};

//TODO remove
export const oldReservation = (reservation) => {
    if (reservation.ended) return true;
    return (
        reservation.state === 'PAST' &&
        moment().diff(moment(reservation.end), 'minutes') > 30
    );
};

export const gpsCoordinatesToGeoJson = (gpsCoordinates) => {
    return new Promise((resolve, reject) => {
        const coordinates = gpsCoordinates.coords;
        if (!!coordinates.latitude && !!coordinates.longitude) {
            resolve({
                geojson: {
                    type: 'Point',
                    coordinates: [coordinates.longitude, coordinates.latitude],
                },
                id: 'gps-position-object-id',
                name: 'GPS',
                accuracy: coordinates.accuracy,
            });
        } else {
            reject(Error('Invalid GPS coordinates.'));
        }
    });
};

export const dateToDateAndTimeString = (date /*TODO FLOW:: string*/) => {
    let dateString =
        moment(date).format('dddd').substring(0, 3) +
        ' ' +
        moment(date).format('DD.MM.YY') +
        ', kl ' +
        moment(date).format(CLOCK_FORMAT);
    return dateString.charAt(0).toUpperCase() + dateString.slice(1);
};

export const getDateStringFromMoment = (date /*TODO FLOW:: string*/) => {
    return `${moment(date).format('DD.MM.YYYY HH:mm')}`;
};

export const timeStringAgeAsMinutes = (timeString) => {
    return moment.duration(moment().diff(moment(timeString))).asMinutes();
};

export const stepUp = (history, stateArg) => {
    let path = history.location.pathname;
    let nextPath = path.substring(0, path.lastIndexOf('/'));
    let state = stateArg ? stateArg : {};
    history.history.push(nextPath, state);
};

/**
 * Used to make sure that the minute values of a moment is set to 0 or 30
 * Rounding up to nearest sane value
 */
export const sanitizeMinutes_roundUp = (time /*TODO FLOW:: moment*/) => {
    let saneTime = moment(time);
    if (saneTime.minutes() > 0 && saneTime.minutes() < 30) saneTime.minutes(30);
    else if (saneTime.minutes() > 30) saneTime.add(1, 'h').minutes(0);
    return saneTime;
};
/*
    Methods used to fetch data that is used by async select inputs
    TODO
    These methods should perhaps have been placed somewhere else,
    It is not good practice to have data flow outside redux like this
    But kept as a mvp-solution
     */

export const searchForReservations = (
    auth,
    inputValue,
    orgId,
    index,
    includeNullValue,
    optionalFilters
) => {
    return new Promise((resolve) =>
        handleApiCallAndParseData(
            searchAdminReservations(inputValue, auth, orgId, optionalFilters),
            undefined,
            (parsedData) => {
                let options = parsedData.map(
                    index
                        ? createIndexOptionFromResponseReservation
                        : createOptionFromResponseReservation
                );
                if (includeNullValue)
                    options.unshift({ label: ' - ', value: null });
                resolve(options);
            },
            (error) => {
                console.log(error);
            }
        )
    );
};

export const simpleSearchReservations = (resNumber, auth, orgId) =>
    new Promise((resolve) =>
        handleApiCallAndParseData(
            simpleFetchAdminReservations(resNumber, auth, orgId),
            undefined,
            (parsedData) => {
                resolve(parsedData.map(createOptionFromResponseReservation));
            },
            console.error
        )
    );

const createOptionFromResponseReservation = (reservation) => {
    return {
        label: `#${reservation.reservationNumber}: ${reservation.membership}, ${reservation.licensePlate}, ${reservation.model}`,
        value: reservation.id,
    };
};

export const createIndexOptionFromResponseReservation = (reservation) => {
    return {
        label: `#${reservation.reservationNumber}: ${reservation.membership} - (${reservation.licensePlate})`,
        value: reservation,
    };
};

export const searchForCars = (auth, inputValue, orgId) => {
    return new Promise((resolve) =>
        handleApiCallAndParseData(
            fetchAdminCars(inputValue, auth, orgId),
            undefined,
            (parsedData) =>
                resolve(parsedData.map(createIndexOptionFromResponseCar)),
            console.error
        )
    );
};

export const searchForModels = (auth, inputValue, orgId) => {
    return new Promise((resolve) =>
        handleApiCallAndParseData(
            simpleFetchAdminModels(inputValue, auth, orgId),
            undefined,
            (parsedData) =>
                resolve(
                    parsedData.map(createIndexOptionFromResponseNamedEntity)
                ),
            (error) => {
                console.log(error);
            }
        )
    );
};

export const searchForLocations = (auth, inputValue, orgId) => {
    return new Promise((resolve) =>
        handleApiCallAndParseData(
            simpleFetchAdminLocations(inputValue, auth, orgId),
            undefined,
            (parsedData) =>
                resolve(
                    parsedData.map(createIndexOptionFromResponseNamedEntity)
                ),
            (error) => {
                console.log(error);
            }
        )
    );
};

export const searchForMembers = (auth, inputValue, orgId, active) => {
    return new Promise((resolve) =>
        handleApiCallAndParseData(
            fetchAdminMemberships(inputValue, auth, orgId, active),
            undefined,
            (parsedData) =>
                resolve(parsedData.map(createOptionFromResponseMember)),
            (error) => {
                console.log(error);
            }
        )
    );
};

export const createOptionFromResponseMember = (member) => {
    return {
        label: `${member.name}${
            member.state !== 'ACTIVE'
                ? ` - (${membershipStateTranslator(member.state)})`
                : ''
        }`,
        value: member,
    };
};

export const searchForPersons = (auth, inputValue, includeNullOption) => {
    return new Promise((resolve) =>
        handleApiCallAndParseData(
            fetchAdminPersons(inputValue, auth),
            undefined,
            (parsedData) => {
                let nullOption = includeNullOption
                    ? [
                          {
                              label: 'Kort ikke i bruk',
                          },
                      ]
                    : [];
                let optionsFromSearch = parsedData.map(
                    createOptionFromResponsePerson
                );
                resolve([...nullOption, ...optionsFromSearch]);
            },
            (error) => {
                console.log(error);
            }
        )
    );
};

const createOptionFromResponsePerson = (person) => {
    return {
        label: `${person.name}`,
        value: person,
    };
};

export const getById = (list, id) => list.find((e) => e.id === id);

export const formatPhoneNumber = (
    phoneNoArg /*TODO FLOW:: string*/,
    showCountryCode,
    landline
) => {
    if (!phoneNoArg) return '';

    phoneNoArg = phoneNoArg.replace('+47', '');
    if (phoneNoArg.length === 8) {
        phoneNoArg = landline
            ? phoneNoArg.substring(0, 2) +
              ' ' +
              phoneNoArg.substring(2, 4) +
              ' ' +
              phoneNoArg.substring(4, 6) +
              ' ' +
              phoneNoArg.substring(6)
            : phoneNoArg.substring(0, 3) +
              ' ' +
              phoneNoArg.substring(3, 5) +
              ' ' +
              phoneNoArg.substring(5);
    }
    if (showCountryCode) return '+47 ' + phoneNoArg;
    return phoneNoArg;
};

export const validPhoneNumber = (phoneNoArg /*TODO FLOW:: string*/) =>
    /^\+?[ 0-9]{8,17}$/.test(phoneNoArg);

export const normalizePhoneNumber = (phoneNoArg /*TODO FLOW:: string*/) => {
    if (!phoneNoArg) return '';
    return phoneNoArg.replace(/[ ]/g, '').replace('+47', '');
};

export const validOrgNo = (orgNo /*TODO FLOW:: string*/) => {
    if (orgNo.length !== 9) return false;
    const weights = [3, 2, 7, 6, 5, 4, 3, 2];
    const checkSum = Number.parseInt(orgNo.charAt(8));
    const orgNoWithouthChecksum = orgNo.substring(0, 8);
    let sum = 0;
    for (let index = 0; index < 8; index++) {
        sum +=
            Number.parseInt(orgNoWithouthChecksum.charAt(index)) *
            weights[index];
    }
    const remainder = sum % 11;
    if (remainder === 0) return checkSum === remainder;
    return checkSum === 11 - remainder;
};

export const validEmail = (
    input /*TODO FLOW:: string*/,
    multiValue /*TODO FLOW:: boolean*/
) => {
    if (!multiValue) return oneValidEmail(input);
    const emails = input.split(/[, ;]+/);
    return !emails.find((email) => !oneValidEmail(email));
};

const oneValidEmail = (email /*TODO FLOW:: string*/) => {
    const re = /^(([a-zA-Z0-9_\-\+]+(\.[a-zA-Z0-9_\-\+]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

export const stringIsAllNumbersButNotOfLength = (
    str /*TODO FLOW:: string*/,
    length /*TODO FLOW:: number*/
) => {
    let allNumbers = /^\d+$/.test(str);
    let validLength = str.length === length;
    return allNumbers && !validLength;
};

const currencyNokWithDecimals = new Intl.NumberFormat('nb-NO', {
    style: 'currency',
    currency: 'NOK',
});

const currencyNokWithoutDecimals = new Intl.NumberFormat('nb-NO', {
    style: 'currency',
    currency: 'NOK',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const quantityWithTwoDecimals = new Intl.NumberFormat('nb-NO', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const quantityWithOptionalDecimals = new Intl.NumberFormat('nb-NO', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});

export const formatPrice = (price) =>
    currencyNokWithoutDecimals.format(price || 0);

export const formatPriceWithDecimals = (price, showCurrencySymbol = true) =>
    showCurrencySymbol
        ? currencyNokWithDecimals.format(price || 0)
        : quantityWithTwoDecimals.format(price || 0);

export const formatQuantity = (quantity) =>
    quantityWithOptionalDecimals.format(quantity || 0);

export const formatNumberWithTwoDecimals = (num) =>
    `${num.toFixed(2)}`.replace('.', ',');

const numberWithoutDecimals = new Intl.NumberFormat('nb-NO', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const formatNumberWithoutDecimals = (number) =>
    numberWithoutDecimals.format(number);

export const formatDateAndTimeString = (dateString, type) => {
    const m = moment(dateString);
    return type === 'SHORT'
        ? `${m.format('DD. MMM')} ${m.format(CLOCK_FORMAT)}`
        : `${m.format('DD. MMM YYYY')} kl. ${m.format(CLOCK_FORMAT)}`;
};

export const formatDateString = (dateString) => {
    if (!dateString) return '';
    const m = moment(dateString);
    return `${m.format('DD. MMM YYYY')}`;
};

export const getProductOptions = (
    stateProducts,
    auth,
    inputValue,
    orgId,
    excludeDiscontinued
) => {
    let result;
    if (stateProducts && !inputValue)
        result = getStateProductsPromise(stateProducts, orgId);
    else result = searchForProducts(auth, orgId, inputValue);
    if (excludeDiscontinued)
        result = new Promise((resolve, reject) => {
            result.then(
                (options) =>
                    resolve(options.filter((p) => !p.value.discontinued)),
                reject
            );
        });

    return result;
};

const getStateProductsPromise = (stateProducts, orgId) =>
    new Promise((resolve) =>
        resolve(
            stateProducts
                .filter((prod) => prod.organizationId === orgId)
                .map(createOptionFromResponseProduct)
        )
    );

let productSearchTimeout;
const searchForProducts = (auth, orgId, inputValue) => {
    return new Promise((resolve) => {
        if (productSearchTimeout) {
            clearTimeout(productSearchTimeout);
            productSearchTimeout = undefined;
        }
        productSearchTimeout = setTimeout(() => {
            clearTimeout(productSearchTimeout);
            productSearchTimeout = undefined;
            handleApiCallAndParseData(
                doFetchProducts(auth, orgId, inputValue),
                undefined,
                (parsedData) => {
                    return resolve(
                        parsedData.map(createOptionFromResponseProduct)
                    );
                },
                (error) => {
                    console.log(error);
                }
            );
        }, 400);
    });
};

const createOptionFromResponseProduct = (product) => {
    return {
        label: getProductLabel(product),
        value: product,
    };
};

export const getProductLabel = (product) => {
    return `${product.productNumber}: ${product.name}`;
};

export const checkIfSmallScreen = () =>
    !window.matchMedia('(min-width: 1136px)').matches ?? false;

export const deviceIsMobile = () =>
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );

export const stringContainsNonSpaceChars = (s) => {
    return /\S/.test(s);
};

export const generateInputId = () => {
    return `input__${generateUuidv4()}`;
};

export const generateUuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : r & (0x3 | 0x8);
        return v.toString(16);
    });
};

//parsing a string on the format id4addd9c754a648ee9f4c34173ac29678 to UUID-format 4addd9c7-54a6-48ee-9f4c-34173ac29678
export const parseEnTurIdResponseString = (str) =>
    str.slice(2, 10) +
    '-' +
    str.slice(10, 14) +
    '-' +
    str.slice(14, 18) +
    '-' +
    str.slice(18, 22) +
    '-' +
    str.slice(22, 38);

export function handleApiCallAndParseData(
    promise,
    dispatch,
    onSuccess,
    onError,
    doNotAuthenticateIfUnauthenticated,
    pagination,
    requestTimeout = 15000
) {
    let didTimeOut = false;

    // todo: does this timeout cause false negatives on reservations? logging should clarify.
    // todo: if so, add optional doNotUseTimout argument
    const timeout = setTimeout(function () {
        didTimeOut = true;
        clearTimeout(timeout);
        logErrorString('Request timed out', promise.request, promise.issuer);
        onError(new Error('Request timed out'));
    }, requestTimeout);
    return promise.then(
        (result) => {
            if (result.ok) {
                clearTimeout(timeout);
                if (!didTimeOut) {
                    if (result.status === 204) {
                        onSuccess();
                    } else {
                        const totalPages = result.headers.get('x-totalpages');
                        const totalElements = result.headers.get(
                            'x-totalelements'
                        );
                        return parseApiData(
                            result,
                            (data) => {
                                return onSuccess(
                                    totalPages && pagination
                                        ? { totalPages, totalElements, data }
                                        : data
                                );
                            },
                            (error) => onError(error)
                        );
                    }
                } else {
                    result.error = 'Call finished after timeout';
                    logErrorFromPromise(
                        result,
                        promise.request,
                        promise.issuer
                    );
                    onError(new Error('Request resolved after timeout'));
                }
            } else {
                if (result.status === 401) {
                    clearTimeout(timeout);
                    if (!doNotAuthenticateIfUnauthenticated && dispatch)
                        dispatch(setShouldAuthenticate(true));
                    onError('UNAUTHENTICATED');
                } else if (result.status === 403) {
                    clearTimeout(timeout);
                    dispatch && dispatch(reportUnauthorizedAttempt(result.url));
                    onError('UNAUTHORIZED');
                } else if (result.status === 404) {
                    logErrorFromPromise(
                        result,
                        promise.request,
                        promise.issuer
                    );
                    clearTimeout(timeout);
                    onError(new Error(result.statusText));
                    dispatch
                        ? dispatch(apiResponseError(result.statusText))
                        : alert(result.statusText);
                } else {
                    logErrorFromPromise(
                        result,
                        promise.request,
                        promise.issuer
                    );
                    clearTimeout(timeout);
                    parseApiData(
                        result,
                        (data) => onError(data),
                        (error) => onError(error)
                    );
                    // onError(new Error(resolve.statusText));
                    dispatch && dispatch(apiResponseError(result.statusText));
                }
            }
        },
        (error) => {
            logErrorFromPromise(error, promise.request, promise.issuer);
            clearTimeout(timeout);
            if (dispatch) dispatch(apiResponseError(error.message));
            onError(error);
        }
    );
}

export const parseApiData = (response, onSuccess, onError) =>
    response.json().then(
        (json) => {
            if (json === undefined || json === null) {
                onError();
            } else if (json.data) {
                return onSuccess(json.data);
            } else {
                return onSuccess(json);
            }
        },
        (error) => {
            onError(error);
        }
    );

export const combineApiCallResult = (results) =>
    results.reduce((acc, value) => {
        if (acc === apiCallError) return apiCallError;
        else if (value === apiCallError) {
            return apiCallError;
        } else if (value === apiCallSuccess) {
            return apiCallSuccess;
        } else return undefined;
    }, {});

export const reservationStateTranslator = (state) => {
    const textMap = {
        ONGOING: 'AKTIV',
        PAST: 'UTGÅTT',
        FUTURE: 'FRAMTIDIG',
        CANCELED: 'KANSELLERT',
        VOIDED: 'ANNULLERT',
    };
    return textMap[state] ? textMap[state] : state;
};

export const membershipStateTranslator = (state) =>
    state === 'ACTIVE'
        ? 'Aktiv'
        : state === 'PAYMENT_REQUIRED'
        ? 'Ikke betalt'
        : state === 'PAUSED'
        ? 'Pauset'
        : state === 'BLOCKED'
        ? 'Sperret'
        : state === 'CANCELED'
        ? 'Avsluttet'
        : 'Ukjent status';

export const contactInfoFromMembership = (membership) =>
    JSON.parse(JSON.stringify(membership).split('null').join('""'));

export const parseDotSeparatedDate = (date) => {
    let newDate;
    let dateArray = date.split('.');
    if (dateArray.length === 3) {
        if (
            dateArray[0].length === 4 &&
            dateArray[1].length === 2 &&
            dateArray[2].length === 2
        ) {
            newDate = moment(`${dateArray[0]}-${dateArray[1]}-${dateArray[2]}`);
        } else if (
            dateArray[0].length === 2 &&
            dateArray[1].length === 2 &&
            dateArray[2].length === 4
        ) {
            newDate = moment(`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`);
        }
    }

    return { valid: newDate ? newDate.isValid() : false, date: newDate };
};

export const parseDashSeparatedDate = (date) => {
    let newDate;
    let dateArray = date.split('-');
    if (dateArray.length === 3) {
        if (
            dateArray[0].length === 4 &&
            dateArray[1].length === 2 &&
            dateArray[2].length === 2
        ) {
            newDate = moment(date);
        } else if (
            dateArray[0].length === 2 &&
            dateArray[1].length === 2 &&
            dateArray[2].length === 4
        ) {
            newDate = moment(`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`);
        }
    }

    return { valid: newDate ? newDate.isValid() : false, date: newDate };
};

export const parseNotSeparatedDate = (date) => {
    let yearLast = moment(
        `${date.slice(4, 8)}-${date.slice(2, 4)}-${date.slice(0, 2)}`
    );
    if (yearLast.isValid()) {
        return { valid: true, date: yearLast };
    } else {
        let yearFirst = moment(
            `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`
        );
        console.log(yearFirst);
        return {
            valid: yearFirst.isValid(),
            date: yearFirst.isValid ? yearFirst : undefined,
        };
    }
};

export const createTimeSelectOptions = () => {
    let options = [];
    for (let i = 0; i < 24; i++) {
        let value1 = '';
        let value2 = '';
        i <= 9 ? (value1 = '0' + i + ':00') : (value1 = i + ':00');
        i <= 9 ? (value2 = '0' + i + ':30') : (value2 = i + ':30');

        let option1 = { value: value1, label: value1 };
        let option2 = { value: value2, label: value2 };
        options.push(option1);
        options.push(option2);
    }
    return options;
};

export const momentToApiDateFormat = (momentObj) =>
    moment(momentObj).format().substr(0, 19);

export const isValidJsonString = (jsonString) => {
    try {
        JSON.parse(jsonString);
    } catch (e) {
        return false;
    }
    return true;
};

export const jsonStringIsFeatureCollection = (jsonString) =>
    isFeatureCollection(JSON.parse(jsonString));

export const isFeatureCollection = (json) =>
    (json.type && json.type === 'FeatureCollection') || json.features;

export const featureCollectionStringToGeometryString = (
    featureCollectionString
) => {
    const featureCollection = JSON.parse(featureCollectionString);
    return JSON.stringify(featureCollection.features[0].geometry, null, 2);
};

export const geojsonAreDifferent = (geo1, geo2) => {
    if (geo1.type !== geo2.type) return true;
    return geo1.type === 'Point'
        ? geojsonPointsAreDifferent(geo1, geo2)
        : multiPointGeojsonAreDifferent(geo1, geo2);
};

const geojsonPointsAreDifferent = (p1, p2) => {
    let c1 = p1.coordinates;
    let c2 = p2.coordinates;
    return !(c1[0] === c2[0] && c1[1] === c2[1]);
};

const multiPointGeojsonAreDifferent = (geo1, geo2) => {
    const coords1 = geo1.coordinates[0];
    const coords2 = geo2.coordinates[0];

    return coords1.reduce((acc, coords, index) => {
        return (
            acc ||
            coords[0] !== coords2[index][0] ||
            coords[1] !== coords2[index][1]
        );
    }, false);
};

export const isValidGeojson = (jsonString) => {
    try {
        L.geoJSON(JSON.parse(jsonString));
    } catch (e) {
        return false;
    }
    return true;
};

export const geoJsonToLatLng = (geojson) => {
    return L.geoJSON(geojson).getBounds().getCenter();
};

export const geoJsonToLayer = (geoJson) => {
    let options = {
        color: 'red',
        fillColor: 'red',
        fillOpacity: 0.5,
        geojson: geoJson,
    };

    if (geoJson.type === 'Point')
        return L.circleMarker(geoJsonToLatLng(geoJson), {
            ...options,
            radius: 10,
        });

    return L.geoJSON(geoJson, options);
};

export const locationToLayer = (location) => {
    return geoJsonToLayer(location.geojson);
};

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (
        c
    ) {
        let r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function safeArrayReplaceElementBy(array, key, newElement) {
    const index = array.findIndex(
        (element) => element[key] === newElement[key]
    );
    const result = [...array];
    if (index > -1) result[index] = newElement;
    else result.push(newElement);
    return result;
}

// UPLOADING PICTURES
export function dataURItoBlob(dataURI) {
    let binary = atob(dataURI.split(',')[1]);
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    let array = [];
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mimeString });
}

export const imageFileToDataUri = (file) => {
    return new Promise((resolve) => {
        if (file.type.split('/')[0] !== 'image') return false;
        // get original orientation of picture, then:
        //      if the orientation is not 1 ("normal"), reset the orientation
        //      resolve with an object containing the imgUri and a boolean saying whether or not the picture was changed
        getOrientation(file, (orientation) => {
            let reader = new FileReader();
            reader.onloadend = () => {
                if (orientation > 1)
                    resetOrientation(
                        reader.result,
                        orientation,
                        file.type,
                        (imgUri) =>
                            resolve({
                                imgUri,
                                changed: true,
                            })
                    );
                else
                    resolve({
                        imgUri: reader.result,
                        changed: false,
                    });
            };
            reader.readAsDataURL(file);
        });
    });
};

function getOrientation(file, callback) {
    let reader = new FileReader();
    reader.onload = function (event) {
        let view = new DataView(event.target.result);

        if (view.getUint16(0, false) !== 0xffd8) return callback(-2);

        let length = view.byteLength,
            offset = 2;

        while (offset < length) {
            let marker = view.getUint16(offset, false);
            offset += 2;

            if (marker === 0xffe1) {
                if (view.getUint32((offset += 2), false) !== 0x45786966) {
                    return callback(-1);
                }
                let little = view.getUint16((offset += 6), false) === 0x4949;
                offset += view.getUint32(offset + 4, little);
                let tags = view.getUint16(offset, little);
                offset += 2;

                for (let i = 0; i < tags; i++)
                    if (view.getUint16(offset + i * 12, little) === 0x0112)
                        return callback(
                            view.getUint16(offset + i * 12 + 8, little)
                        );
            } else if ((marker & 0xff00) !== 0xff00) break;
            else offset += view.getUint16(offset, false);
        }
        return callback(-1);
    };
    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
}

function resetOrientation(srcBase64, srcOrientation, type, callback) {
    let img = new Image();
    img.onload = () => {
        let width = img.width,
            height = img.height,
            canvas = document.createElement('canvas'),
            ctx = canvas.getContext('2d');

        // set proper canvas dimensions before transform & export
        if (4 < srcOrientation && srcOrientation < 9) {
            // noinspection JSSuspiciousNameCombination
            canvas.width = height;
            // noinspection JSSuspiciousNameCombination
            canvas.height = width;
        } else {
            canvas.width = width;
            canvas.height = height;
        }

        // transform context before drawing image
        switch (srcOrientation) {
            case 2:
                ctx.transform(-1, 0, 0, 1, width, 0);
                break;
            case 3:
                ctx.transform(-1, 0, 0, -1, width, height);
                break;
            case 4:
                ctx.transform(1, 0, 0, -1, 0, height);
                break;
            case 5:
                ctx.transform(0, 1, 1, 0, 0, 0);
                break;
            case 6:
                ctx.transform(0, 1, -1, 0, height, 0);
                break;
            case 7:
                ctx.transform(0, -1, -1, 0, height, width);
                break;
            case 8:
                ctx.transform(0, -1, 1, 0, 0, width);
                break;
            default:
                break;
        }

        // draw image
        ctx.drawImage(img, 0, 0);
        callback(canvas.toDataURL(type));
    };
    img.src = srcBase64;
}

export function checkIfFiltersAreActive(filters, hideUnavailableCars) {
    if (!!hideUnavailableCars) return true;
    if (filters.maxSeats < 9 || filters.minSeats > 1) return true;
    for (const group of filters.groups) {
        if (group.operator === 'OR') {
            // for OR filters selecting all is the same as selecting none.
            let oneOrMoreIsUnselcted = false;
            let oneOrMoreIsSelcted = false;
            group.values.forEach((value) => {
                if (value.selected) {
                    oneOrMoreIsSelcted = true;
                } else {
                    oneOrMoreIsUnselcted = true;
                }
            });
            if (oneOrMoreIsUnselcted && oneOrMoreIsSelcted) {
                return true;
            }
        } else {
            for (const value of group.values) {
                if (value.selected) return true;
            }
        }
    }
    return false;
}

export const urlSearchToMap = (search) =>
    search &&
    decodeURIComponent(search)
        .split('?')[1]
        .split('&')
        .reduce((a, e) => {
            let [key, value] = e.split('=');
            return {
                ...a,
                [key]: value,
            };
        }, {});

export const getCookie = (key) => {
    const allCookies = document.cookie;
    if (!allCookies || allCookies.length === 0) return undefined;
    for (let c of allCookies.split('; ')) {
        const splitCookie = c.split('=');
        if (splitCookie[0] === key) return splitCookie[1];
    }
    return undefined;
};

export const getUserinfoFromCookies = () => {
    const userInfoCookie = getCookie('userinfo');
    if (!userInfoCookie) return undefined;
    return decodeURIComponent(userInfoCookie).replaceAll('+', ' ');
};

export const removeStoredUserInfo = () => {
    localStorage.removeItem('userinfo');
    localStorage.removeItem('userinfo_ts');
};

export const storeUserInfo = (userinfo) => {
    if (!userinfo) return;
    localStorage.setItem('userinfo', userinfo);
    localStorage.setItem('userinfo_ts', moment().format('YYYY-MM-DD HH:mm:ss'));
};

export const getStoredUserInfo = () => {
    let userInfoTs = localStorage.getItem('userinfo_ts');
    if (userInfoTs) {
        userInfoTs = moment(userInfoTs, 'YYYY-MM-DD HH:mm:ss');
        const diff = moment().diff(userInfoTs);
        // 10 min shelf life
        if (diff > 600000) {
            removeStoredUserInfo();
            return undefined;
        }
    }

    let userInfo = localStorage.getItem('userinfo');
    if (!userInfo) return undefined;
    try {
        userInfo = JSON.parse(userInfo);
        return userInfo;
    } catch (e) {
        console.log('failed to parse userInfo');
        return undefined;
    }
};

export const getCompareFunction = (by) => (a, b) => {
    const av = a[by];
    const bv = b[by];
    return av > bv ? 1 : av < bv ? -1 : 0;
};

export const messageRelatesToCar = (message, car) =>
    (!message.car && !message.model && !message.location) ||
    (message.car && message.car.id === car.carId) ||
    (message.model && message.model.id === car.modelId) ||
    (message.location && message.location.id === car.location.id);

export const filterMessagesByCar = (messages, car) =>
    messages ? messages.filter((m) => messageRelatesToCar(m, car)) : [];

export const isSame = (e1, e2) => {
    if (e1 === e2) return true;
    if (!e1 || !e2) return false;
    for (let key of Object.keys(e1)) {
        const val1 = e1[key],
            val2 = e2[key];
        let same =
            typeof val1 === 'object' && !Array.isArray(val1) && val1 !== null
                ? isSame(val1, val2)
                : e1[key] === e2[key];
        if (!same) return false;
    }
    return true;
};

export const isElectric = (car) => {
    let fuel = car.properties.find((prop) => {
        return prop.groupKey === 'FUEL_TYPE';
    });
    return fuel ? fuel.key === 'ELECTRIC' : false;
};

/*
    Konverterer et nummer på formen '+474445566' til '+47 444 55 666'.
    Om nummeret starter med flere ting, som '+0047 ' eller noe går det også fint, det blir bare lagt på fremst.
    Merk at om nummeret allerede er formatert vil vi få helt feil svar.
 */
export const prettifyPhoneNumber = (nr) => {
    if (nr.length < 11) return nr;
    return (
        nr.slice(0, -8) +
        ' ' +
        nr.slice(-8, -5) +
        ' ' +
        nr.slice(-5, -3) +
        ' ' +
        nr.slice(-3)
    );
};
