import { handleActions } from 'redux-actions';
import actions from './actions';
import {
    MAX_AVAILABILITY,
    PRICE,
    LOCATION,
    PROXIMITY,
    CarComparatorName,
} from './utils';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { apiCallError, apiCallSuccess } from '../../../../utilities/constants';
import {
    Availability,
    Car,
    CarPrice,
    SearchResult,
} from '../../../types/search';
import {
    ApiCallResult,
    DeleLocation,
    MapPosition,
} from '../../../types/application';
import { EntityMessage } from '../../../types/admin';
import { CityBikeSpot } from '../../../../utilities/types';
import { Moment } from 'moment';
import {
    CarSearchFilters,
    ConcurrentReservationLimit,
    ExternalLocation,
    PublicId,
    Reservation,
} from '../../../types/common';

type SearchDataState = {
    fetchingCars: boolean;
    fetchingReservation: boolean;
    fetchingExternalLocations: boolean;
    locationSearchString: string;
    cars: Car[];
    fetchingSwappableCars: boolean;
    fetchSwappableCarsResult: ApiCallResult;
    swappableCars: Car[];
    externalLocations: ExternalLocation[];
    previouslySelectedLocations: DeleLocation[];
    reservation?: Reservation;
    fetchingPriceOfCurrentCar: boolean;
    priceOfCurrentCar?: CarPrice;
    reservingCar: boolean;
    reservationResult: ApiCallResult;
    reservingCarResult?: 'SUCCESS' | 'ERROR' | 'COMPLETED';
    messages: EntityMessage[];
    checkingConcurrentReservationLimit: boolean;
    checkConcurrentReservationLimitResult: ApiCallResult;
    concurrentReservationLimitResponse?: ConcurrentReservationLimit;
    showingSwapCarOptions: boolean;
};

const defaultDataState: SearchDataState = {
    fetchingCars: false,
    fetchingReservation: false,
    fetchingExternalLocations: false,
    locationSearchString: '',
    cars: [],
    fetchingSwappableCars: false,
    fetchSwappableCarsResult: undefined,
    swappableCars: [],
    externalLocations: [],
    previouslySelectedLocations: [],
    reservation: undefined,
    fetchingPriceOfCurrentCar: false,
    priceOfCurrentCar: undefined,
    reservingCar: false,
    reservationResult: undefined,
    reservingCarResult: undefined,
    messages: [],
    checkingConcurrentReservationLimit: false,
    checkConcurrentReservationLimitResult: undefined,
    concurrentReservationLimitResponse: undefined,
    showingSwapCarOptions: false,
};

const dataReducer = handleActions<SearchDataState, any>(
    {
        [actions.fetchCarsPending.toString()]: (state) => ({
            ...state,
            fetchingCars: true,
        }),
        [actions.fetchCarsCompleted.toString()]: (
            state,
            action: {
                payload: SearchResult;
            }
        ) => ({
            ...state,
            fetchingCars: false,
            cars: action.payload.cars,
            messages: action.payload.messages,
        }),
        [actions.fetchCarsError.toString()]: (state) => ({
            ...state,
            fetchingCars: false,
        }),

        [actions.fetchSwappableCarsPending.toString()]: (state) => ({
            ...state,
            fetchingSwappableCars: true,
            fetchSwappableCarsResult: undefined,
        }),
        [actions.fetchSwappableCarsCompleted.toString()]: (
            state,
            action: { payload: Car[] }
        ) => ({
            ...state,
            fetchingSwappableCars: false,
            fetchSwappableCarsResult: apiCallSuccess,
            swappableCars: action.payload,
        }),
        [actions.fetchSwappableCarsError.toString()]: (state) => ({
            ...state,
            fetchSwappableCarsResult: apiCallError,
            fetchingSwappableCars: false,
        }),

        [actions.checkConcurrentReservationLimitPending.toString()]: (
            state
        ) => ({
            ...state,
            checkingConcurrentReservationLimit: true,
            checkConcurrentReservationLimitResult: undefined,
        }),
        [actions.checkConcurrentReservationLimitCompleted.toString()]: (
            state,
            action: { payload: ConcurrentReservationLimit }
        ) => ({
            ...state,
            checkingConcurrentReservationLimit: false,
            checkConcurrentReservationLimitResult: apiCallSuccess,
            concurrentReservationLimitResponse: action.payload,
        }),
        [actions.checkConcurrentReservationLimitError.toString()]: (state) => ({
            ...state,
            checkingConcurrentReservationLimit: false,
            checkConcurrentReservationLimitResult: apiCallError,
            concurrentReservationLimitResponse: undefined,
        }),

        [actions.setShowingSwapCarOptions.toString()]: (
            state,
            action: { payload: boolean }
        ) => ({
            ...state,
            showingSwapCarOptions: action.payload,
        }),
        [actions.bookingReservationPending.toString()]: (state) => ({
            ...state,
            fetchingReservation: true,
        }),
        [actions.bookingReservationCompleted.toString()]: (
            state,
            action: { payload: Reservation }
        ) => ({
            ...state,
            fetchingReservation: false,
            reservation: action.payload,
        }),
        [actions.updatingData.toString()]: (state) => ({
            ...state,
            fetchingCars: true,
        }),
        [actions.setLocationSearchString.toString()]: (
            state,
            action: { payload: string }
        ) => ({
            ...state,
            locationSearchString: action.payload,
        }),
        [actions.setPreviouslySelectedLocations.toString()]: (
            state,
            action: { payload: DeleLocation[] }
        ) => ({
            ...state,
            previouslySelectedLocations: action.payload,
        }),
        [actions.fetchExternalLocationsPending.toString()]: (state) => ({
            ...state,
            fetchingExternalLocations: true,
        }),
        [actions.fetchExternalLocationsCompleted.toString()]: (
            state,
            action: { payload: ExternalLocation[] }
        ) => {
            return {
                ...state,
                fetchingExternalLocations: false,
                externalLocations: action.payload,
            };
        },
        [actions.bookingConfirmationPending.toString()]: (state) => ({
            ...state,
            reservingCar: true,
            reservingCarResult: undefined,
        }),
        [actions.bookingConfirmationError.toString()]: (
            state,
            action: { payload: 'SUCCESS' | 'COMPLETED' | 'ERROR' }
        ) => ({
            ...state,
            reservingCar: false,
            //TODO gjør om til bool
            reservingCarResult: action.payload ? action.payload : 'ERROR',
        }),
        [actions.resetBookingConfirmationError.toString()]: (state) => ({
            ...state,
            reservingCarResult: undefined,
        }),
        [actions.bookingConfirmationCompleted.toString()]: (state) => ({
            ...state,
            reservingCar: false,
            reservingCarResult: 'SUCCESS',
        }),
        [actions.fetchPriceOfCurrentCarPending.toString()]: (state) => ({
            ...state,
            fetchingPriceOfCurrentCar: true,
        }),
        [actions.fetchPriceOfCurrentCarError.toString()]: (state) => ({
            ...state,
            fetchingPriceOfCurrentCar: false,
        }),
        [actions.fetchPriceOfCurrentCarCompleted.toString()]: (
            state,
            action
        ) => ({
            ...state,
            fetchingPriceOfCurrentCar: false,
            priceOfCurrentCar: action.payload,
        }),
        CLEAR_STATE: (state) => {
            return {
                ...state,
                fetchingCars: false,
                fetchingReservation: false,
                fetchingExternalLocations: false,
                locationSearchString: '',
                previouslySelectedLocations: [],
                reservation: undefined,
                fetchingPriceOfCurrentCar: false,
                priceOfCurrentCar: undefined,
                reservingCar: false,
                reservationResult: undefined,
            };
        },
    },
    defaultDataState
);

type SearchState = {
    startTime?: Moment;
    endTime?: Moment;
    selectedCarId?: PublicId;
    gettingGpsPosition: boolean;
    fetchingFilters: boolean;
    filtersAreActive: boolean;
    filters?: CarSearchFilters;
    hideUnavailableCars: boolean;
    superDamageWaiver: boolean;
    selectedPosition?: DeleLocation;
    gpsPositionDisabled: boolean;
    kilometerEstimate?: string;
};

const defaultSearchState: SearchState = {
    startTime: undefined,
    endTime: undefined,
    selectedCarId: undefined,
    gettingGpsPosition: false,
    fetchingFilters: false,
    filtersAreActive: false,
    filters: undefined,
    hideUnavailableCars: false,
    superDamageWaiver: true,
    selectedPosition: undefined,
    gpsPositionDisabled: false,
    kilometerEstimate: undefined,
};

const searchReducer = handleActions<SearchState, any>(
    {
        [actions.initializeSearch.toString()]: (
            state,
            action: { payload: { startTime: Moment; endTime: Moment } }
        ) => ({
            ...state,
            startTime: action.payload.startTime,
            endTime: action.payload.endTime,
        }),
        [actions.updateTimes.toString()]: (
            state,
            action: { payload: { start?: Moment; end: Moment } }
        ) => ({
            ...state,
            startTime: action.payload.start,
            endTime: action.payload.end,
        }),
        [actions.setSelectedPosition.toString()]: (
            state,
            action: { payload: DeleLocation }
        ) => ({
            ...state,
            selectedPosition: action.payload,
        }),
        [actions.setGpsPositioningDisabled.toString()]: (
            state,
            action: { payload: boolean }
        ) => ({
            ...state,
            gpsPositionDisabled: action.payload,
        }),
        [actions.gettingGpsPositionPending.toString()]: (state) => ({
            ...state,
            gettingGpsPosition: true,
        }),
        [actions.gettingGpsPositionCompleted.toString()]: (state) => ({
            ...state,
            gettingGpsPosition: false,
        }),
        [actions.gettingGpsPositionError.toString()]: (state) => ({
            ...state,
            gettingGpsPosition: false,
        }),
        [actions.fetchFiltersPending.toString()]: (state) => ({
            ...state,
            fetchingFilters: true,
        }),
        [actions.fetchFiltersCompleted.toString()]: (
            state,
            action: { payload: CarSearchFilters }
        ) => ({
            ...state,
            fetchingFilters: false,
            filters: action.payload,
        }),
        [actions.setFilters.toString()]: (
            state,
            action: { payload: CarSearchFilters }
        ) => ({
            ...state,
            filters: action.payload,
        }),
        [actions.setFiltersAreActive.toString()]: (
            state,
            action: { payload: boolean }
        ) => ({
            ...state,
            filtersAreActive: action.payload,
        }),
        [actions.setHideUnavailableCars.toString()]: (
            state,
            action: { payload: boolean }
        ) => ({
            ...state,
            hideUnavailableCars: action.payload,
        }),
        [actions.selectCar.toString()]: (state, action: { payload: Car }) => ({
            ...state,
            selectedCarId: action.payload.id,
            selectedCar: action.payload,
        }),
        [actions.setSuperDamageWaiver.toString()]: (
            state,
            action: { payload: boolean }
        ) => ({
            ...state,
            superDamageWaiver: action.payload,
        }),
        [actions.setKilometerEstimate.toString()]: (state, action) => ({
            ...state,
            kilometerEstimate: action.payload,
        }),
    },
    defaultSearchState
);

type AvailabilityState = {
    initializingAvailability: boolean;
    startTime: undefined | Moment;
    endTime: undefined | Moment;
    fetchingAvailabilityInfo: boolean;
    isAvailable: boolean;
    availablePeriods: Availability[];
    fetchingReservations: boolean;
    reservedDays: Moment[];
};

const defaultAvailabilityState: AvailabilityState = {
    initializingAvailability: false,
    startTime: undefined,
    endTime: undefined,
    fetchingAvailabilityInfo: false,
    isAvailable: false,
    availablePeriods: [],
    fetchingReservations: false,
    reservedDays: [],
};

const availabilityReducer = handleActions<AvailabilityState, any>(
    {
        [actions.initAvailabilityPending.toString()]: (state) => ({
            ...state,
            initializingAvailability: true,
            startTime: undefined,
            endTime: undefined,
            isAvailable: false,
            availablePeriods: [],
            fetchingReservations: false,
            reservedDays: [],
        }),
        [actions.initAvailabilityCompleted.toString()]: (state) => ({
            ...state,
            initializingAvailability: false,
        }),
        [actions.setAvailabilityStartTime.toString()]: (
            state,
            action: { payload: Moment | undefined }
        ) => ({
            ...state,
            startTime: action.payload,
        }),
        [actions.setAvailabilityEndTime.toString()]: (
            state,
            action: { payload: Moment | undefined }
        ) => ({
            ...state,
            endTime: action.payload,
        }),
        [actions.fetchAvailabilityInfoPending.toString()]: (state) => ({
            ...state,
            fetchingAvailabilityInfo: true,
        }),
        [actions.fetchAvailabilityInfoError.toString()]: (state) => ({
            ...state,
            fetchingAvailabilityInfo: false,
        }),
        [actions.fetchAvailabilityInfoCompleted.toString()]: (
            state,
            action: {
                payload: {
                    isPeriodAvailable: boolean;
                    periods: Availability[];
                };
            }
        ) => ({
            ...state,
            fetchingAvailabilityInfo: false,
            isAvailable: action.payload.isPeriodAvailable,
            availablePeriods: action.payload.periods,
        }),
        [actions.fetchReservedDaysPending.toString()]: (state) => ({
            ...state,
            fetchingReservations: true,
        }),
        [actions.fetchReservedDaysCompleted.toString()]: (state, action) => ({
            ...state,
            fetchingReservations: false,
            reservedDays: action.payload,
        }),
    },
    defaultAvailabilityState
);

type UiState = {
    showCarFilterView: boolean;
    mapPosition?: MapPosition;
    sortingPriority: CarComparatorName[];
    showCityBikes: boolean;
    cityBikes?: CityBikeSpot[];
};

const defaultUiState: UiState = {
    showCarFilterView: false,
    mapPosition: undefined,
    sortingPriority: [PROXIMITY, LOCATION, MAX_AVAILABILITY, PRICE],
    showCityBikes: false,
};

//TODO remove unused functions
const uiReducer = handleActions<UiState, any>(
    {
        [actions.setMapPosition.toString()]: (
            state,
            action: { payload: MapPosition }
        ) => ({
            ...state,
            mapPosition: action.payload,
        }),
        [actions.toggleCarFilterView.toString()]: (state) => {
            return { ...state, showCarFilterView: !state.showCarFilterView };
        },
        [actions.setShowCityBikes.toString()]: (
            state,
            action: { payload: boolean }
        ) => ({
            ...state,
            showCityBikes: action.payload,
        }),
        [actions.setCityBikes.toString()]: (
            state,
            action: { payload: CityBikeSpot[] }
        ) => ({
            ...state,
            cityBikes: action.payload,
        }),
    },
    defaultUiState
);

const searchPersistConfig = {
    key: 'search',
    storage: storage,
    whitelist: ['selectedPosition', 'gpsPositionDisabled'],
};

const dataPersistConfig = {
    key: 'searchData',
    storage: storage,
    whitelist: ['previouslySelectedLocations'],
};

const reducer = combineReducers({
    searchData: persistReducer(dataPersistConfig, dataReducer),
    search: persistReducer(searchPersistConfig, searchReducer),
    availability: availabilityReducer,
    ui: uiReducer,
});

export default reducer;
