import appActions from '../../../duck/actions';
import {
    doCancelReservation,
    doDialToOpen,
    doEndReservation,
    doFetchReservationDrivers,
    doPutReservationDrivers,
    extendReservation,
    fetchActiveReservationsFromApi,
    fetchDistanceFromAPI,
    fetchEntityMessagesApiCall,
    fetchHistoricReservationsFromApi,
    fetchReservationExtensibilityFromAPI,
    fetchReservationFromApi,
    getApiCall,
    postSubResource,
    promiseTimeout,
    putApiCall,
} from '../../../../utilities/api';
import {
    fetchActiveReservationsPending,
    fetchActiveReservationsCompleted,
    fetchActiveReservationsError,
    fetchHistoricReservationsPending,
    fetchHistoricReservationsCompleted,
    fetchHistoricReservationsError,
    dialToOpenPending,
    dialToOpenCompleted,
    dialToOpenError,
    updateReservationsAfterExtension,
    resetReservationExtensionHighLight,
    fetchReservationDriversPending,
    fetchReservationDriversCompleted,
    fetchReservationDriversError,
    setReservationDriverState,
    updateReservationDriversPending,
    updateReservationDriversCompleted,
    updateReservationDriversError,
    fetchReservationPending,
    fetchReservationCompleted,
    fetchReservationError,
    extendReservationPending,
    extendReservationCompleted,
    extendReservationError,
    fetchReservationExtendabilityPending,
    fetchReservationExtendabilityCompleted,
    fetchReservationExtendabilityError,
    cancelReservationPending,
    cancelReservationCompleted,
    cancelReservationError,
    hideCancellationSuccessModal,
    putReservationPending,
    putReservationCompleted,
    putReservationError,
    updateReservationUserNotePending,
    updateReservationUserNoteCompleted,
    updateReservationUserNoteError,
    fetchCarDistanceCompleted,
    fetchingFindCarInfoPending,
    fetchingFindCarInfoCompleted,
    fetchingFindCarInfoError,
    resetSelectedReservation,
    fetchEntityMessagesCompleted,
    preLoadActiveReservationsPending,
    preLoadActiveReservationsCompleted,
    preLoadActiveReservationsError,
} from './reservationReducers';
import {
    fetchGpsPosition,
    geoJsonToLatLng,
    getDistanceString,
    handleApiCallAndParseData,
    strcmp,
} from '../../../../utilities/utils';
import {
    apiResponseError,
    handlePagination,
    setPerformAfterLogin,
    setSelectedMembership,
    setShouldAuthenticate,
} from '../../../duck/operations';
import {
    selectAuthentication,
    selectMemberships,
    selectSelectedMembership,
} from '../../../duck/selectors';
import {
    selectFetchingReservation,
    selectMembershipActiveReservations,
    selectMembershipHistoricReservations,
    selectPreLoadingActiveReservations,
    selectReservation,
    selectReservationDrivers,
} from './selectors';
import { fetchLocations, selectLocations } from '../../LocationPage/api';
import {
    createApiCallObject,
    handleErrors,
} from '../../../../utilities/apiUtils';
import { checkConcurrentReservationLimit } from '../../SearchPage/duck/operations';

export function fetchReservationById(id, optionalCompletedCallback) {
    return (dispatch, getState) => {
        dispatch(fetchReservationPending());
        return fetchReservationFromApi(
            id,
            selectAuthentication(getState())
        ).then(
            (response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(fetchReservationCompleted(json));
                        dispatch(fetchReservationExtensibility(id, json));
                        if (optionalCompletedCallback)
                            dispatch(optionalCompletedCallback(id));

                        // If the user opened a link to a reservation while having a different membership selected
                        if (
                            json.membershipId !==
                            selectSelectedMembership(getState())?.id
                        ) {
                            const reservationMembership = selectMemberships(
                                getState()
                            )?.find((m) => m.id === json.membershipId);
                            if (reservationMembership) {
                                dispatch(
                                    setSelectedMembership(reservationMembership)
                                );
                            }
                        }
                    });
                } else {
                    dispatch(fetchReservationError());
                    if (response.status === 401) {
                        dispatch(setShouldAuthenticate(true));
                        dispatch(
                            setPerformAfterLogin(() => {
                                dispatch(fetchActiveReservations());
                                if (optionalCompletedCallback)
                                    dispatch(optionalCompletedCallback(id));
                            })
                        );
                    }
                }
            },
            () => dispatch(fetchReservationError())
        );
    };
}

export function startNow(reservationId) {
    return (dispatch, getState) => {
        return handleApiCallAndParseData(
            getApiCall(
                selectAuthentication(getState()),
                'reservations',
                createApiCallObject(dispatch, 'putReservation'),
                `${reservationId}/start-now`
            ),
            dispatch,
            (parsedData) => {
                dispatch(fetchReservationCompleted(parsedData));
            },
            (error) => {
                console.log('error: ', error);
            }
        );
    };
}

export function endReservation(id, damage, onsuccess) {
    return (dispatch, getState) => {
        function retryEndReservation(e) {
            if (e.message === 'unauthenticated') {
                dispatch(setShouldAuthenticate(true));
                dispatch(
                    setPerformAfterLogin(endReservation(id, damage, onsuccess))
                );
            } else {
                dispatch(apiResponseError(e.message));
            }

            return new Promise((resolve) => resolve(e));
        }

        return doEndReservation(id, damage, selectAuthentication(getState()))
            .then((response) => handleErrors(response, dispatch))
            .then((okResponse) => okResponse.json())
            .then((json) => {
                dispatch(fetchReservationCompleted(json));
                dispatch(fetchActiveReservations());
                dispatch(fetchHistoricReservations());
                dispatch(checkConcurrentReservationLimit());
                if (typeof onsuccess === 'function') onsuccess();
            })
            .catch(retryEndReservation);
    };
}

export function cancelReservation(id, feeString) {
    return (dispatch, getState) => {
        dispatch(cancelReservationPending());
        doCancelReservation(id, selectAuthentication(getState())).then(
            (response) =>
                handleErrors(response, dispatch).then(
                    (okResponse) => {
                        if (okResponse) {
                            okResponse.json().then((json) => {
                                dispatch(cancelReservationCompleted(feeString));

                                let reservations = [
                                    ...selectMembershipActiveReservations(
                                        getState()
                                    ),
                                ];
                                let index = reservations.findIndex(
                                    (res) => res.id === json.id
                                );
                                if (index !== -1) {
                                    reservations[index] = json;
                                    dispatch(
                                        fetchActiveReservationsCompleted(
                                            reservations
                                        )
                                    );
                                }
                                dispatch(fetchReservationCompleted(json));
                            });
                        }
                    },
                    (e) => {
                        dispatch(cancelReservationError());
                        if (e.message === 'unauthenticated') {
                            dispatch(setShouldAuthenticate(true));
                            dispatch(
                                setPerformAfterLogin(cancelReservation(id))
                            );
                        } else {
                            dispatch(apiResponseError(e.message));
                        }
                    }
                ),
            (error) => {
                dispatch(cancelReservationError());
                console.log(error);
            }
        );
    };
}

const cacheReservationResponse = (res) => {
    if (!('caches' in window)) return;
    caches
        .open('apiCalls')
        .then((c) =>
            c.put(
                new Request(`/api/reservations/${res.id}`),
                new Response(JSON.stringify(res))
            )
        );
};

export function putReservation(reservation, shared, refreshExtensibility) {
    return (dispatch, getState) => {
        dispatch(putReservationPending());
        return handleApiCallAndParseData(
            putApiCall(
                selectAuthentication(getState()),
                'reservations',
                reservation,
                createApiCallObject(dispatch, 'putReservation'),
                reservation.id
            ),
            dispatch,
            (parsedData, response) => {
                if (
                    parsedData.errors &&
                    parsedData.errors.length === 0 &&
                    parsedData.entity
                ) {
                    dispatch(putReservationCompleted());
                    dispatch(fetchReservationCompleted(parsedData.entity));
                    cacheReservationResponse(parsedData.entity);
                    shared || dispatch(fetchActiveReservations());
                    shared || dispatch(fetchHistoricReservations());
                    refreshExtensibility &&
                        dispatch(fetchReservationExtensibility(reservation.id));
                } else {
                    dispatch(putReservationError(parsedData.errors[0]));
                    console.log(parsedData);
                }
            },
            (error) => {
                let propagatedError = error.errors
                    ? error.errors[0]
                    : undefined;
                dispatch(
                    putReservationError(
                        propagatedError ? propagatedError.code : undefined
                    )
                );
                console.log(error);
            }
        );
    };
}

export function doHideCancellationSuccessModal() {
    return (dispatch) => {
        dispatch(hideCancellationSuccessModal());
    };
}

export function extendReservationBy30Min(id) {
    return (dispatch, getState) => {
        dispatch(extendReservationPending());
        extendReservation(id, 30, selectAuthentication(getState())).then(
            (response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(doUpdateReservationsAfterExtension(json));
                        dispatch(fetchReservationCompleted(json));
                        dispatch(extendReservationCompleted());
                        dispatch(fetchReservationExtensibility(id, json));
                        cacheReservationResponse(json);
                    });
                } else {
                    dispatch(extendReservationError());
                }
            }
        );
    };
}

export function dialToOpen(id) {
    return (dispatch, getState) => {
        dispatch(dialToOpenPending());
        doDialToOpen(id, selectAuthentication(getState())).then((response) => {
            if (response.ok) {
                dispatch(dialToOpenCompleted());
            } else {
                dispatch(dialToOpenError());
            }
        });
    };
}

function doUpdateReservationsAfterExtension(updatedReservation) {
    return (dispatch, getState) => {
        let reservations = [...selectMembershipActiveReservations(getState())];
        const reservationIndex = reservations.findIndex(
            (res) => res.id === updatedReservation.id
        );
        let reservation = (reservations[reservationIndex] = {
            ...reservations[reservationIndex],
        });
        reservation.end = updatedReservation.end;
        reservation.effectiveEnd = updatedReservation.effectiveEnd;
        dispatch(updateReservationsAfterExtension(reservations));
    };
}

export const doResetReservationExtensionHighLight = () => (dispatch) =>
    dispatch(resetReservationExtensionHighLight());

export function updateReservationUserNote(reservationId, notes, shared) {
    return (dispatch, getState) => {
        dispatch(updateReservationUserNotePending());
        return handleApiCallAndParseData(
            postSubResource(
                selectAuthentication(getState()),
                'reservations',
                reservationId,
                'note',
                notes,
                createApiCallObject(dispatch, 'updateReservationUserNote'),
                false,
                true
            ),
            dispatch,
            (parsedData) => {
                dispatch(updateReservationUserNoteCompleted(parsedData));
                shared || dispatch(fetchActiveReservations());
                shared || dispatch(fetchHistoricReservations());
            },
            (error) => {
                dispatch(updateReservationUserNoteError());
                console.log(error);
            }
        );
    };
}

export function fetchReservationExtensibility(id, reservation) {
    return (dispatch, getState) => {
        reservation = reservation ? reservation : selectReservation(getState());
        if (reservation && reservation.id === id && !reservation.ended) {
            dispatch(fetchReservationExtendabilityPending());
            fetchReservationExtensibilityFromAPI(
                id,
                selectAuthentication(getState())
            ).then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(fetchReservationExtendabilityCompleted(json));
                    });
                } else {
                    dispatch(fetchReservationExtendabilityError());
                }
            });
        }
    };
}

export function fetchReservationLocationAndDistanceById(id) {
    return (dispatch, getState) => {
        if (!navigator.onLine) return;
        dispatch(fetchingFindCarInfoPending());
        const reservation = selectReservation(getState());

        if (reservation && !reservation.location) {
            dispatch(fetchingFindCarInfoCompleted());
            return;
        }

        if (reservation && reservation.id === id) {
            const locations = selectLocations(getState());
            const location = locations.find(
                (loc) => loc.id === reservation.location.id
            );
            if (location) {
                dispatch(fetchCarDistance(location.geojson, id));
                dispatch(fetchingFindCarInfoCompleted());
            } else {
                dispatch(fetchLocations()).then(
                    () => {
                        dispatch(fetchReservationLocationAndDistanceById(id));
                    },
                    (error) => {
                        dispatch(fetchingFindCarInfoError());
                        console.log(error);
                    }
                );
            }
        } else {
            fetchReservationById(id, fetchReservationLocationAndDistanceById)(
                dispatch,
                getState
            );
        }
    };
}

export function fetchMembershipReservations() {
    return (dispatch) => {
        dispatch(fetchActiveReservations());
        dispatch(fetchHistoricReservations());
    };
}

export function fetchActiveReservations(preloadAll) {
    return (dispatch, getState) => {
        dispatch(fetchActiveReservationsPending());
        return fetchActiveReservationsFromApi(
            selectAuthentication(getState())
        ).then(
            (response) =>
                handleErrors(response, dispatch).then(
                    (okResponse) => {
                        okResponse.json().then((json) => {
                            json.sort((a, b) => {
                                return strcmp(a.start, b.start);
                            });
                            dispatch(fetchEntityMessages(json));
                            dispatch(fetchActiveReservationsCompleted(json));
                            if (preloadAll)
                                dispatch(preloadActiveReservations(json));
                        });
                    },
                    (e) => {
                        if (e.message === 'unauthenticated') {
                            dispatch(setShouldAuthenticate(true));
                            dispatch(
                                setPerformAfterLogin(fetchActiveReservations())
                            );
                        } else {
                            dispatch(apiResponseError(e.message));
                        }
                    }
                ),
            (error) => {
                dispatch(fetchActiveReservationsError());
                throw error;
            }
        );
    };
}

// Pre load the full reservations for offline use and mark them as such
export const preloadActiveReservations = (reservationsjsons) => {
    return (dispatch, getState) => {
        if (selectPreLoadingActiveReservations(getState())) return;
        dispatch(preLoadActiveReservationsPending());
        const auth = selectAuthentication(getState());
        const onError = () => dispatch(preLoadActiveReservationsError());

        if (reservationsjsons && Array.isArray(reservationsjsons)) {
            let promises = [];
            reservationsjsons.forEach((r) => {
                promises.push(preLoadReservation(r, auth, onError));
            });
            Promise.all(promises).then(
                () => {
                    dispatch(preLoadActiveReservationsCompleted());
                },
                (e) => {
                    console.log('e: ', e);
                    onError();
                }
            );
        } else dispatch(preLoadActiveReservationsError());
    };
};

export const preLoadReservation = (json, auth, onError) =>
    fetchReservationFromApi(json.id, auth).then((r) => r.json(), onError);

export function fetchHistoricReservations(page, retryTimeout) {
    return (dispatch, getState) => {
        const auth = selectAuthentication(getState());
        if (!auth) return;

        const selectedMembership = selectSelectedMembership(getState());

        if (!selectedMembership) {
            console.log(
                'Attempted to fetchHistoricReservations before setting selectedMembership'
            );
            const rt = retryTimeout ? retryTimeout + 100 : 1;
            if (rt > 10000) return;
            return setTimeout(() => {
                dispatch(fetchHistoricReservations(page, rt));
            }, rt);
        }

        dispatch(fetchHistoricReservationsPending());
        return handleApiCallAndParseData(
            fetchHistoricReservationsFromApi(
                selectAuthentication(getState()),
                selectedMembership.id,
                createApiCallObject(dispatch, 'fetchHistoricReservations'),
                page
            ),
            dispatch,
            (parsedData) => {
                dispatch(handlePagination(page, parsedData));
                let previousData = page
                    ? selectMembershipHistoricReservations(getState())
                    : [];
                dispatch(
                    fetchHistoricReservationsCompleted({
                        totalElements: parsedData.totalElements,
                        reservations: [
                            ...previousData,
                            ...(parsedData.data || parsedData),
                        ],
                    })
                );
            },
            (error) => {
                dispatch(fetchHistoricReservationsError());
                dispatch(apiResponseError(error.message));
                if (page) {
                    dispatch(appActions.fetchingNewPageError());
                }
                console.log(error);
            },
            undefined,
            true
        );
    };
}

export function fetchCarDistance(carGeojson, reservationId) {
    return (dispatch) => {
        return fetchGpsPosition().then(
            (response) =>
                promiseTimeout(
                    fetchDistanceFromAPI(
                        geoJsonToLatLng(carGeojson),
                        geoJsonToLatLng(response.geojson)
                    ),
                    5000
                ).then(
                    (distanceResponse) =>
                        distanceResponse.json().then(
                            (json) => {
                                try {
                                    const walkDistance =
                                        json.data.trip.tripPatterns[0]
                                            .streetDistance;
                                    dispatch(
                                        fetchCarDistanceCompleted({
                                            id: reservationId,
                                            distance: getDistanceString(
                                                walkDistance
                                            ),
                                        })
                                    );
                                } catch (error) {
                                    console.log(error);
                                }
                            },
                            (error) => {
                                console.log(error);
                            }
                        ),
                    (error) => {
                        console.log(error);
                    }
                ),
            (error) => {
                console.log(error);
            }
        );
    };
}

export function fetchReservationDrivers(reservationId) {
    return (dispatch, getState) => {
        dispatch(fetchReservationDriversPending());
        return handleApiCallAndParseData(
            doFetchReservationDrivers(
                selectAuthentication(getState()),
                reservationId,
                createApiCallObject(dispatch, 'fetchReservationDrivers')
            ),
            dispatch,
            (parsedData) => {
                dispatch(fetchReservationDriversCompleted(parsedData));
            },
            (error) => {
                dispatch(fetchReservationDriversError());
                console.log(error);
            }
        );
    };
}

export function doSetReservationDriverState(driverState) {
    return (dispatch) => dispatch(setReservationDriverState(driverState));
}

export function updateReservationDrivers(reservationId, sharedWith) {
    return (dispatch, getState) => {
        dispatch(updateReservationDriversPending());
        let drivers = selectReservationDrivers(getState());
        if (sharedWith) drivers = [...drivers, sharedWith];

        return handleApiCallAndParseData(
            doPutReservationDrivers(
                selectAuthentication(getState()),
                reservationId,
                drivers,
                createApiCallObject(dispatch, 'updateReservationDrivers')
            ),
            dispatch,
            (parsedData) => {
                dispatch(updateReservationDriversCompleted(parsedData));
            },
            (error) => {
                dispatch(updateReservationDriversError());
                console.log(error);
            }
        );
    };
}

export function updateReservationData(id) {
    return (dispatch, getState) => {
        if (selectFetchingReservation(getState())) return;
        dispatch(fetchReservationById(id));
        dispatch(fetchReservationLocationAndDistanceById(id));
    };
}

export const doResetSelectedReservation = () => (dispatch) =>
    dispatch(resetSelectedReservation());

export const fetchEntityMessages = (reservations) => {
    return (dispatch, getState) => {
        const reservationIds = reservations
            .filter(
                (r) =>
                    !r.ended && r.state !== 'CANCELED' && r.state !== 'VOIDED'
            )
            .map((r) => r.id);

        return handleApiCallAndParseData(
            fetchEntityMessagesApiCall(
                selectAuthentication(getState()),
                createApiCallObject(dispatch, 'fetchEntityMessages'),
                reservationIds
            ),
            dispatch,
            (parsedData) => {
                dispatch(fetchEntityMessagesCompleted(parsedData));
            },
            (error) => {
                console.log(error);
            }
        );
    };
};
