import classnames from 'classnames';
import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../icon/addIcon.svg';
import { ReactComponent as AddMemberIcon } from '../icon/addMember.svg';
import { ReactComponent as BillsIcon } from '../icon/bills.svg';
import { ReactComponent as SmallBillIcon } from '../icon/bills_16.svg';
import { ReactComponent as BlockIcon } from '../icon/blockIcon.svg';
import { ReactComponent as CancelIcon } from '../icon/cancelIcon.svg';
import { ReactComponent as CheckInCircleIcon } from '../icon/checkInCircle.svg';
import { ReactComponent as CodeIcon } from '../icon/code.svg';
import { ReactComponent as DamageIcon } from '../icon/damage.svg';
import { ReactComponent as EditlIcon } from '../icon/edit.svg';
import { ReactComponent as FilterIcon } from '../icon/filter.svg';
import { ReactComponent as ListIcon } from '../icon/list.svg';
import { ReactComponent as LockIcon } from '../icon/lock.svg';
import { ReactComponent as MessageIcon } from '../icon/message.svg';
import { ReactComponent as MessageInCircleIcon } from '../icon/message_in_circle.svg';
import { ReactComponent as PauseIcon } from '../icon/pauseIcon.svg';
import { ReactComponent as PencilIcon } from '../icon/pencil.svg';
import { ReactComponent as PictureIcon } from '../icon/picture.svg';
import { ReactComponent as RefreshIcon } from '../icon/refreshIcon.svg';
import { ReactComponent as RemoveIcon } from '../icon/removeIcon.svg';
import { ReactComponent as StartIcon } from '../icon/startIcon.svg';
import { ReactComponent as StopIcon } from '../icon/stopIcon.svg';
import { ReactComponent as SwapCarIcon } from '../icon/swap_car.svg';
import { ReactComponent as UnlockIcon } from '../icon/unlock.svg';
import { ReactComponent as WalletIcon } from '../icon/wallet.svg';
import { ReactComponent as CancelReservationIcon } from '../icon/cancelcar.svg';
import { ReactComponent as SwapDriverIcon } from '../icon/switchdrivers.svg';
import { ReactComponent as ProblemTriangle } from '../icon/triangle.svg';
import { ReactComponent as CarIcon } from '../icon/car.svg';
import { ReactComponent as CallIcon } from '../icon/call.svg';
import { ReactComponent as BackIcon } from '../icon/backSmall.svg';
import { Theme } from '../../../utilities/types';
import { ReactComponent as ElectricIcon } from '../icon/charging.svg';
import { ReactComponent as FuelIcon } from '../icon/fuel.svg';
import { ReactComponent as HelpIcon } from '../icon/helpCar.svg';
import { ReactComponent as InfoIcon } from '../icon/infocar.svg';
import { deviceIsMobile, prettifyPhoneNumber } from '../../../utilities/utils';
import { connect } from 'react-redux';
import { selectTheme } from '../../duck/selectors';
import { State } from '../../../state/store';

type ButtonTypes =
    | 'SWAP_DRIVERS'
    | 'CANCEL_RESERVATION'
    | 'TOGGLE_RAW_DATA'
    | 'EDIT_MEMBERSHIP'
    | 'MAKE_RESERVATION'
    | 'UNLOCK_CAR'
    | 'LOCK_CAR'
    | 'BUTTON_2_UNLOCK_CAR'
    | 'BUTTON_2_LOCK_CAR'
    | 'ADD_PICTURE_BUTTON_LARGE'
    | 'ADD_PICTURE_BUTTON'
    | 'BILLS'
    | 'PROBLEM'
    | 'ELECTRICITY'
    | 'FUEL'
    | 'PRICE'
    | 'SWAP_CAR'
    | 'LOST_CARD'
    | 'DAMAGE'
    | 'ADMIN_EDIT_LINK'
    | 'ADMIN_INVOICES_LINK'
    | 'ADMIN_EDIT_BUTTON'
    | 'ADMIN_ADD_LINK'
    | 'REFUND'
    | 'LIST_WITH_LABEL'
    | 'REMOVE_BUTTON'
    | 'ADD_BUTTON'
    | 'REFRESH_CAR_POSITION_BUTTON'
    | 'REFRESH_BUTTON'
    | 'REFRESH_BUTTON--noHover'
    | 'BUTTON_2_START'
    | 'BUTTON_2_START--disabled'
    | 'BUTTON_2_PAUSE'
    | 'BUTTON_2_PAUSE--disabled'
    | 'BUTTON_2_STOP'
    | 'BUTTON_2_SMS'
    | 'BUTTON_2_SMS--disabled'
    | 'BUTTON_2_CANCEL'
    | 'BUTTON_2_CANCEL--disabled'
    | 'BUTTON_2_DELETE'
    | 'BUTTON_2_EDIT'
    | 'BUTTON_2_BLOCK'
    | 'BUTTON_2_BLOCK--disabled'
    | 'ADD_BUTTON_WITH_PLUS'
    | 'CREATE_MEMBERSHIP'
    | 'PROFILE_MENU_OPTION'
    | 'PROFILE_MENU_LINK'
    | 'PROFILE_MENU_LINK_DISABLED'
    | 'BUTTON_4_SMS'
    | 'BUTTON_4_FILTERS'
    | 'ADMIN_BILLS'
    | 'CALL'
    | 'HELP_BACK'
    | 'HELP'
    | 'INFORMATION';

type Props = {
    buttonType: ButtonTypes;
    theme: Theme;
    className?: string;
    id?: string;
    label?: string;
    ariaLabel?: string;
    link?: any;
    to?: string;
    target?: string;
    pathname?: string;
    onClick?: () => void;
    active?: boolean;
    styleOverride?: Object;
    title?: string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
    testId?: string;
    download?: boolean;
    children?: ReactNode | string;
    tabIndex?: number;
};

/* TODO
Vi kan generalisere noe vanvittig her, det eneste som stort set endrer seg er type, link og icon

dette kan vi switche på

kan ha type is one of og mappe dette til button 3, 4 etc
 */

const ButtonWithIconComponent = (props: Props) => {
    const [hover, setHover] = useState(false);
    const { buttonType, theme, link } = { ...props };

    switch (buttonType) {
        case 'SWAP_DRIVERS':
            return (
                <Link className="button_grid" to={link ? link : {}}>
                    <SwapDriverIcon />
                    <div
                        className="button_2__text"
                        style={{
                            ...theme.text?.buttonWithIconText,
                            marginBottom: 0,
                        }}
                    >
                        Sjåfører
                    </div>
                </Link>
            );
        case 'CANCEL_RESERVATION':
            return (
                <Link className="button_grid" to={link ? link : {}}>
                    <CancelReservationIcon />
                    <div
                        className="button_2__text"
                        style={{
                            ...theme.text?.buttonWithIconText,
                            marginBottom: 0,
                        }}
                    >
                        Avbestill
                    </div>
                </Link>
            );
        case 'TOGGLE_RAW_DATA':
            return (
                <button
                    className={classnames(
                        'button_2 button_2--rounded button_2--noText',
                        props.className
                    )}
                    style={props.styleOverride}
                    onClick={props.onClick}
                >
                    <CodeIcon />
                </button>
            );
        case 'MAKE_RESERVATION':
            return (
                <Link
                    className={'button_4 button_4--active'}
                    to={{
                        pathname: `/findcar`,
                    }}
                    style={{ marginTop: '1rem' }}
                >
                    <CarIcon
                        style={{
                            marginRight: '0.5rem',
                            height: '1rem',
                            width: '1rem',
                        }}
                    />
                    <div
                        className="button_4__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        VELG BIL
                    </div>
                </Link>
            );
        case 'UNLOCK_CAR':
            return (
                <Link
                    className="button__nextTo"
                    style={props.styleOverride}
                    to={link ? link : {}}
                >
                    <UnlockIcon />
                    <div className="button__nextTo--text">Åpne</div>
                </Link>
            );
        case 'LOCK_CAR':
            return (
                <button
                    className="button__nextTo"
                    style={{
                        borderLeft: '1px solid var(--links_and_clickables)',
                    }}
                    onClick={props.onClick}
                >
                    <LockIcon />
                    <div className="button__nextTo--text">Lås</div>
                </button>
            );
        case 'BUTTON_2_UNLOCK_CAR':
            return (
                <button
                    className="button_2"
                    style={{
                        background: theme.colors?.background_button,
                    }}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <UnlockIcon />
                    <div
                        className="button_2__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        {props.label || 'Lås opp bil'}
                    </div>
                </button>
            );
        case 'BUTTON_2_LOCK_CAR':
            return (
                <button
                    className="button_2"
                    style={{
                        background: theme.colors?.background_button,
                    }}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <LockIcon />
                    <div
                        className="button_2__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        {props.label || 'Lås bil'}
                    </div>
                </button>
            );
        case 'ADD_PICTURE_BUTTON_LARGE':
            return (
                <button
                    className="button_2"
                    style={{
                        background: theme.colors?.background_button,
                    }}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <PictureIcon />

                    <div
                        className="button_2__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        Legg til bilde
                    </div>
                </button>
            );
        case 'ADD_PICTURE_BUTTON':
            return (
                <button
                    className={'button_4 button_4--active'}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <PictureIcon />
                    <div
                        className="button_4__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        Legg til bilde
                    </div>
                </button>
            );
        case 'BILLS':
            return (
                <Link
                    className="button_grid "
                    to={link ? link : {}}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <BillsIcon />
                    <div
                        className="button_2__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        Utlegg
                    </div>
                </Link>
            );
        case 'PROBLEM':
            return (
                <Link className="button_grid" to={link ? link : {}}>
                    <ProblemTriangle />
                    <div
                        className="button_2__text"
                        style={{
                            ...theme.text?.buttonWithIconText,
                            marginBottom: 0,
                        }}
                        onClick={() => {
                            if (props.onClick) props.onClick();
                        }}
                    >
                        Avvik
                    </div>
                </Link>
            );
        case 'ELECTRICITY':
            return (
                <Link className="button_grid" to={link ? link : {}}>
                    <ElectricIcon />
                    <div
                        className="button_2__text"
                        style={{
                            ...theme.text?.buttonWithIconText,
                            marginBottom: 0,
                        }}
                    >
                        Lading
                    </div>
                </Link>
            );
        case 'FUEL':
            return (
                <Link className="button_grid" to={link ? link : {}}>
                    <FuelIcon />
                    <div
                        className="button_2__text"
                        style={{
                            ...theme.text?.buttonWithIconText,
                            marginBottom: 0,
                        }}
                    >
                        Drivstoff
                    </div>
                </Link>
            );
        case 'PRICE':
            return (
                <Link
                    data-test={props.testId}
                    className="button_grid"
                    to={link ? link : {}}
                >
                    <WalletIcon />
                    <div
                        className="button_2__text"
                        style={{
                            ...theme.text?.buttonWithIconText,
                            marginBottom: 0,
                        }}
                    >
                        Pris
                    </div>
                </Link>
            );
        case 'SWAP_CAR':
            return (
                <button
                    data-test={props.testId}
                    className="button_grid"
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <SwapCarIcon />
                    <div
                        className="button_2__text"
                        style={{
                            ...theme.text?.buttonWithIconText,
                            marginBottom: 0,
                        }}
                    >
                        Bytt bil
                    </div>
                </button>
            );
        case 'LOST_CARD':
            return (
                <button
                    className="button_4 lostCardButton"
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <div
                        className="button_2__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        Meld mistet kort
                    </div>
                </button>
            );
        case 'DAMAGE':
            return (
                <Link className="button_grid" to={link ? link : {}}>
                    <DamageIcon />
                    <div
                        className="button_2__text"
                        style={{
                            ...theme.text?.buttonWithIconText,
                            marginBottom: 0,
                        }}
                    >
                        Skade
                    </div>
                </Link>
            );
        case 'ADMIN_EDIT_LINK':
            return (
                <Link
                    className={'button_iconWithLink'}
                    style={{ borderColor: theme.linkColor }}
                    to={link ? link : {}}
                    target={props.target}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                    aria-label={props.ariaLabel}
                >
                    <PencilIcon />
                    <p
                        className="button_iconWithLink__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        {props.label}
                    </p>
                </Link>
            );
        case 'ADMIN_INVOICES_LINK':
            return (
                <Link
                    className={'button_iconWithLink'}
                    style={{ borderColor: theme.linkColor }}
                    to={link ? link : {}}
                    target={props.target}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                    aria-label={props.ariaLabel}
                >
                    <SmallBillIcon />
                    <p
                        className="button_iconWithLink__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        {props.label}
                    </p>
                </Link>
            );
        case 'ADMIN_EDIT_BUTTON':
            return (
                <button
                    className={'button_iconWithLink'}
                    style={{ borderColor: theme.linkColor }}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                    aria-label={props.ariaLabel}
                >
                    <PencilIcon />
                    <p
                        className="button_iconWithLink__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        {props.label}
                    </p>
                </button>
            );

        case 'ADMIN_ADD_LINK':
            return (
                <Link
                    className={classnames(
                        'button_iconWithLink',
                        props.pathname
                    )}
                    style={{ borderColor: theme.linkColor }}
                    to={link ? link : {}}
                    target={props.target}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                    aria-label={props.ariaLabel}
                >
                    <AddIcon />
                    <p
                        className="button_iconWithLink__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        {props.label}
                    </p>
                </Link>
            );
        case 'LIST_WITH_LABEL':
            return (
                <Link
                    className={'button_iconWithLink button_listWithLabel'}
                    style={{ borderColor: theme.linkColor }}
                    to={link ?? {}}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                    aria-label={props.ariaLabel}
                >
                    <ListIcon />
                    <p
                        className="button_iconWithLink__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        {props.label}
                    </p>
                </Link>
            );
        case 'REMOVE_BUTTON':
            return (
                <Link
                    className={'button_iconOnly'}
                    to={link ? link : {}}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                    aria-label={props.ariaLabel}
                >
                    <RemoveIcon />
                </Link>
            );
        case 'ADD_BUTTON':
            return (
                <Link
                    className={'button_iconAndText'}
                    style={{ borderColor: theme.linkColor }}
                    to={link ? link : {}}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                    aria-label={props.ariaLabel}
                >
                    <div className="button_iconAndText__icon">
                        <AddIcon />
                    </div>
                    <p
                        className="button_iconAndText__text"
                        style={{ color: theme.colors?.links_and_clickables }}
                    >
                        {props.label}
                    </p>
                </Link>
            );

        case 'REFRESH_CAR_POSITION_BUTTON':
            return (
                <button
                    className={'button_4'}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <RefreshIcon />
                    <div
                        className="button_4__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        Hent ny posisjon
                    </div>
                </button>
            );
        case 'REFRESH_BUTTON':
            return (
                <button
                    className={'button_iconOnly'}
                    style={{ borderColor: theme.linkColor }}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                    aria-label={props.ariaLabel}
                >
                    <RefreshIcon />
                </button>
            );
        case 'REFRESH_BUTTON--noHover':
            return (
                <button
                    className={'button_iconOnly--noHover'}
                    style={{ borderColor: theme.linkColor }}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                    aria-label={props.ariaLabel}
                >
                    <RefreshIcon />
                </button>
            );
        case 'BUTTON_2_START':
            return (
                <Link
                    className={'button_2 button_2--noHoverOnSVGpath'}
                    style={{
                        background: theme.colors?.background_button,
                    }}
                    to={link ? link : {}}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <StartIcon />
                    <div
                        className="button_2__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        {props.label}
                    </div>
                </Link>
            );
        case 'BUTTON_2_START--disabled':
            return (
                <div className={'button_2 button_2--disabled'}>
                    <StartIcon />
                    <div
                        className="button_2__text"
                        style={{
                            ...theme.text?.buttonWithIconText,
                            color: theme.colors?.disabled__wcag,
                        }}
                    >
                        {props.label}
                    </div>
                </div>
            );
        case 'BUTTON_2_PAUSE':
            return (
                <Link
                    className={'button_2'}
                    style={{
                        background: theme.colors?.background_button,
                    }}
                    to={link ? link : {}}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <PauseIcon />
                    <div
                        className="button_2__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        {props.label}
                    </div>
                </Link>
            );
        case 'BUTTON_2_PAUSE--disabled':
            return (
                <div className={'button_2 button_2--disabled'}>
                    <PauseIcon />
                    <div
                        className="button_2__text"
                        style={{
                            ...theme.text?.buttonWithIconText,
                            color: theme.colors?.disabled__wcag,
                        }}
                    >
                        {props.label}
                    </div>
                </div>
            );
        case 'REFUND':
            return (
                <Link
                    className={'button_4 button_4--active u-marginTopSmall'}
                    to={link ? link : {}}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <span className={'u-paddingSidesSmall'}>
                        💸 {props.label}
                    </span>
                </Link>
            );
        case 'BUTTON_2_STOP':
            return (
                <Link
                    className={'button_2'}
                    style={{
                        background: theme.colors?.background_button,
                    }}
                    to={link ? link : {}}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <StopIcon />
                    <div
                        className="button_2__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        {props.label}
                    </div>
                </Link>
            );
        case 'BUTTON_2_SMS':
            return (
                <Link
                    className={'button_2'}
                    style={{
                        background: theme.colors?.background_button,
                    }}
                    to={link ? link : {}}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <MessageInCircleIcon />
                    <div
                        className="button_2__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        {props.label}
                    </div>
                </Link>
            );
        case 'BUTTON_2_SMS--disabled':
            return (
                <div className={'button_2 button_2--disabled'}>
                    <MessageInCircleIcon />
                    <div
                        className="button_2__text"
                        style={{
                            ...theme.text?.buttonWithIconText,
                            color: theme.colors?.disabled__wcag,
                        }}
                    >
                        {props.label}
                    </div>
                </div>
            );
        case 'BUTTON_2_CANCEL':
            return (
                <Link
                    className={'button_2'}
                    style={{
                        background: theme.colors?.background_button,
                    }}
                    to={link ? link : {}}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <CancelIcon />
                    <div
                        className="button_2__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        {props.label}
                    </div>
                </Link>
            );
        case 'BUTTON_2_CANCEL--disabled':
            return (
                <div className={'button_2 button_2--disabled'}>
                    <CancelIcon />
                    <div
                        className="button_2__text"
                        style={{
                            ...theme.text?.buttonWithIconText,
                            color: theme.colors?.disabled__wcag,
                        }}
                    >
                        {props.label}
                    </div>
                </div>
            );
        case 'BUTTON_2_DELETE':
            return (
                <button
                    className={'button_2'}
                    style={{ background: theme.colors?.background_button }}
                    onClick={props.onClick}
                >
                    <CancelIcon />
                    <div
                        className="button_2__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        {props.label}
                    </div>
                </button>
            );
        case 'BUTTON_2_EDIT':
            return (
                <button
                    className={'button_2'}
                    style={{ background: theme.colors?.background_button }}
                    onClick={props.onClick}
                >
                    <EditlIcon />
                    <div
                        className="button_2__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        {props.label}
                    </div>
                </button>
            );
        case 'BUTTON_2_BLOCK':
            return (
                <Link
                    className={'button_2'}
                    style={{
                        background: theme.colors?.background_button,
                    }}
                    to={link ? link : {}}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <BlockIcon />
                    <div
                        className="button_2__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        {props.label}
                    </div>
                </Link>
            );
        case 'BUTTON_2_BLOCK--disabled':
            return (
                <div className={'button_2 button_2--disabled'}>
                    <BlockIcon />
                    <div
                        className="button_2__text"
                        style={{
                            ...theme.text?.buttonWithIconText,
                            color: theme.colors?.disabled__wcag,
                        }}
                    >
                        {props.label}
                    </div>
                </div>
            );
        case 'BUTTON_4_SMS':
            return (
                <Link
                    className={'button_4 smallSmsButton'}
                    onClick={props.onClick}
                    style={props.styleOverride}
                    to={link ? link : {}}
                    onMouseEnter={() => {
                        if (props.onMouseEnter) props.onMouseEnter();
                    }}
                    onMouseLeave={() => {
                        if (props.onMouseLeave) props.onMouseLeave();
                    }}
                    onFocus={() => {
                        if (props.onFocus) props.onFocus();
                    }}
                    onBlur={() => {
                        if (props.onBlur) props.onBlur();
                    }}
                >
                    <MessageIcon />
                    <div
                        className="button_4__text"
                        style={{
                            ...theme.text?.roundButtonWithIconText,
                            color: theme.colors?.links_and_clickables,
                        }}
                    >
                        {props.label}
                    </div>
                </Link>
            );
        case 'BUTTON_4_FILTERS':
            return (
                <Link
                    className={'button_4 smallFiltersButton'}
                    to={link ? link : {}}
                    onClick={props.onClick}
                    style={props.styleOverride}
                >
                    <FilterIcon />
                    <div
                        className="button_4__text"
                        style={{
                            ...theme.text?.roundButtonWithIconText,
                            color: theme.colors?.links_and_clickables,
                        }}
                    >
                        {props.label}
                    </div>
                    {props.active && (
                        <div className="smallFiltersButton__activeFiltersIndicator">
                            <CheckInCircleIcon />
                        </div>
                    )}
                </Link>
            );
        case 'ADD_BUTTON_WITH_PLUS':
            const content = (
                <>
                    <AddMemberIcon />
                    <div
                        className="button_4__text"
                        style={{
                            ...theme.text?.roundButtonWithIconText,
                            color: theme.colors?.links_and_clickables,
                        }}
                    >
                        {props.label}
                    </div>
                </>
            );
            return props.to || link ? (
                <Link
                    to={props.to || link}
                    target={props.target}
                    className={'button_4 addButtonWithPlus'}
                    onClick={props.onClick}
                >
                    {content}
                </Link>
            ) : (
                <button
                    className={'button_4 addButtonWithPlus'}
                    onClick={props.onClick}
                >
                    {content}
                </button>
            );
        case 'CREATE_MEMBERSHIP':
            return (
                <Link
                    to={'/join'}
                    className={'button_4 createMembershipButton'}
                >
                    <AddMemberIcon />
                    <div
                        className="button_4__text"
                        style={{
                            ...theme.text?.roundButtonWithIconText,
                            color: theme.colors?.links_and_clickables,
                        }}
                    >
                        {props.label}
                    </div>
                </Link>
            );
        case 'PROFILE_MENU_OPTION':
            return (
                <button
                    data-test={props.testId}
                    className={classnames('profileMenuOption', {
                        'profileMenuOption--active': props.active,
                    })}
                    style={{
                        backgroundColor: hover
                            ? theme.colors?.hover_on_white_background_color
                            : 'white',
                        color: props.active
                            ? props.theme.colors?.links_and_clickables
                            : props.theme.colors?.text__grey,
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                    title={props.title}
                >
                    {props.children}
                </button>
            );
        case 'PROFILE_MENU_LINK':
            return (
                <Link
                    to={{
                        pathname: '/profile',
                        state: { prevPath: window.location.pathname },
                    }}
                    className={'profileMenuOption profileMenuOption--link'}
                    style={{
                        backgroundColor: hover
                            ? theme.colors?.hover_on_white_background_color
                            : 'white',
                        color: props.active
                            ? props.theme.colors?.links_and_clickables
                            : props.theme.colors?.text__grey,
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    {props.children}
                </Link>
            );
        case 'PROFILE_MENU_LINK_DISABLED':
            return (
                <div
                    className={
                        'profileMenuOption profileMenuOption--link disabled'
                    }
                    style={{
                        backgroundColor:
                            theme.colors?.hover_on_white_background_color,
                        color: '#70707F',
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    {props.children}
                </div>
            );
        case 'ADMIN_BILLS':
            return (
                <Link
                    className="button_grid notChosenActionButton"
                    to={link ? link : {}}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <BillsIcon />
                    <div
                        className="button_2__text"
                        style={theme.text?.buttonWithIconText}
                    >
                        Utlegg
                    </div>
                </Link>
            );
        case 'CALL':
            const number = 'tel:' + props.link.state.number;
            return deviceIsMobile() ? (
                <a
                    className="button_grid"
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                    href={number}
                >
                    <CallIcon />
                    <span
                        className="button_2__text"
                        style={{
                            ...theme.text?.buttonWithIconText,
                        }}
                    >
                        Vakttelefon
                    </span>
                </a>
            ) : (
                <div>
                    {props.label || 'Trenger du fortsatt hjelp ring '}
                    <p>
                        <a href={number}>
                            {' '}
                            {prettifyPhoneNumber(props.link.state.number)}{' '}
                        </a>
                    </p>
                </div>
            );
        case 'HELP_BACK':
            return (
                <button
                    className="button_back"
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <BackIcon />
                    Tilbake
                </button>
            );
        case 'HELP':
            return (
                <Link
                    className={classnames(
                        'reservationPage__helpButton',
                        props.className
                    )}
                    to={link ? link : {}}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <HelpIcon />
                    <span>Hjelp</span>
                </Link>
            );

        case 'INFORMATION':
            return (
                <Link
                    className="reservationPage__helpButton"
                    to={link ? link : {}}
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <InfoIcon />
                    <span>Om bilen</span>
                </Link>
            );

        default:
            return <div />;
    }
};
export const ButtonWithIcon = connect((state: State) => ({
    theme: selectTheme(state),
}))(ButtonWithIconComponent);
export default ButtonWithIcon;
