import moment from 'moment';
import { deviceIsMobile } from './utils';
import { apiCallSize } from './constants';
import { simpleAdminSearch } from './searchUtils';
import { get } from './apiUtils';

/*
    TODO improvement
    1. Har laget generelle metoder for get/post/put etc, disse bør brukes istedenfor alle generelle metoder
    2. Bedre løsning. Flytt alle pathene, f.eks /api/admin/cars, over i en constants-fil og
    kall heller apiCallObject.issue() i handleApiCallAndParseData.
    Vi kan da skrote hele api.js-filen
 */

/* General functions */

export function getApiCall(
    authorization,
    resource,
    apiCallObject,
    optionalResourceId,
    isAdminResource
) {
    return apiCallObject
        .setCallParams(
            `/api/${isAdminResource ? 'admin/' : ''}${resource}${
                optionalResourceId ? '/' + optionalResourceId : ''
            }`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
            }
        )
        .issue();
}

export function postApiCall(
    authorization,
    resource,
    requestBody,
    apiCallObject,
    optionalResourceId,
    isAdminResource
) {
    return apiCallObject
        .setCallParams(
            `/api/${isAdminResource ? 'admin/' : ''}${resource}${
                optionalResourceId ? '/' + optionalResourceId : ''
            }`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: `POST`,
                body: JSON.stringify(requestBody),
            }
        )
        .issue();
}

export function putApiCall(
    authorization,
    resource,
    putObject,
    apiCallObject,
    optionalResourceId,
    isAdminResource
) {
    return apiCallObject
        .setCallParams(
            `/api/${isAdminResource ? 'admin/' : ''}${resource}${
                optionalResourceId ? '/' + optionalResourceId : ''
            }`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: `PUT`,
                body: JSON.stringify(putObject),
            }
        )
        .issue();
}

export function deleteApiCall(
    authorization,
    resource,
    apiCallObject,
    optionalResourceId,
    isAdminResource
) {
    return apiCallObject
        .setCallParams(
            `/api/${isAdminResource ? 'admin/' : ''}${resource}${
                optionalResourceId ? '/' + optionalResourceId : ''
            }`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: `DELETE`,
            }
        )
        .issue();
}

export function getSubResource(
    authorization,
    mainResource,
    subResourceKey,
    apiCallObject,
    optionalResourceId,
    isAdminResource
) {
    return apiCallObject
        .setCallParams(
            `/api/${isAdminResource ? 'admin/' : ''}${mainResource}${
                optionalResourceId ? '/' + optionalResourceId : ''
            }/${subResourceKey}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
            }
        )
        .issue();
}

export function postSubResource(
    authorization,
    mainResource,
    mainResourceId,
    subResourceKey,
    requestBody,
    apiCallObject,
    isAdminResource,
    noStringify
) {
    return apiCallObject
        .setCallParams(
            `/api/${
                isAdminResource ? 'admin/' : ''
            }${mainResource}/${mainResourceId}/${subResourceKey}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: `POST`,
                body: noStringify ? requestBody : JSON.stringify(requestBody),
            }
        )
        .issue();
}
export function putSubResource(
    authorization,
    mainResource,
    mainResourceId,
    subResourceKey,
    requestBody,
    apiCallObject,
    isAdminResource,
    noStringify
) {
    return apiCallObject
        .setCallParams(
            `/api/${
                isAdminResource ? 'admin/' : ''
            }${mainResource}/${mainResourceId}/${subResourceKey}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: `PUT`,
                body: noStringify ? requestBody : JSON.stringify(requestBody),
            }
        )
        .issue();
}

export function deleteSubResource(
    authorization,
    mainResource,
    mainResourceId,
    subResourceKey,
    resourceId,
    apiCallObject,
    isAdminResource
) {
    return apiCallObject
        .setCallParams(
            `/api/${
                isAdminResource ? 'admin/' : ''
            }${mainResource}/${mainResourceId}/${subResourceKey}/${resourceId}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: `DELETE`,
            }
        )
        .issue();
}

export const doCheckConcurrentReservationLimit = (
    authorization,
    membershipId,
    start,
    end,
    apiCallObject
) =>
    apiCallObject
        .setCallParams(
            '/api/reservations/check-concurrent-reservation-limit-reached',
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                body: JSON.stringify({ membershipId, start, end }),
                method: `POST`,
            }
        )
        .issue();

export function fetchCars(
    start,
    end,
    location,
    filters,
    minimumAvailabilityPercent,
    authorization,
    apiCallObject
) {
    let requestUrl = '/api/search';
    if (end) {
        if (!start) start = moment();

        let apiCall =
            '/api/search?start=' +
            moment(start).format().substring(0, 19) +
            '&end=' +
            moment(end).format().substring(0, 19);

        if (!!location) {
            apiCall += `&location=${encodeURIComponent(
                JSON.stringify(location.geojson)
            )}`;
        }
        if (!!minimumAvailabilityPercent)
            apiCall += `&minimumAvailabilityPercent=${minimumAvailabilityPercent}`;

        if (!!filters)
            apiCall += `&filters=${encodeURIComponent(
                JSON.stringify(filters)
            )}`;

        requestUrl = apiCall;
    }

    return apiCallObject
        .setCallParams(requestUrl, {
            headers: { authorization: authorization },
        })
        .issue();
}

export function logErrorFromPromise(promised, request, issuer, body) {
    if (typeof promised === 'string') {
        doLog(JSON.stringify({ issuer, error: promised }), 'promised');
        return;
    }
    if (!body && promised.body && promised.body.locked === false) {
        try {
            promised
                .clone()
                .json()
                .then((parsedBody) =>
                    logErrorFromPromise(promised, request, issuer, parsedBody)
                );
        } catch (e) {
            console.log('Failed to log error to backend:');
            console.log(e);
        }
        return;
    }
    let message = { issuer, body, error: promised.error };
    [
        'redirected',
        'status',
        'statusText',
        'url',
        'promised',
        'message',
        'path',
        'timestamp',
    ].forEach((key) => {
        if (promised[key] && (!body || !body[key]))
            message[key] = promised[key];
    });
    if (request) {
        let logRequest = { ...request };
        let logRequestHeaders = (logRequest.headers = {
            ...logRequest.headers,
        });
        logRequestHeaders.authorization = logRequestHeaders.authorization
            ? '[[REDACTED]]'
            : 'NO_AUTHORIZATION';
        try {
            logRequest.body = JSON.parse(logRequest.body);
        } catch (e) {
            console.log('failed to parse request body', e);
        }
        message.request = logRequest;
    }
    doLog(JSON.stringify(message), 'error');
}

export function logErrorString(error, request, issuer) {
    doLog(JSON.stringify({ issuer, error }), 'error');
}

export function doLog(message, severity) {
    fetch('/api/log', {
        body: JSON.stringify({ message, severity }),
        headers: {
            'content-type': 'application/json',
        },
        method: 'POST',
    }).then(() => {});
}

export function doEndReservation(reservationId, carWasDamaged, authorization) {
    return fetchWithAuthorization(
        `/api/reservations/${reservationId}/end`,
        authorization,
        {
            body: JSON.stringify({ carWasDamaged }),
            headers: {
                'content-type': 'application/json',
            },
            method: `POST`,
        }
    );
}

export function doCancelReservation(reservationId, authorization) {
    return fetchWithAuthorization(
        `/api/reservations/${reservationId}/cancel`,
        authorization,
        {
            headers: {
                'content-type': 'application/json',
            },
            method: `POST`,
        }
    );
}

//TODO bruk postSubResource her
export function doDialToOpen(reservationId, authorization) {
    return fetchWithAuthorization(
        `/api/reservations/${reservationId}/dial-to-open`,
        authorization,
        {
            headers: {
                'content-type': 'application/json',
            },
            method: `POST`,
        }
    );
}

export function doFetchMembershipTypes(
    authorization,
    apiCallObject,
    partnerKey
) {
    return apiCallObject
        .setCallParams(
            `/api/memberships/types${
                partnerKey ? `?partnerKey=${partnerKey}` : ''
            }`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: `GET`,
            }
        )
        .issue();
}

export function doFetchMembership(authorization, membershipId, apiCallObject) {
    return apiCallObject
        .setCallParams(`/api/memberships/${membershipId}`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `GET`,
        })
        .issue();
}

export function doUpdatePersonMembership(
    authorization,
    membershipId,
    memberPersonId,
    personMembershipOptions,
    apiCallObject,
    admin
) {
    return apiCallObject
        .setCallParams(
            `/api/${
                admin ? 'admin/' : ''
            }memberships/${membershipId}/members/${memberPersonId}/default`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                body: JSON.stringify(personMembershipOptions),
                method: `PUT`,
            }
        )
        .issue();
}

export function doFetchMembershipMembers(
    authorization,
    membershipId,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/memberships/${membershipId}/members`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `GET`,
        })
        .issue();
}

export function doFetchMemberships(authorization, apiCallObject) {
    return apiCallObject
        .setCallParams(`/api/memberships`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `GET`,
        })
        .issue();
}

export function doUpdateMembership(
    authorization,
    membershipJson,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/memberships/${membershipJson.id}`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            body: JSON.stringify(membershipJson),
            method: `PUT`,
        })
        .issue();
}

export function doRemoveMembershipMember(
    authorization,
    membershipId,
    memberId,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/memberships/${membershipId}/members/${memberId}`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `DELETE`,
        })
        .issue();
}

/*
    Admin api calls
 */

//TODO fiks disse to, krever en endring i apiCallObject
export function fetchAdminCars(inputValue, authorization, orgId) {
    return simpleAdminSearch('/api/admin/cars/', inputValue, authorization, {
        orgId: orgId,
    });
}

export function fetchAdminPersons(query, authorization) {
    return simpleAdminSearch('/api/admin/persons/', query, authorization);
}

export function fetchAdminMemberships(
    inputValue,
    authorization,
    orgId,
    active
) {
    return simpleAdminSearch(
        '/api/admin/memberships/',
        inputValue,
        authorization,
        {
            orgId: orgId,
            active: active,
        }
    );
}

export function simpleFetchAdminModels(inputValue, authorization, orgId) {
    return simpleAdminSearch('/api/admin/models/', inputValue, authorization, {
        orgId: orgId,
    });
}

export function simpleFetchAdminLocations(inputValue, authorization, orgId) {
    return simpleAdminSearch(
        '/api/admin/locations/',
        inputValue,
        authorization,
        { orgId: orgId }
    );
}

export function simpleFetchAdminReservations(resNumber, auth, orgId) {
    return simpleAdminSearch('/api/admin/reservations/', resNumber, auth, {
        orgId: orgId,
    });
}

export function searchAdminReservations(
    inputValue,
    authorization,
    orgId,
    optionalFilters
) {
    return fetchWithAuthorization(
        `/api/admin/reservations?reservationNumber=${inputValue}${
            optionalFilters || ''
        }${orgId ? `&orgId=${orgId}` : ''}&size=20`,
        authorization
    );
}

/*

Trenger knytte dette opp mot members, actions osv

16:23

 */
export function getAdminMembershipSubMembers(
    membershipId,
    authorization,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/admin/memberships/${membershipId}/members`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
        })
        .issue();
}

/* Admin -- file upload api calls*/

export function doUploadFile(authorization, filetype, organizationId, file) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('filetype', filetype);
    formData.append('organizationId', organizationId);

    let headers = Object.assign(
        {},
        { 'content-type': 'application/json' },
        { authorization },
        { 'Content-Type': 'multipart/form-data' }
    );

    Object.keys(headers).forEach(function (k) {
        if (k.toLowerCase() === 'content-type') delete headers[k];
    });

    return fetch(`/api/admin/import`, {
        body: formData,
        headers,
        method: `POST`,
    });
}

/* Admin -- reservation api calls*/
export function getConflictsFromApi(
    authorization,
    apiCallObject,
    organizationId
) {
    return apiCallObject
        .setCallParams(
            `/api/admin/reservations/conflicts?organizationId=${organizationId}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
            }
        )
        .issue();
}

export function getMessagesFromApi(
    authorization,
    apiCallObject,
    organizationId
) {
    return apiCallObject
        .setCallParams(
            `/api/admin/reservations/messages?orgId=${organizationId}&handled=false`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
            }
        )
        .issue();
}

export function getAdminReservationMessageFromApi(
    authorization,
    id,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/admin/reservations/messages/${id}`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
        })
        .issue();
}

export function getAdminReservationMessagesFromApi(
    authorization,
    apiCallObject,
    filters,
    orgId,
    page
) {
    return apiCallObject
        .setCallParams(
            `/api/admin/reservations/messages?size=${apiCallSize}${
                page ? `&page=${page}` : ''
            }${filters}${orgId ? `&orgId=${orgId}` : ''}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: 'GET',
            }
        )
        .issue();
}

export function getAdminAdminReservationMessageMetadataFromApi(
    authorization,
    apiCallObject
) {
    return apiCallObject
        .setCallParams('/api/admin/reservations/messages/metadata', {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: 'GET',
        })
        .issue();
}

export function getAdminDamagesFromApi(
    authorization,
    apiCallObject,
    filters,
    orgId,
    page
) {
    return apiCallObject
        .setCallParams(
            `/api/admin/damages?size=${apiCallSize}${
                page ? `&page=${page}` : ''
            }${filters}${orgId ? `&orgId=${orgId}` : ''}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: 'GET',
            }
        )
        .issue();
}

export function getAdminAdminDamageMetadataFromApi(
    authorization,
    apiCallObject
) {
    return apiCallObject
        .setCallParams('/api/admin/damages/metadata', {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: 'GET',
        })
        .issue();
}

export function doPostPicture(authorization, file, path) {
    let formData = new FormData();
    formData.append('file', file);
    // formData.append('orientation', orientation);

    let headers = Object.assign(
        {},
        { 'content-type': 'application/json' },
        { authorization },
        { 'Content-Type': 'multipart/form-data' }
    );

    Object.keys(headers).forEach(function (k) {
        if (k.toLowerCase() === 'content-type') delete headers[k];
    });

    return fetch(path, {
        body: formData,
        headers,
        method: `POST`,
    });
}

export function postReservationPicture(authorization, reservationId, file) {
    return doPostPicture(
        authorization,
        file,
        `/api/reservations/${reservationId}/messages/add-picture`
    );
}

export function postDamagePicture(authorization, damageId, file) {
    return doPostPicture(
        authorization,
        file,
        `/api/admin/damages/${damageId}/add-picture`
    );
}

export function deleteDamagePicture(authorization, pictureId) {
    return fetch(`/api/admin/damages/pictures/${pictureId}`, {
        headers: {
            authorization: authorization,
            'content-type': 'application/json',
        },
        method: `DELETE`,
    });
}

export function getAdminReservationFromApi(authorization, id, apiCallObject) {
    return apiCallObject
        .setCallParams(`/api/admin/reservations/${id}`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
        })
        .issue();
}

export function sendAdminReservationToConvadis(
    authorization,
    id,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/admin/reservations/${id}/send-to-convadis`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `POST`,
        })
        .issue();
}

export function doInvoiceAdminReservation(authorization, id, apiCallObject) {
    return apiCallObject
        .setCallParams(`/api/admin/reservations/${id}/invoice`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `POST`,
        })
        .issue();
}

export function getReservationChangesFromApi(
    authorization,
    id,
    includeCommunication,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(
            `/api/admin/reservations/${id}/changelog?includeCommunication=${includeCommunication}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
            }
        )
        .issue();
}

export function getReservationChangeLogBody(
    authorization,
    id,
    changeLogId,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(
            `/api/admin/reservations/${id}/changelog/${changeLogId}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
            }
        )
        .issue();
}

export function getAdminReservationTripsFromApi(
    authorization,
    id,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/admin/reservations/${id}/trips`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
        })
        .issue();
}

export function createAdminReservationApiCall(
    authorization,
    reservation,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/admin/reservations/`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `POST`,
            body: JSON.stringify(reservation),
        })
        .issue();
}

export function getReservationsForMembershipFromApi(
    id,
    authorization,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/admin/memberships/${id}/reservations?size=100`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();
}

export function getReservationsForPersonFromApi(
    id,
    authorization,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/admin/persons/${id}/reservations?size=100`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();
}

export function getReservationsForCarFromApi(id, authorization, apiCallObject) {
    return apiCallObject
        .setCallParams(`/api/admin/cars/${id}/reservations?size=100`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();
}

export function searchAdminReservationByKey(
    key,
    value,
    authorization,
    apiCallObject,
    sort,
    desc
) {
    return apiCallObject
        .setCallParams(
            `/api/admin/reservations?${key}=${value}&size=100${
                sort ? `&sort=${sort}${desc ? ',desc' : ''}` : ''
            }`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
            }
        )
        .issue();
}

export function resetAdminReviewFlag(authorization, id) {
    return fetchWithAuthorization(
        `/api/admin/reservations/${id}/reset-review`,
        authorization,
        {
            method: `POST`,
        }
    );
}

export function getAdminReservationExtensibilityFromApi(
    id,
    authorization,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/admin/reservations/${id}/extend`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
        })
        .issue();
}

export function putAdminReservationApiCall(
    reservation,
    authorization,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/admin/reservations/${reservation.id}`, {
            body: JSON.stringify(reservation),
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `PUT`,
        })
        .issue();
}

/* Admin -- reservation api calls*/

export function getAdminReservationsFromApi(
    authorization,
    orgId,
    apiCallObject,
    filters,
    page
) {
    return apiCallObject
        .setCallParams(
            `/api/admin/reservations?size=${apiCallSize}${
                page ? `&page=${page}` : ''
            }${filters}&orgId=${orgId}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: 'GET',
            }
        )
        .issue();
}

export function getAdminReservationMetadataFromApi(
    authorization,
    apiCallObject
) {
    return apiCallObject
        .setCallParams('/api/admin/reservations/metadata', {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: 'GET',
        })
        .issue();
}

/* Admin -- admin reservation api calls*/

export function getAdminAdminReservationsFromApi(
    authorization,
    orgId,
    apiCallObject,
    filters,
    page
) {
    return apiCallObject
        .setCallParams(
            `/api/admin/admin-reservations?size=${apiCallSize}${
                page ? `&page=${page}` : ''
            }${filters}&orgId=${orgId}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: 'GET',
            }
        )
        .issue();
}

export function getAdminAdminReservationMetadataFromApi(
    authorization,
    apiCallObject
) {
    return apiCallObject
        .setCallParams('/api/admin/admin-reservations/metadata', {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: 'GET',
        })
        .issue();
}

// Admin Reservation Expenses Api Calls

export function getReservationExpensesMetadataFromApi(
    authorization,
    apiCallObject
) {
    return apiCallObject
        .setCallParams('/api/admin/reservation-expenses/metadata', {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: 'GET',
        })
        .issue();
}

export function getReservationExpensesDataFromApi(
    authorization,
    apiCallObject,
    filters,
    orgId,
    page,
    size
) {
    return apiCallObject
        .setCallParams(
            `/api/admin/reservation-expenses?size=${size ? size : apiCallSize}${
                page ? `&page=${page}` : ''
            }${filters}${orgId ? `&orgId=${orgId}` : ''}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: 'GET',
            }
        )
        .issue();
}

export async function doCreateCarImage(
    authorization,
    apiCallObject,
    imageFile,
    imageData
) {
    const imageBase64Promise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.onerror = reject;
        reader.readAsDataURL(imageFile);
    });

    return imageBase64Promise
        .then((imageBase64) => {
            const payload = {
                metaData: imageData,
                fileData: {
                    base64Data: imageBase64,
                    name: imageFile.name,
                    contentType: imageFile.type,
                },
            };

            return fetch(`/api/admin/car-image/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authorization,
                },
                body: JSON.stringify(payload),
            });
        })
        .catch((error) => {
            console.error('Error encoding image to base64:', error);
            throw error;
        });
}

export function doUpdateCarImage(
    authorization,
    apiCallObject,
    imageId,
    imageData
) {
    return apiCallObject
        .setCallParams(`/api/admin/car-image/${imageId}/update`, {
            body: JSON.stringify(imageData),
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `PUT`,
        })
        .issue();
}

export function doDeleteCarImage(authorization, apiCallObject, publicId) {
    return apiCallObject
        .setCallParams(`/api/admin/car-image/${publicId}`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: 'DELETE',
        })
        .issue();
}

export function doDeleteExpense(authorization, apiCallObject, id) {
    return apiCallObject
        .setCallParams(`/api/admin/reservation-expenses/${id}`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: 'DELETE',
        })
        .issue();
}

export function doSelectExpenseReservation(
    authorization,
    apiCallObject,
    expenseId,
    reservationId
) {
    return apiCallObject
        .setCallParams(
            `/api/admin/reservation-expenses/${expenseId}/create-invoice-line/${reservationId}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: 'POST',
            }
        )
        .issue();
}

// Admin Billing Api Calls

export function getAdminBillingDataFromApi(
    authorization,
    apiCallObject,
    filters,
    orgId,
    page
) {
    return apiCallObject
        .setCallParams(
            `/api/admin/billing?size=${apiCallSize}${
                page ? `&page=${page}` : ''
            }${filters}${orgId ? `&orgId=${orgId}` : ''}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: 'GET',
            }
        )
        .issue();
}

export function getAdminBillingMetadataFromApi(authorization, apiCallObject) {
    return apiCallObject
        .setCallParams('/api/admin/billing/metadata', {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: 'GET',
        })
        .issue();
}

export function doGetInvoices(authorization, membershipId, apiCallObject) {
    return apiCallObject
        .setCallParams(`/api/invoices?membershipId=${membershipId}`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: 'GET',
        })
        .issue();
}

export function doSendInvoices(authorization, apiCallObject, invoiceId) {
    return apiCallObject
        .setCallParams(`/api/admin/invoices/${invoiceId}/send`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: 'POST',
        })
        .issue();
}

export function doFetchAllCarImages(filters, authorization, orgId, page) {
    return fetchWithAuthorization(
        `/api/admin/car-image/images?size=100${orgId ? `&orgId=${orgId}` : ''}
        ${filters !== undefined ? filters : ''}
        ${page ? `&page=${page}` : ''}`,
        authorization
    );
}

export const getAdminCarImagesMetadataApiCall = (
    authorization,
    apiCallObject
) =>
    apiCallObject
        .setCallParams(`/api/admin/car-image/metadata`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();

export const getAdminProductVatCodesApiCall = (authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/products/vatcodes`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();

export const getAdminProductsMetadataApiCall = (authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/products/metadata`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();

export function doFetchAllProducts(filters, authorization, orgId, page) {
    return fetchWithAuthorization(
        `/api/admin/products?size=100${orgId ? `&orgId=${orgId}` : ''}
        ${filters !== undefined ? filters : ''}
        ${page ? `&page=${page}` : ''}`,
        authorization
    );
}

export function updateAdminProductApiCall(
    product,
    authorization,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/admin/products/${product.id}`, {
            body: JSON.stringify(product),
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `PUT`,
        })
        .issue();
}

export function createAdminProductApiCall(
    product,
    authorization,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/admin/products`, {
            body: JSON.stringify(product),
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `POST`,
        })
        .issue();
}

export function getAdminProduct(id, authorization, apiCallObject) {
    return apiCallObject
        .setCallParams(`/api/admin/products/${id}`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `GET`,
        })
        .issue();
}

export function getAdminProductCategoriesApiCall(authorization, apiCallObject) {
    return apiCallObject
        .setCallParams(`/api/admin/products/categories`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `GET`,
        })
        .issue();
}

export function doFetchProducts(authorization, orgId, searchString) {
    const queryString =
        '?sort=productNumber&size=100' +
        (searchString ? '&q=' + searchString : '');

    return fetchWithAuthorization(
        `/api/admin/products/search${queryString}${
            orgId ? `&orgId=${orgId}` : ''
        }`,
        authorization
    );
}

export function getAdminInvoiceMetadataFromApi(authorization, apiCallObject) {
    return apiCallObject
        .setCallParams('/api/admin/invoices/metadata', {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: 'GET',
        })
        .issue();
}

export function getAdminInvoiceDetailsFromApi(
    authorization,
    apiCallObject,
    invoiceId
) {
    return apiCallObject
        .setCallParams(`/api/admin/invoices/${invoiceId}`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: 'GET',
        })
        .issue();
}

export function getAdminInvoicesFromApi(
    authorization,
    apiCallObject,
    filters,
    orgId,
    page
) {
    return apiCallObject
        .setCallParams(
            `/api/admin/invoices?size=${apiCallSize}${
                page ? `&page=${page}` : ''
            }${filters}${orgId ? `&orgId=${orgId}` : ''}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: 'GET',
            }
        )
        .issue();
}

export function fetchActiveReservationsFromApi(authorization) {
    return fetchWithAuthorization(`/api/reservations/active`, authorization);
}

export function fetchHistoricReservationsFromApi(
    authorization,
    membershipId,
    apiCallObject,
    page
) {
    return apiCallObject
        .setCallParams(
            `/api/reservations/historic?size=20&sort=start,desc${
                page ? `&page=${page}` : ''
            }&membershipId=${membershipId}`,
            {
                headers: { authorization: authorization },
            }
        )
        .issue();
}

export function fetchReservationFromApi(id, authorization) {
    return fetchWithAuthorization(`/api/reservations/${id}`, authorization);
}

export function doFetchReservationDrivers(
    authorization,
    reservationId,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/reservations/${reservationId}/drivers`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `GET`,
        })
        .issue();
}

export function doPutReservationDrivers(
    authorization,
    reservationId,
    drivers,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/reservations/${reservationId}/drivers`, {
            body: JSON.stringify(drivers),
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `PUT`,
        })
        .issue();
}

export function extendReservation(reservationId, minutes, authorization) {
    return fetchWithAuthorization(
        `/api/reservations/${reservationId}/extend`,
        authorization,
        {
            body: JSON.stringify({ extendByMinutes: 30 }),
            headers: {
                'content-type': 'application/json',
            },
            method: `POST`,
        }
    );
}

export function fetchReservationExtensibilityFromAPI(
    reservationId,
    authorization
) {
    return fetchWithAuthorization(
        `/api/reservations/${reservationId}/extend`,
        authorization
    );
}

export function fetchDistanceFromAPI(fromLocation, toLocation) {
    const body = `
    {
        trip(
            from: {coordinates: {latitude: ${fromLocation.lat}, longitude: ${fromLocation.lng}}}
            to: {coordinates: {latitude: ${toLocation.lat}, longitude: ${toLocation.lng}}}
            numTripPatterns: 1
        ) {
            tripPatterns {
                streetDistance
            }
        }
    }
    `;

    return fetch('https://api.entur.io/journey-planner/v3/graphql', {
        method: 'POST',
        headers: {
            'content-Type': 'application/json',
            'et-client-name': 'Bildeleringen-Dele',
        },
        body: JSON.stringify({ query: body }),
    });
}

export function doFetchCityBikeData() {
    const body = `
    {
      bikeRentalStationsByBbox(
        minimumLatitude:58
        maximumLatitude:61
        minimumLongitude:4
        maximumLongitude:6
      )
    
      {
        id
        name
        bikesAvailable
        spacesAvailable
        realtimeOccupancyAvailable
        allowDropoff
        networks
        longitude
        latitude
      }
    }`;
    return fetch('https://api.entur.io/journey-planner/v3/graphql', {
        method: 'POST',
        headers: {
            'content-Type': 'application/json',
            'et-client-name': 'Bildeleringen-Dele',
        },
        body: JSON.stringify({ query: body }),
    });
}

export function fetchExternalLocationsByString(
    searchString,
    apiCallObject,
    focus = [60.38841601607084, 5.324249267578125]
) {
    // focus: results closer to this point are prioritized. Defaults to bergen city center without argument.
    const baseUrl = 'https://api.entur.io/geocoder/v1/search';

    let url = `${baseUrl}?text=${searchString}&focus.point.lat=${focus[0]}&focus.point.lon=${focus[1]}&lang=en`;

    url += `&boundary.county_ids=46,50`; // kun vis resultater i fylkene Vestland og Trøndelag
    // url += `&boundary.circle.lat=${focus[0]}&boundary.circle.lon=${focus[1]}&boundary.circle.radius=100`; // limit the search to a radius around Bergen Sentrum

    return apiCallObject
        .setCallParams(url, {
            headers: {
                'content-Type': 'application/json',
                'et-client-name': 'Bildeleringen-Dele',
            },
        })
        .issue();
}

export function fetchReservationsByCarID(
    carID,
    startTime,
    endTime,
    before,
    after,
    authorization,
    apiCallObject,
    optionalReservationId,
    optionalLocationId
) {
    let filters = {
        carIds: carID ? [carID] : undefined,
        locationIds: optionalLocationId ? [optionalLocationId] : undefined,
    };

    if (!endTime || !moment(endTime).isValid()) {
        endTime = moment(startTime).hour(23).minute(30).second(0);
    }

    let start = moment(startTime).format('YYYY-MM-DDTHH:mm');
    let end = moment(endTime).format('YYYY-MM-DDTHH:mm');
    return apiCallObject
        .setCallParams(
            `/api/search?start=${start}&end=${end}&before=${before}&after=${after}&filters=${encodeURIComponent(
                JSON.stringify(filters)
            )}${
                optionalReservationId
                    ? `&reservationId=${optionalReservationId}`
                    : ''
            }`,
            {
                headers: {
                    authorization: authorization,
                },
            }
        )
        .issue();
}

export function doLogin(userName, password) {
    let body = `username=${encodeURIComponent(
        userName
    )}&password=${encodeURIComponent(password)}`;
    return fetch('/api/login', {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        method: 'POST',
        body,
    });
}

export const doGcreLogin = (token) =>
    fetch('/api/partner/gcre/auth', {
        headers: {
            'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ token }),
    });

export function doLogout(authorization) {
    return fetch('/api/logout', {
        method: 'POST',
        headers: { authorization },
    });
}

export function doLinkVippsUser(authorization, apiCallObject, sub, phoneNo) {
    let headers = {
        'content-type': 'application/json',
        authorization,
    };
    return apiCallObject
        .setCallParams('/oauth/vipps/link', {
            method: `POST`,
            headers,
            body: JSON.stringify({ sub, phoneNo }),
        })
        .issue();
}

export function doUnlinkVippsUser(authorization, apiCallObject) {
    return apiCallObject
        .setCallParams('/oauth/vipps/unlink', {
            method: `DELETE`,
            headers: { authorization },
        })
        .issue();
}

export function doJoin(authorization, membership) {
    let headers = {
        'content-type': 'application/json',
    };
    if (authorization) headers.authorization = authorization;

    return fetch('/api/memberships/join', {
        headers,
        body: JSON.stringify(membership),
        method: 'POST',
    });
}

export function doAcceptInvitation(authorization, invitationToken) {
    let headers = {
        'content-type': 'application/json',
    };
    if (authorization) headers.authorization = authorization;

    return fetch('/api/memberships/join/accept-invitation', {
        headers,
        body: JSON.stringify(invitationToken),
        method: 'POST',
    });
}

export function doVippsJoin(user) {
    let headers = {
        'content-type': 'application/json',
    };
    return fetch('/oauth/vipps/create-vipps-user', {
        headers,
        body: JSON.stringify(user),
        method: 'POST',
    });
}

export function doSendMembershipInvitationEmail(
    authorization,
    membershipId,
    inviteeName,
    inviteeEmail,
    canCreateReservation,
    canCreatePrivateMembership
) {
    return fetch('/api/memberships/invite', {
        method: 'POST',
        headers: {
            authorization: authorization,
            'content-type': 'application/json',
        },
        body: JSON.stringify({
            membershipId,
            inviteeName,
            inviteeEmail,
            canCreateReservation,
            isGrantedChildMembership: canCreatePrivateMembership,
        }),
    });
}

export function doSendPasswordRetrievalEmail(usernameEmailOrPhone) {
    return fetch('/api/users/forgot-password', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({ usernameEmailOrPhone }),
    });
}

export function doResetPassword(resetPasswordObject) {
    return fetch('/api/users/forgot-password/reset', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(resetPasswordObject),
    });
}

export function doCreateUser(apiCallObject, userInfo) {
    return apiCallObject
        .setCallParams('/api/users/create', {
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(userInfo),
            method: `POST`,
        })
        .issue();
}

export function doFetchUserInfo(authorization, apiCallObject) {
    return apiCallObject
        .setCallParams('/api/persons', {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `GET`,
        })
        .issue();
}

export function doUpdateUserInfo(authorization, userInfoJson, apiCallObject) {
    return apiCallObject
        .setCallParams('/api/persons', {
            body: JSON.stringify(userInfoJson),
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `PUT`,
        })
        .issue();
}

export function doChangePassword(
    authorization,
    currentPassword,
    newPassword,
    apiCallObject
) {
    return apiCallObject
        .setCallParams('/api/users/change-password', {
            body: JSON.stringify({ currentPassword, newPassword }),
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `POST`,
        })
        .issue();
}

export function whoAmI(authorization) {
    return fetchWithAuthorization('/api/users/whoami', authorization);
}

export function doFetchOrganizations(apiCallObject) {
    return apiCallObject
        .setCallParams('/api/organizations', {
            headers: {
                'content-type': 'application/json',
            },
        })
        .issue();
}

export function promiseTimeout(promise, timeoutArg) {
    let didTimeOut = false;

    return new Promise((resolve, reject) => {
        const timeout = setTimeout(function () {
            didTimeOut = true;
            clearTimeout(timeout);
            reject(new Error('Request timed out'));
        }, timeoutArg);

        promise
            .then((response) => {
                clearTimeout(timeout);
                if (!didTimeOut) {
                    resolve(response);
                } else {
                    console.log('Call finished after timeout');
                }
            })
            .catch(function (err) {
                if (!didTimeOut) reject(err);
                // else reject has already happened
            });
    });
}

export function fetchWithAuthorization(
    req,
    authorization,
    requestInit /*TODO FLOW:?*/
) {
    let newRequestInit = requestInit ? { ...requestInit } : {};

    if (authorization) {
        newRequestInit.headers = newRequestInit.headers
            ? { ...newRequestInit.headers }
            : {};
        newRequestInit.headers.authorization = authorization;
    }

    return fetch(req, newRequestInit);
}

export function adminSearch(url, inputValue, authorization) {
    return fetchWithAuthorization(`${url}${inputValue}&size=20`, authorization);
}

export function getAdminMembershipsFromApi(
    authorization,
    filters,
    orgId,
    page
) {
    return fetchWithAuthorization(
        `/api/admin/memberships?size=${apiCallSize}${
            page ? `&page=${page}` : ''
        }${filters}${orgId ? `&orgId=${orgId}` : ''}`,
        authorization
    );
}

export function getAdminMembershipsMetadataFromApi(authorization) {
    return fetchWithAuthorization(
        `/api/admin/memberships/metadata`,
        authorization
    );
}

export const getAdminMembership = (id, authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/memberships/${id}`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();

export function doRefundMembershipDeposit(id, authorization) {
    return fetchWithAuthorization(
        `/api/admin/memberships/${id}/refund-deposit`,
        authorization
    );
}

export function postAdminMembershipSubResource(
    authorization,
    membershipId,
    subResourceKey,
    requestBody,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(
            `/api/admin/memberships/${membershipId}/${subResourceKey}`,
            {
                body: JSON.stringify(requestBody),
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: `POST`,
            }
        )
        .issue();
}

export function deleteAdminMembershipSubResource(
    authorization,
    membershipId,
    subResourceKey,
    resourceId,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(
            `/api/admin/memberships/${membershipId}/${subResourceKey}/${resourceId}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: `DELETE`,
            }
        )
        .issue();
}

export function putAdminMembershipApiCall(
    membership,
    authorization,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/admin/memberships/${membership.id}`, {
            body: JSON.stringify(membership),
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `PUT`,
        })
        .issue();
}

export function deleteAdminMembershipApiCall(
    membershipId,
    authorization,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(
            `/api/admin/memberships/delete-if-unused/${membershipId}`,
            {
                headers: {
                    authorization: authorization,
                },
                method: `DELETE`,
            }
        )
        .issue();
}

export function getAdminMembershipStates(authorization, apiCallObject) {
    return apiCallObject
        .setCallParams(`/api/admin/memberships/states`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
        })
        .issue();
}

export function getAdminMembershipTypes(authorization, orgId, apiCallObject) {
    return apiCallObject
        .setCallParams(`/api/admin/membershipTypes?orgId=${orgId}`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
        })
        .issue();
}

/*

    Admin persons api calls

*/
export const getAdminPersonsApiCall = (
    filters,
    page,
    authorization,
    apiCallObject
) =>
    apiCallObject
        .setCallParams(
            `/api/admin/persons?size=${apiCallSize}${
                page ? `&page=${page}` : ''
            }${filters}`,
            {
                headers: {
                    'content-type': 'application/json',
                    authorization: authorization,
                },
            }
        )
        .issue();

export const getAdminPersonsMetadataApiCall = (authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/persons/metadata`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();

export const getAdminPersonApiCall = (id, authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/persons/${id}`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();

export function adminChangePersonPassword(
    authorization,
    personId,
    newPassword,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/admin/persons/${personId}/change-password`, {
            body: JSON.stringify({ newPassword }),
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `POST`,
        })
        .issue();
}

export function postAdminPersonSubResource(
    authorization,
    personId,
    subResourceKey,
    requestBody,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/admin/persons/${personId}/${subResourceKey}`, {
            body: JSON.stringify(requestBody),
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `POST`,
        })
        .issue();
}

export function deleteAdminPersonSubResource(
    authorization,
    personId,
    subResourceKey,
    resourceId,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(
            `/api/admin/persons/${personId}/${subResourceKey}/${resourceId}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: `DELETE`,
            }
        )
        .issue();
}

export function deleteAdminUnusedPerson(
    authorization,
    personId,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/admin/persons/delete/${personId}`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `DELETE`,
        })
        .issue();
}

export const getAdminPersonMembershipsApiCall = (
    id,
    authorization,
    apiCallObject
) =>
    apiCallObject
        .setCallParams(`/api/admin/persons/${id}/memberships`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();

export const getAdminPersonCardsApiCall = (id, authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/persons/${id}/cards`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();

export const putAdminPersonApiCall = (person, authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/persons/${person.id}`, {
            body: JSON.stringify(person),
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `PUT`,
        })
        .issue();

export const putAdminPersonDriversLicenseValidApiCall = (
    person,
    authorization,
    apiCallObject
) =>
    apiCallObject
        .setCallParams(
            `/api/admin/persons/${person.id}/driverslicense?valid=${person.driversLicenseValid}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: `PUT`,
            }
        )
        .issue();

export function createAdminPersonApiCall(authorization, person, apiCallObject) {
    return apiCallObject
        .setCallParams(`/api/admin/persons/`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `POST`,
            body: JSON.stringify(person),
        })
        .issue();
}

export const getAdminPersoncardIndexByCardIdApiCall = (
    authorization,
    cardNumber,
    apiCallObject
) =>
    apiCallObject
        .setCallParams(`/api/admin/member-cards?cardNumber=${cardNumber}`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();

/*

    Admin cars api calls

*/
export const getAdminCarsApiCall = (
    filters,
    orgId,
    page,
    authorization,
    apiCallObject
) =>
    apiCallObject
        .setCallParams(
            `/api/admin/cars?size=${apiCallSize}${
                page ? `&page=${page}` : ''
            }${filters}${orgId ? `&orgId=${orgId}` : ''}`,
            {
                headers: {
                    'content-type': 'application/json',
                    authorization: authorization,
                },
            }
        )
        .issue();

export const getAdminCarsMetadataApiCall = (authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/cars/metadata`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();

export const getAdminCarApiCall = (id, authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/cars/${id}`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();

export function postAdminCarSubResource(
    authorization,
    carId,
    subResourceKey,
    requestBody,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(`/api/admin/cars/${carId}/${subResourceKey}`, {
            body: JSON.stringify(requestBody),
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `POST`,
        })
        .issue();
}

export function deleteAdminCarSubResource(
    authorization,
    carId,
    subResourceKey,
    resourceId,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(
            `/api/admin/cars/${carId}/${subResourceKey}/${resourceId}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: `DELETE`,
            }
        )
        .issue();
}

export const putAdminCarApiCall = (car, authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/cars/${car.id}`, {
            body: JSON.stringify(car),
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `PUT`,
        })
        .issue();

export function createAdminCarApiCall(authorization, car, apiCallObject) {
    return apiCallObject
        .setCallParams(`/api/admin/cars`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `POST`,
            body: JSON.stringify(car),
        })
        .issue();
}

/*

    Admin car assignments api calls

*/

export const fetchAdminAssignments = (
    filters,
    orgId,
    page,
    authorization,
    apiCallObject
) =>
    apiCallObject
        .setCallParams(
            `/api/admin/assignments?size=${apiCallSize}${
                page ? `&page=${page}` : ''
            }${filters}${orgId ? `&orgId=${orgId}` : ''}`,
            {
                headers: {
                    'content-type': 'application/json',
                    authorization: authorization,
                },
            }
        )
        .issue();

export const fetchAdminAssignmentsMetadata = (authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/assignments/metadata`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();

export const fetchAdminAssignmentsByCarId = (
    authorization,
    apiCallObject,
    carId
) =>
    apiCallObject
        .setCallParams(`/api/admin/cars/${carId}/assignments`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `GET`,
        })
        .issue();

export const fetchAdminAssignment = (authorization, apiCallObject, id) =>
    apiCallObject
        .setCallParams(`/api/admin/assignments/${id}`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `GET`,
        })
        .issue();

export const postAdminAssignment = (authorization, apiCallObject, assignment) =>
    apiCallObject
        .setCallParams(`/api/admin/cars/${assignment.car.id}/assignments`, {
            // .setCallParams(`/api/admin/assignments`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `POST`,
            body: JSON.stringify(assignment),
            // body: JSON.stringify(assignment)
        })
        .issue();

export const putAdminAssignment = (authorization, apiCallObject, assignment) =>
    apiCallObject
        .setCallParams(`/api/admin/cars/${assignment.car.id}/assignments`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `PUT`,
            body: JSON.stringify(assignment),
            // body: JSON.stringify(assignment)
        })
        .issue();

export const doDeleteAdminAssignment = (
    authorization,
    apiCallObject,
    assignment
) =>
    apiCallObject
        .setCallParams(
            `/api/admin/cars/${assignment.carId}/assignments/${assignment.id}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: `DELETE`,
                body: JSON.stringify(assignment),
            }
        )
        .issue();

/*

    Admin car models api calls

*/

export const fetchAdminModels = (authorization, orgId, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/models?orgId=${orgId}&size=9999`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `GET`,
        })
        .issue();

export const getAdminCarTemplate = (authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/cars/template`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `GET`,
        })
        .issue();

export const getAdminCarIcons = (
    authorization,
    organizationId,
    apiCallObject
) =>
    apiCallObject
        .setCallParams(
            `/api/admin/cars/icons?organizationId=${organizationId}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: `GET`,
            }
        )
        .issue();

export function getAdminCarFuelCards(carId, authorization, apiCallObject) {
    return apiCallObject
        .setCallParams(`/api/admin/cars/${carId}/fuelCards`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
        })
        .issue();
}

/*

    Admin model api calls

*/
export const getAdminModelsApiCall = (
    filters,
    orgId,
    page,
    authorization,
    apiCallObject
) =>
    apiCallObject
        .setCallParams(
            `/api/admin/models?size=${apiCallSize}${
                page ? `&page=${page}` : ''
            }${filters}${orgId ? `&orgId=${orgId}` : ''}`,
            {
                headers: {
                    'content-type': 'application/json',
                    authorization: authorization,
                },
            }
        )
        .issue();

export const getAdminModelsMetadataApiCall = (authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/models/metadata`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();

export const getAdminModelApiCall = (id, authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/models/${id}`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();

export const putAdminModelApiCall = (model, authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/models/${model.id}`, {
            body: JSON.stringify(model),
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `PUT`,
        })
        .issue();

export function createAdminModelApiCall(authorization, model, apiCallObject) {
    return apiCallObject
        .setCallParams(`/api/admin/models`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `POST`,
            body: JSON.stringify(model),
        })
        .issue();
}

export const fetchAdminCarCategories = (authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/car-categories/`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `GET`,
        })
        .issue();

export const fetchChargingCableTypes = (authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/models/chargers/`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `GET`,
        })
        .issue();

export const getAdminModelTemplate = (authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/models/template`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `GET`,
        })
        .issue();

/*

    EntityMessages api calls

*/

export const fetchEntityMessagesApiCall = (
    authorization,
    apiCallObject,
    reservationIds
) =>
    apiCallObject
        .setCallParams(`/api/entity-messages`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `POST`,
            body: JSON.stringify({ reservationIds }),
        })
        .issue();

/*

    Admin EntityMessages api calls

*/
export const getAdminEntityMessagesApiCall = (
    filters,
    orgId,
    page,
    authorization,
    apiCallObject
) =>
    apiCallObject
        .setCallParams(
            `/api/admin/entity-messages?size=${apiCallSize}${
                page ? `&page=${page}` : ''
            }${filters}${orgId ? `&orgId=${orgId}` : ''}`,
            {
                headers: {
                    'content-type': 'application/json',
                    authorization: authorization,
                },
            }
        )
        .issue();

export const getAdminEntityMessagesMetadataApiCall = (
    authorization,
    apiCallObject
) =>
    apiCallObject
        .setCallParams(`/api/admin/entity-messages/metadata`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();

export const getAdminEntityMessageCall = (id, authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/entity-messages/${id}`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();

export const postAdminEntityMessage = (authorization, apiCallObject, message) =>
    apiCallObject
        .setCallParams(`/api/admin/entity-messages/`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `POST`,
            body: JSON.stringify(message),
        })
        .issue();

export const deleteAdminEntityMessageCall = (
    id,
    authorization,
    apiCallObject
) =>
    apiCallObject
        .setCallParams(`/api/admin/entity-messages/${id}`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
            method: `DELETE`,
        })
        .issue();

export const putAdminEntityMessage = (authorization, apiCallObject, message) =>
    apiCallObject
        .setCallParams(`/api/admin/entity-messages/${message.id}`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `PUT`,
            body: JSON.stringify(message),
        })
        .issue();

/*

    Admin location api calls

*/
export const getAdminLocationsApiCall = (
    filters,
    page,
    authorization,
    apiCallObject,
    organizationId
) =>
    apiCallObject
        .setCallParams(
            `/api/admin/locations?size=${apiCallSize}${
                page ? `&page=${page}` : ''
            }${organizationId ? `&orgId=${organizationId}` : ''}
            ${filters}`,
            {
                headers: {
                    'content-type': 'application/json',
                    authorization: authorization,
                },
            }
        )
        .issue();

export const getAdminLocationsMetadataApiCall = (
    authorization,
    apiCallObject
) =>
    apiCallObject
        .setCallParams(`/api/admin/locations/metadata`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();

export const getAdminLocationApiCall = (id, authorization, apiCallObject) =>
    apiCallObject
        .setCallParams(`/api/admin/locations/${id}`, {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
            },
        })
        .issue();

export const adminSendSms = (authorization, messageText, recipientIds) =>
    fetch('/api/admin/sms/send', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            authorization: authorization,
        },
        body: JSON.stringify({ messageText, recipientIds }),
    });

export function doFetchTags(authorization, apiCallObject) {
    return apiCallObject
        .setCallParams(`/api/situations/tags`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: `OPTIONS`,
        })
        .issue();
}

export function doFetchSituations(
    apiCallObject,
    reservation,
    amount,
    tags,
    auth
) {
    if (deviceIsMobile()) tags.push('FROM_APP');
    else tags.push('FROM_WEBAPP');
    let url = `/api/situations?reservation_id=${reservation.id}&amount=${amount}`;
    if (tags.length > 0) url += '&tags=' + tags.join(',');
    return get(url, apiCallObject, auth);
}

export function getSituation(id, authorization, apiCallObject) {
    return apiCallObject
        .setCallParams(`/api/admin/situations/${id}`, {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: 'GET',
        })
        .issue();
}

export function getSpecificSolutions(
    page,
    filters,
    orgId,
    authorization,
    apiCallObject
) {
    return apiCallObject
        .setCallParams(
            `/api/admin/situations/specific?size=${apiCallSize}${
                page ? `&page=${page}` : ''
            }&orgId=${orgId}${filters}`,
            {
                headers: {
                    authorization: authorization,
                    'content-type': 'application/json',
                },
                method: 'GET',
            }
        )
        .issue();
}

export function getSpecificSolutionsMetadata(authorization, apiCallObject) {
    return apiCallObject
        .setCallParams('/api/admin/situations/specific/metadata', {
            headers: {
                authorization: authorization,
                'content-type': 'application/json',
            },
            method: 'GET',
        })
        .issue();
}
