import { createApi } from '@reduxjs/toolkit/query/react';
import {
    authenticatedQuery,
    adminListQuery,
} from '../../../../../utilities/RTK/RTKUtils';
import { Metadata, ProductIndex } from '../../../../types/admin';
import { CursorPaginatedResult } from '../../../../../utilities/RTK/RTKTypes';
import { AdminListQueryParams } from '../../../../common/AdminList/AdminListRTK';

export const productApi = createApi({
    reducerPath: 'admin-product',
    baseQuery: authenticatedQuery('/api/admin/products'),

    endpoints: (builder) => ({
        getProducts: builder.query<
            CursorPaginatedResult<ProductIndex>,
            AdminListQueryParams
        >({
            query: adminListQuery,
        }),
        getProductsMetadata: builder.query<Metadata<ProductIndex>, void>({
            query: () => '/metadata',
        }),
    }),
});

export const { useGetProductsQuery, useGetProductsMetadataQuery } = productApi;
