import { SearchPageState } from '../app/pages/SearchPage/SearchPageConstants';
import { ProfilePageState } from '../app/pages/ProfilePage/duck/constants';
import {
    AdminCarPageState,
    AdminMembershipPageState,
    AdminModelPageState,
    AdminPersonPageState,
    AdminReservationPageState,
} from '../app/pages/Admin/adminPageConstants';
import { ReservationPageState } from '../app/pages/ReservationsPage/duck/constants';
import { JoinPageState } from '../app/pages/ProfilePage/Components/Join/constants';
import { useHistory, useLocation } from 'react-router-dom';

// RouterState must always correspond to RouterStateDisjunction.
export type RouterState = SearchPageState &
    ReservationPageState &
    ProfilePageState &
    JoinPageState &
    AdminMembershipPageState &
    AdminReservationPageState &
    AdminPersonPageState &
    AdminCarPageState &
    AdminModelPageState;

// RouterStateDisjunction must always correspond to RouterState.
export type RouterStateDisjunction =
    | SearchPageState
    | ReservationPageState
    | ProfilePageState
    | JoinPageState
    | AdminMembershipPageState
    | AdminReservationPageState
    | AdminPersonPageState
    | AdminCarPageState
    | AdminModelPageState;

/**
 * This is an abstraction over `History` and `RouterLocation` to manipulate the router state with better typing.
 *
 * This hook intentionally narrows down the type of `RouterState`, so that we are forced to specify which state we want.
 * With this, we can only set and get fields of the specified state, and we avoid distractions and confusion.
 */
export function useRouterState<S extends RouterStateDisjunction>() {
    const history = useHistory();
    const location = useLocation();

    function goBack() {
        history.goBack();
    }
    function pushRouterPath(pathname: string, nextState?: S) {
        history.push(pathname, nextState);
    }
    function replaceRouterPath(pathname: string, nextState?: S) {
        history.replace(pathname, nextState);
    }
    function pushRouterState(nextState: S) {
        history.push({ state: nextState });
    }
    function replaceRouterState(newState: S) {
        history.replace({ state: newState });
    }
    const routerState = (location.state as S) || {};

    return {
        goBack,
        pushRouterPath,
        replaceRouterPath,
        pushRouterState,
        replaceRouterState,
        routerState,
    };
}
