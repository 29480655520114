import React, { lazy, Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { FeatureToggler } from '../../components/FeatureToggler';
import { P } from '../Typography';
import { Theme } from '../../../utilities/types';
import { Membership, Organization } from '../../types/common';
import { AppRoute } from './AppRoute';

const AdminTripletexConfiguration = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminTripletex/AdminTripletexConfiguration/AdminTripletexConfiguration'
        )
);
const FeatureTogglesPage = lazy(
    () => import('../../pages/Admin/FeatureToggles/FeatureTogglesPage')
);
const LoadingPage = lazy(() => import('../LoadingPage/LoadingPage'));
const ErrorPage = lazy(() => import('../../pages/ErrorPage/ErrorPage'));
const DebugPage = lazy(() => import('../../pages/DebugPage/DebugPage'));
const AdminNewMemberCard = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminMemberCards/AdminNewMemberCard/AdminNewMemberCardContainer'
        )
);
const AdminProducts = lazy(
    () => import('../../pages/Admin/subPages/AdminProducts/AdminProducts')
);
const AdminNewEntityMessage = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminEntityMessages/AdminEntityMessage/AdminNewEntityMessage'
        )
);

const AdminImport = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminImportPage/AdminImportContainer'
        )
);
const GcreLanding = lazy(
    () => import('../../components/Partners/Gcre/GcreLandingContainer')
);
const OidcLoginSuccess = lazy(
    () => import('../../components/OidcLoginSuccess')
);
const AdminLocations = lazy(
    () => import('../../pages/Admin/subPages/AdminLocations/AdminLocations')
);
const AdminLocation = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminLocations/AdminLocationContainer'
        )
);
const AdminNewLocation = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminLocations/AdminNewLocation/AdminNewLocationContainer'
        )
);
const SharedReservation = lazy(
    () => import('../../pages/SharedReservation/SharedReservationContainer')
);
const AdminReservationExpenses = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminBilling/AdminReservationExpenses/AdminReservationExpensesContainer'
        )
);
const AdminAssignments = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminAssignmentsPage/AdminAssignments'
        )
);
const AdminAdminReservations = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminAdminReservations/AdminAdminReservations'
        )
);
const AdminReservationMessages = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminReservations/AdminReservationMessages/AdminReservationMessages'
        )
);
const AdminReservationMessage = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminReservations/AdminReservationMessages/AdminReservationMessage/AdminReservationMessageContainer'
        )
);
const AdminDamage = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminDamages/AdminDamage/AdminDamageContainer'
        )
);
const AdminEntityMessage = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminEntityMessages/AdminEntityMessage/AdminEntityMessageContainer'
        )
);
const AdminDamages = lazy(
    () => import('../../pages/Admin/subPages/AdminDamages/AdminDamages')
);
const AdminNewDamage = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminDamages/AdminDamage/AdminNewDamage/AdminNewDamageContainer'
        )
);
const LoginComplete = lazy(() => import('../../components/LoginComplete'));
const LoginFailed = lazy(() => import('../../components/LoginFailed'));
const LinkOrCreateUser = lazy(
    () => import('../../components/LinkOrCreateUser/LinkOrCreateUserContainer')
);
const VerifyLicense = lazy(
    () => import('../../pages/VerifyLicense/VerifyLicense')
);
const LinkExistingUser = lazy(
    () =>
        import(
            '../../components/LinkOrCreateUser/LinkExistingUser/LinkExistingUserContainer'
        )
);
const SearchPage = lazy(() => import('../../pages/SearchPage'));
const ReservationsPage = lazy(() => import('../../pages/ReservationsPage'));
const InvoicesPage = lazy(() => import('../../pages/InvoicesPage'));
const PaymentFeedbackPage = lazy(
    () => import('../../pages/PaymentFeedbackPage')
);
const ProfilePage = lazy(() => import('../../pages/ProfilePage'));
const LocationPage = lazy(
    () => import('../../pages/LocationPage/LocationPage')
);
const ForgotPasswordPage = lazy(
    () => import('../../pages/ForgotPasswordPage/ForgotPasswordPageContainer')
);
const Join = lazy(
    () => import('../../pages/ProfilePage/Components/Join/JoinContainer')
);
const Admin = lazy(() => import('../../pages/Admin'));
const AdminSearchPage = lazy(
    () => import('../../pages/Admin/subPages/AdminSearchPage')
);
const AdminMemberships = lazy(
    () => import('../../pages/Admin/subPages/AdminMemberships')
);
const AdminMemberCards = lazy(
    () => import('../../pages/Admin/subPages/AdminMemberCards')
);
const AdminMemberCard = lazy(
    () => import('../../pages/Admin/subPages/AdminMemberCards/AdminMemberCard')
);
const AdminMembership = lazy(
    () => import('../../pages/Admin/subPages/AdminMemberships/AdminMembership')
);
const AdminOverview = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminHelp/AdminHelpOverview/AdminHelpContainer'
        )
);
const AdminHelpSpecificSolution = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminHelp/AdminHelpOverview/AdminSpecificSolutionOverviewContainer'
        )
);
const AdminOverviewEdit = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminHelp/AdminHelpEdit/AdminHelpEditContainer'
        )
);
const AdminOverviewSpecificEdit = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminHelp/AdminHelpEdit/AdminHelpEditSpecificContainer'
        )
);
const AdminPersons = lazy(
    () => import('../../pages/Admin/subPages/AdminPersons/AdminPersons')
);
const AdminNewPerson = lazy(
    () => import('../../pages/Admin/subPages/AdminPersons/AdminNewPerson')
);
const AdminPerson = lazy(
    () => import('../../pages/Admin/subPages/AdminPersons/AdminPerson')
);
const AdminReservations = lazy(
    () => import('../../pages/Admin/subPages/AdminReservations')
);
const AdminNewReservation = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminReservations/AdminNewReservation'
        )
);
const AdminReservation = lazy(
    () =>
        import('../../pages/Admin/subPages/AdminReservations/AdminReservation')
);
const AdminCars = lazy(
    () => import('../../pages/Admin/subPages/AdminCars/AdminCars')
);
const AdminNewCar = lazy(
    () => import('../../pages/Admin/subPages/AdminCars/AdminNewCar')
);
const AdminEntityMessages = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminEntityMessages/AdminEntityMessages'
        )
);
const AdminCar = lazy(
    () => import('../../pages/Admin/subPages/AdminCars/AdminCar')
);
const AdminModels = lazy(
    () => import('../../pages/Admin/subPages/AdminModels')
);
const AdminNewModel = lazy(
    () => import('../../pages/Admin/subPages/AdminModels/AdminNewModel')
);
const AdminModel = lazy(
    () => import('../../pages/Admin/subPages/AdminModels/AdminModel')
);
const AdminBilling = lazy(
    () =>
        import('../../pages/Admin/subPages/AdminBilling/AdminBillingContainer')
);
const AdminBillingSummary = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminBilling/AdminBillingSummary/AdminBillingSummaryContainer'
        )
);
const AdminNewProduct = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminProducts/AdminNewProduct/AdminNewProductContainer'
        )
);
const AdminInvoices = lazy(
    () =>
        import('../../pages/Admin/subPages/AdminInvoice/AdminInvoiceContainer')
);
const AdminTripletexErrors = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminTripletex/AdminTripletexErrors/AdminTripletexErrors'
        )
);
const JoinTerms = lazy(
    () => import('../../pages/ProfilePage/Components/Join/JoinTerms')
);

const AdminCarImage = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminCarImage/AdminCarImageContainer'
        )
);

const AdminNewCarImage = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminCarImage/AdminNewCarImage/AdminNewCarImageContainer'
        )
);

type Props = {
    theme: Theme;
    organizations: Organization[];
    logout: () => void;
    selectedMembership?: Membership;
};

const Routes = (props: Props) => (
    <Suspense fallback={<div />}>
        <Switch>
            <AppRoute
                exact
                path="/"
                render={() => <Redirect to="/findcar" />}
            />
            <AppRoute
                exact
                path="/loginComplete"
                render={(location) => <LoginComplete location={location} />}
            />
            <AppRoute
                exact
                path="/oidc-login-success"
                render={(location) => <OidcLoginSuccess location={location} />}
            />
            <AppRoute
                exact
                path="/linkOrCreateUser"
                render={(location) => <LinkOrCreateUser location={location} />}
            />
            <AppRoute
                exact
                path="/partner/gcre/auth"
                render={(location) => (
                    <FeatureToggler
                        toggleName={'gcre landing page'}
                        hideIfToggleOff
                    >
                        <GcreLanding location={location} />
                    </FeatureToggler>
                )}
            />
            <AppRoute
                exact
                path="/linkExistingUser"
                render={(location) => (
                    <LinkExistingUser location={location} onlyLink />
                )}
            />
            <AppRoute
                exact
                path="/loginFailed"
                render={() => <LoginFailed />}
            />
            <AppRoute
                exact
                path="/logout"
                render={() => {
                    props.logout();
                    return <Redirect to="/findcar" />;
                }}
            />
            <AppRoute
                exact
                path="/index"
                render={() => <Redirect to="/findcar" />}
            />
            <AppRoute
                exact
                path="/index.html"
                render={() => <Redirect to="/findcar" />}
            />
            <AppRoute
                exact
                path="/findcar"
                render={(location) => <SearchPage location={location} />}
            />
            <AppRoute
                exact
                path="/verifyLicense"
                requireAuthentication
                render={(location) => <VerifyLicense location={location} />}
            />
            <AppRoute
                path="/reservations/:id"
                render={(location) => <ReservationsPage location={location} />}
                requireAuthentication
            />
            <AppRoute
                path="/shared/:id"
                render={(location) => (
                    <SharedReservation
                        id={location.match.params.id}
                        location={location}
                    />
                )}
            />
            <AppRoute
                path="/vipps/v2/fallback/:id"
                requireAuthentication
                render={(location) => (
                    <PaymentFeedbackPage location={location} />
                )}
            />
            <AppRoute
                path="/invoices/:id"
                render={() => <Redirect to="/invoices" />}
            />
            <AppRoute
                path="/invoices"
                requireAuthentication
                render={(location) =>
                    props.selectedMembership?.isOwner ? (
                        <InvoicesPage location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <AppRoute
                path={'/reservations'}
                render={(location) => <ReservationsPage location={location} />}
                requireAuthentication
            />
            <AppRoute
                path="/reset-password"
                render={(location) => (
                    <ForgotPasswordPage location={location} />
                )}
            />
            <AppRoute
                path="/profile"
                requireAuthentication
                render={(location) => <ProfilePage location={location} />}
            />
            <AppRoute
                path="/join"
                render={(location) => <Join location={location} />}
            />
            <AppRoute
                path="/terms"
                render={(location) => (
                    <JoinTerms theme={props.theme} location={location} />
                )}
            />
            <AppRoute
                path="/locations/:id"
                render={(location) => (
                    <LocationPage locationId={location.match.params.id} />
                )}
            />
            <AppRoute exact path="/loading" render={() => <LoadingPage />} />
            <AppRoute
                exact
                path="/admin"
                requireAdmin
                render={(location) => <Admin location={location} />}
            />
            <AppRoute
                exact
                path="/admin/feature-toggles"
                requireAdmin
                render={(location) => (
                    <FeatureTogglesPage
                        theme={props.theme}
                        location={location}
                    />
                )}
            />
            <AppRoute
                exact
                path="/admin/search"
                requireAdmin
                render={(location) => <AdminSearchPage location={location} />}
            />
            <AppRoute
                exact
                path="/admin/user-management/memberships"
                requireAdmin
                render={(location) => <AdminMemberships location={location} />}
            />
            <AppRoute
                exact
                path="/admin/user-management/member-cards/new"
                requireAdmin
                render={(location) => (
                    <AdminNewMemberCard location={location} />
                )}
            />
            <AppRoute
                exact
                path="/admin/billing-management/products/new"
                requireAdmin
                render={(location) => <AdminNewProduct location={location} />}
            />
            <AppRoute
                exact
                path="/admin/user-management/member-cards/:id"
                requireAdmin
                render={(location) => (
                    <AdminMemberCard cardId={location.match.params.id} />
                )}
            />
            <AppRoute
                exact
                path="/admin/user-management/member-cards"
                requireAdmin
                render={(location) => <AdminMemberCards location={location} />}
            />
            <AppRoute
                path="/admin/user-management/memberships/:id"
                requireAdmin
                render={(location) => (
                    <AdminMembership
                        location={location}
                        membershipId={location.match.params.id}
                    />
                )}
            />
            <AppRoute
                exact
                path="/admin/user-management/persons"
                requireAdmin
                render={(location) => <AdminPersons location={location} />}
            />
            <AppRoute
                exact
                path="/admin/user-management/persons/new"
                requireAdmin
                render={(location) => <AdminNewPerson location={location} />}
            />
            <AppRoute
                path="/admin/user-management/persons/:id"
                requireAdmin
                render={(location) => (
                    <AdminPerson
                        location={location}
                        personId={location.match.params.id}
                    />
                )}
            />
            <AppRoute
                exact
                path="/admin/user-management"
                render={() => <Redirect to="/admin/user-management/persons" />}
            />
            <AppRoute
                exact
                path="/admin/billing-management/invoices"
                requireAdmin
                render={(location) => <AdminInvoices location={location} />}
            />
            <AppRoute
                exact
                path="/admin/billing-management/invoice"
                requireAdmin
                render={() => <AdminBillingSummary />}
            />
            <AppRoute
                exact
                path="/admin/billing-management/products"
                requireAdmin
                render={(location) => <AdminProducts location={location} />}
            />
            <AppRoute
                exact
                path="/admin/billing-management/tripletex-errors"
                requireAdmin
                render={(location) => (
                    <AdminTripletexErrors location={location} />
                )}
            />
            <AppRoute
                exact
                path="/admin/billing-management/tripletex-configuration"
                requireAdmin
                render={(location) => (
                    <AdminTripletexConfiguration location={location} />
                )}
            />
            <AppRoute
                exact
                path="/admin/billing-management/billing"
                requireAdmin
                render={(location) => <AdminBilling location={location} />}
            />
            <AppRoute
                exact
                path="/admin/billing-management/expenses"
                requireAdmin
                render={(location) => (
                    <AdminReservationExpenses location={location} />
                )}
            />
            <AppRoute
                exact
                path="/admin/billing-management"
                render={() => (
                    <Redirect
                        to={{
                            pathname: '/admin/billing-management/billing',
                            search: 'needsReview=true',
                        }}
                    />
                )}
            />
            <AppRoute
                exact
                path="/admin/reservation-management"
                render={() => (
                    <Redirect to="/admin/reservation-management/reservations" />
                )}
            />
            <AppRoute
                path={'/admin/reservation-management/reservations'}
                exact
                requireAdmin
                render={(location) => <AdminReservations location={location} />}
            />
            <AppRoute
                exact
                path="/admin/help/edit"
                requireAdmin
                render={(location) => <AdminOverview location={location} />}
            />
            <AppRoute
                exact
                path={'/admin/help/overview'}
                requireAdmin
                render={(location) => (
                    <AdminHelpSpecificSolution location={location} />
                )}
            />
            <AppRoute
                exact
                path="/admin/help/edit/:id"
                requireAdmin
                render={(location) => (
                    <AdminOverviewEdit
                        location={location}
                        situationId={location.match.params.id}
                    />
                )}
            />
            <AppRoute
                exact
                path="/admin/help/edit/specific/:id"
                requireAdmin
                render={(location) => (
                    <AdminOverviewSpecificEdit location={location} />
                )}
            />
            <AppRoute
                exact
                path="/admin/reservation-management/messages"
                requireAdmin
                render={(location) => (
                    <AdminReservationMessages location={location} />
                )}
            />
            <AppRoute
                exact
                path="/admin/damages"
                requireAdmin
                render={(location) => <AdminDamages location={location} />}
            />
            <AppRoute
                exact
                path="/admin/reservation-management/work-list"
                requireAdmin
                render={(location) => (
                    <AdminAdminReservations location={location} />
                )}
            />
            <AppRoute
                exact
                path="/admin/reservation-management/reservations/new"
                requireAdmin
                render={(location) => (
                    <AdminNewReservation location={location} />
                )}
            />
            <AppRoute
                path="/admin/reservation-management/reservations/:id"
                requireAdmin
                render={(location) => (
                    <AdminReservation
                        location={location}
                        reservationId={location.match.params.id}
                    />
                )}
            />
            <AppRoute
                path="/admin/reservation-management/messages/:id"
                requireAdmin
                render={(location) => (
                    <AdminReservationMessage
                        location={location}
                        messageId={location.match.params.id}
                    />
                )}
            />
            <AppRoute
                exact
                path="/admin/damages/new"
                requireAdmin
                render={(location) => <AdminNewDamage location={location} />}
            />
            <AppRoute
                path="/admin/damages/:id"
                requireAdmin
                render={(location) => (
                    <AdminDamage
                        location={location}
                        damageId={location.match.params.id}
                    />
                )}
            />
            <AppRoute
                exact
                path="/admin/fleet-management/cars"
                requireAdmin
                render={(location) => <AdminCars location={location} />}
            />
            <AppRoute
                exact
                path="/admin/fleet-management/assignments"
                requireAdmin
                render={(location) => <AdminAssignments location={location} />}
            />
            <AppRoute
                exact
                path="/admin/fleet-management/cars/new"
                requireAdmin
                render={(location) =>
                    props.organizations.length === 0 ? (
                        <P>Henter organisasjoner.</P>
                    ) : (
                        <AdminNewCar location={location} />
                    )
                }
            />
            <AppRoute
                exact
                path="/admin/fleet-management/entity-messages"
                requireAdmin
                render={(location) => (
                    <AdminEntityMessages location={location} />
                )}
            />
            <AppRoute
                exact
                path="/admin/fleet-management/entity-messages/new"
                requireAdmin
                render={(location) => (
                    <AdminNewEntityMessage location={location} />
                )}
            />
            <AppRoute
                path="/admin/fleet-management/entity-messages/:id"
                requireAdmin
                render={(location) => (
                    <AdminEntityMessage
                        location={location}
                        messageId={location.match.params.id}
                    />
                )}
            />
            <AppRoute
                path="/admin/fleet-management/cars/:id"
                requireAdmin
                render={(location) =>
                    props.organizations.length === 0 ? (
                        <P>Henter organisasjoner.</P>
                    ) : (
                        <AdminCar
                            location={location}
                            carId={location.match.params.id}
                        />
                    )
                }
            />
            <AppRoute
                exact
                path="/admin/fleet-management/models"
                requireAdmin
                render={(location) => <AdminModels location={location} />}
            />
            <AppRoute
                exact
                path="/admin/fleet-management/car-images"
                requireAdmin
                render={(location) => <AdminCarImage location={location} />}
            />
            <AppRoute
                exact
                path="/admin/fleet-management/car-images/new"
                requireAdmin
                render={(location) => <AdminNewCarImage location={location} />}
            />
            <AppRoute
                exact
                path="/admin/fleet-management/locations"
                requireAdmin
                render={(location) => <AdminLocations location={location} />}
            />
            <AppRoute
                exact
                path="/admin/fleet-management/locations/new"
                requireAdmin
                render={(location) => <AdminNewLocation location={location} />}
            />
            <AppRoute
                exact
                path="/admin/fleet-management/locations/:id"
                requireAdmin
                render={(location) => (
                    <AdminLocation
                        location={location}
                        locationId={location.match.params.id}
                    />
                )}
            />
            <AppRoute
                exact
                path="/admin/fleet-management/models/new"
                requireAdmin
                render={(location) => <AdminNewModel location={location} />}
            />
            <AppRoute
                path="/admin/fleet-management/models/:id"
                requireAdmin
                render={(location) => (
                    <AdminModel
                        location={location}
                        modelId={location.match.params.id}
                    />
                )}
            />
            <AppRoute
                exact
                path="/admin/fleet-management"
                render={() => <Redirect to="/admin/fleet-management/cars" />}
            />
            <AppRoute
                path="/admin/billing-management/import"
                requireAdmin
                render={(location) => <AdminImport location={location} />}
            />
            <AppRoute exact path="/debug-mode" render={() => <DebugPage />} />
            <AppRoute
                path=""
                render={(location) => (
                    <ErrorPage location={location} type={'UNKNOWN_URL'} />
                )}
            />
        </Switch>
    </Suspense>
);

export default Routes;
