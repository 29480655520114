import React, { Suspense } from 'react';
import { Theme } from '../../../utilities/types';
import { ApiCallResult } from '../../types/application';
import { connect } from 'react-redux';
import {
    selectSendPasswordResetEmailPending,
    selectSendPasswordResetEmailResult,
    selectShouldAuthenticate,
    selectShowIncorrectLoginFeedback,
    selectTheme,
} from '../../duck/selectors';
import {
    clearPerformAfterLogin,
    login,
    resetSendPasswordResetEmailResult,
    sendPasswordRetrievalEmail,
    setShouldAuthenticate,
    setShowIncorrectLoginFeedback,
    updateWhoAmIAndLogoutIfAuthExpired,
} from '../../duck/operations';
import Modal from '../Modal';
import Login from '../Login/Login';
import { AppDispatch, State } from '../../../state/store';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../state/hooks';
import { selectShouldRedirectToReservations } from '../../pages/ReservationsPage/duck/selectors';
import { setShouldRedirectToReservations } from '../../pages/ReservationsPage/duck/reservationReducers';

type Props = {
    theme: Theme;

    shouldRedirect?: boolean;
    shouldAuthenticate?: boolean;
    showIncorrectLoginFeedback?: boolean;
    sendPasswordResetEmailPending?: boolean;
    sendPasswordResetEmailResult: ApiCallResult;

    resetSendPasswordResetEmailResult: () => void;
    login: (username: string, password: string) => void;
    sendPasswordRetrievalEmail: (usernameEmailOrPhone: string) => void;
    setShowIncorrectLoginFeedback: (show: boolean) => void;
    setShouldAuthenticate: (should: boolean) => void;
    logoutIfAuthExpired: (fetchUserInfoIfLoggedIn?: boolean) => void;
    clearPerformAfterLogin: () => void;
};

const LoginModalComponent = (props: Props) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    if (props.shouldRedirect) {
        dispatch(setShouldRedirectToReservations(false));
        history.push('/reservations');
        return <></>;
    }
    if (!props.shouldAuthenticate) return <></>;

    const closeLoginModal = () => {
        props.setShowIncorrectLoginFeedback(false);
        props.logoutIfAuthExpired();
        props.clearPerformAfterLogin();
        props.setShouldAuthenticate(false);
    };

    return (
        <Suspense fallback={<div />}>
            <Modal closeModal={closeLoginModal}>
                <Login
                    theme={props.theme}
                    login={props.login}
                    sendPasswordResetEmailPending={
                        props.sendPasswordResetEmailPending
                    }
                    sendPasswordResetEmailResult={
                        props.sendPasswordResetEmailResult
                    }
                    resetSendPasswordResetEmailResult={
                        props.resetSendPasswordResetEmailResult
                    }
                    sendPasswordRetrievalEmail={
                        props.sendPasswordRetrievalEmail
                    }
                    setShowIncorrectLoginFeedback={
                        props.setShowIncorrectLoginFeedback
                    }
                    showIncorrectLoginFeedback={
                        props.showIncorrectLoginFeedback
                    }
                    closeModal={closeLoginModal}
                />
            </Modal>
        </Suspense>
    );
};

export const LoginModal = connect(
    (state: State) => ({
        theme: selectTheme(state),
        shouldRedirect: selectShouldRedirectToReservations(state),
        sendPasswordResetEmailPending: selectSendPasswordResetEmailPending(
            state
        ),
        sendPasswordResetEmailResult: selectSendPasswordResetEmailResult(state),
        showIncorrectLoginFeedback: selectShowIncorrectLoginFeedback(state),
        shouldAuthenticate: selectShouldAuthenticate(state),
    }),
    (dispatch: AppDispatch) => ({
        login: (userName: string, password: string) =>
            dispatch(login(userName, password)),
        sendPasswordRetrievalEmail: (usernameEmailOrPhone: string) =>
            dispatch(sendPasswordRetrievalEmail(usernameEmailOrPhone)),
        setShowIncorrectLoginFeedback: (show: boolean) =>
            dispatch(setShowIncorrectLoginFeedback(show)),
        resetSendPasswordResetEmailResult: () =>
            dispatch(resetSendPasswordResetEmailResult()),
        logoutIfAuthExpired: (fetchUserInfoIfLoggedIn?: boolean) =>
            dispatch(
                updateWhoAmIAndLogoutIfAuthExpired(fetchUserInfoIfLoggedIn)
            ),
        clearPerformAfterLogin: () => dispatch(clearPerformAfterLogin()),
        setShouldAuthenticate: (should: boolean) =>
            dispatch(setShouldAuthenticate(should)),
    })
)(LoginModalComponent);
