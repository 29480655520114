import * as L from 'leaflet';
import React from 'react';
import Map from '../../../../common/map/Map';
import { DeleLocation, MapPosition } from '../../../../types/application';
import { Car } from '../../../../types/search';
import { LatLng } from 'leaflet';
import { connect } from 'react-redux';
import { AppDispatch, State } from '../../../../../state/store';
import { selectCar } from '../../duck/operations';
import {
    selectCars,
    selectMapPosition,
    selectSelectedCarId,
    selectSelectedPosition,
    selectShowCityBikes,
} from '../../duck/selectors';
import { useRouterState } from '../../../../../utilities/routerStateUtils';
import { SearchPageState } from '../../SearchPageConstants';

export const SYSTEM_DEFAULT_MAP_POSITION: LatLng = new LatLng(
    60.391864334,
    5.32294035
);

type MapChooserProps = {
    showCityBikes: boolean;
    mapPosition?: MapPosition;
    selectedPosition?: DeleLocation;
    selectedCarId?: string;
    cars: Car[];
    selectCar: (car: Car) => void;
};

const MapChooserComponent = (props: MapChooserProps) => {
    const { pushRouterState } = useRouterState<SearchPageState>();
    const mapPosition: MapPosition = props.mapPosition
        ? props.mapPosition
        : { latlng: SYSTEM_DEFAULT_MAP_POSITION, zoom: 15 };
    const getCarMarker = (car: Car) => {
        const latLng = L.geoJSON(car.location.geojson).getBounds().getCenter();
        let isChosen = car.id === props.selectedCarId;
        const marker = L.marker(latLng, {
            icon: getCarIcon(car.iconUrl, car.maxAvailability, isChosen),
            title: car.licensePlate,
            alt: car.iconUrl,
        });
        marker.on('click', () => {
            props.selectCar(car);
            pushRouterState({
                showBookingPage: true,
                showMap: true,
            });
        });
        return marker;
    };

    const getCarIcon = (
        url: string | undefined,
        maxAvailability: number,
        isChosen: boolean
    ) => {
        const availabilityClass =
            maxAvailability === 0
                ? 'unavailable'
                : maxAvailability < 100
                ? 'partiallyAvailable'
                : 'available';

        return L.divIcon({
            html: `
            <div class="carMarker">
                <svg class="${availabilityClass}${
                isChosen ? ' selected' : ''
            }" width="29.42" height="33.43" viewBox="0 0 29.42 33.43" xmlns="http://www.w3.org/2000/svg">
                    <path class="first" d="m14.65 8.8844h-12.112c-0.61146 0.016555-0.71041 0.53404-0.52728 1.0495l12.639 21.334z" fill="#8bbe00"/>
                    <path class="second" d="m14.65 8.8844h12.112c0.61146 0.01656 0.7104 0.53404 0.52728 1.0495l-12.639 21.334z" fill="#8bbe00"/>
                    <path class="strokePath" d="m2.0104 9.934c-0.18312-0.51548-0.08418-1.033 0.52728-1.0495h24.224c0.61146 0.01656 0.7104 0.53404 0.52728 1.0495l-12.639 21.334z" fill="none" stroke="#000"/>
                </svg>
                <img
                    src=${url} 
                    alt="car"
                    class="carMarker__img"
                >
            </div>`,
            iconSize: new L.Point(50, 50),
            iconAnchor: [25, 45],
        });
    };

    const getCarAvailabilityById = (licensePlate: string) => {
        const car = props.cars.find((car) => car.licensePlate === licensePlate);
        return car ? car.maxAvailability : 0;
    };

    return (
        <div className="mapChooserContent">
            <Map
                mapPosition={mapPosition}
                selectedPosition={props.selectedPosition}
                carMarkers={props.cars.map(getCarMarker)}
                getCarIcon={getCarIcon}
                getCarAvailabilityById={getCarAvailabilityById}
                showCityBikes={props.showCityBikes}
            />
        </div>
    );
};

export const MapChooser = connect(
    (state: State) => ({
        cars: selectCars(state),
        selectedCarId: selectSelectedCarId(state),
        selectedPosition: selectSelectedPosition(state),
        mapPosition: selectMapPosition(state),
        showCityBikes: selectShowCityBikes(state),
    }),
    (dispatch: AppDispatch) => ({
        selectCar: (car: Car) => dispatch(selectCar(car, true)),
    })
)(MapChooserComponent);

export default MapChooser;
