import { handleActions } from 'redux-actions';
import actions from './actions';
import { apiCallError, apiCallSuccess } from '../../../../utilities/constants';
import { combineReducers } from 'redux';
import personReducer from '../subPages/AdminPersons/duck/reducers';
import carReducer from '../subPages/AdminCars/duck/reducers';
import modelReducer from '../subPages/AdminModels/duck/adminModelReducers';
import locationsReducer from '../subPages/AdminLocations/duck/reducers';
import reservationExpensesReducer from '../subPages/AdminBilling/AdminReservationExpenses/duck/adminExpensesReducers';
import damageReducer from '../subPages/AdminDamages/duck/reducers';
import entityMessageReducer from '../subPages/AdminEntityMessages/duck/reducers';
import {
    specificSolutionsReducer,
    situationReducer,
} from '../subPages/AdminHelp/duck/reducers';
import invoiceLineReducer from '../subPages/AdminBilling/duck/reducers';
import membershipPreviewReducer from '../subPages/AdminMemberships/AdminMembership/AdminMembershipPreview/duck/reducer';

const defaultReservationState = {
    fetchingAdminReservation: false,
    fetchAdminReservationResult: undefined,
    fetchingAdminReservationExtensibility: false,
    fetchAdminReservationExtensibilityResult: undefined,
    reservationExtendability: undefined,
    reservation: undefined,
    reservations: {},
    trips: [],
    fetchingAdminReservationTrips: false,
    fetchAdminReservationTripsResult: undefined,
    fetchingAdminReservationConflicts: false,
    fetchAdminReservationConflictsResult: undefined,
    reservationConflicts: undefined,
    fetchingAdminReservationDrivers: false,
    fetchReservationDriverResult: undefined,
    reservationDrivers: undefined,
    puttingReservation: false,
    putReservationResult: undefined,
    cancellingReservation: false,
    cancelReservationResult: undefined,
    voidingReservation: false,
    voidReservationResult: undefined,
    addingReservationDriver: false,
    addReservationDriverResult: undefined,
    removingReservationDriver: false,
    removeReservationDriverResult: undefined,
    blockingReservation: undefined,
    creatingReservation: false,
    createReservationResult: undefined,
    newReservationId: undefined,

    fetchingAdminAdminReservationsResult: undefined,
    fetchingAdminAdminReservations: false,
    adminReservationIndexes: undefined,
};

const reservationReducer = handleActions(
    {
        [actions.fetchConflictsPending]: (state) => ({
            ...state,
            fetchConflictsResult: undefined,
            fetchingConflicts: true,
        }),
        [actions.fetchConflictsCompleted]: (state, action) => ({
            ...state,
            fetchConflictsResult: apiCallSuccess,
            fetchingConflicts: false,
            conflicts: action.payload,
        }),
        [actions.fetchConflictsError]: (state) => ({
            ...state,
            fetchConflictsResult: apiCallError,
            fetchingConflicts: false,
        }),

        [actions.fetchingAdminReservationsPending]: (state) => ({
            ...state,
            fetchingAdminReservationsResult: undefined,
            fetchingAdminReservations: true,
        }),
        [actions.fetchingAdminReservationsCompleted]: (state, action) => ({
            ...state,
            fetchingAdminReservationsResult: apiCallSuccess,
            fetchingAdminReservations: false,
            adminReservationIndexes: action.payload,
        }),
        [actions.fetchingAdminReservationsError]: (state) => ({
            ...state,
            fetchingAdminReservationsResult: apiCallError,
            fetchingAdminReservations: false,
        }),

        [actions.fetchingAdminReservationsMetadataPending]: (state) => ({
            ...state,
            fetchingAdminReservationsMetadataResult: undefined,
            fetchingAdminReservationsMetadata: true,
        }),
        [actions.fetchingAdminReservationsMetadataCompleted]: (
            state,
            action
        ) => ({
            ...state,
            fetchingAdminAdminReservationsMetadataResult: apiCallSuccess,
            fetchingAdminAdminReservationsMetadata: false,
            reservationMetadata: action.payload,
        }),
        [actions.fetchingAdminReservationsMetadataError]: (state) => ({
            ...state,
            fetchingAdminReservationsMetadataResult: apiCallError,
            fetchingAdminReservationsMetadata: false,
        }),

        [actions.fetchingAdminReservationMessagesPending]: (state) => ({
            ...state,
            fetchingAdminReservationMessagesResult: undefined,
            fetchingAdminReservationMessages: true,
        }),
        [actions.fetchingAdminReservationMessagesCompleted]: (
            state,
            action
        ) => ({
            ...state,
            fetchingAdminReservationMessagesResult: apiCallSuccess,
            fetchingAdminReservationMessages: false,
            adminReservationMessageIndexes: action.payload,
        }),
        [actions.fetchingAdminReservationMessagesError]: (state) => ({
            ...state,
            fetchingAdminReservationMessagesResult: apiCallError,
            fetchingAdminReservationMessages: false,
        }),

        [actions.fetchingAdminReservationMessagesMetadataPending]: (state) => ({
            ...state,
            fetchingAdminReservationMessagesMetadataResult: undefined,
            fetchingAdminReservationMessagesMetadata: true,
        }),
        [actions.fetchingAdminReservationMessagesMetadataCompleted]: (
            state,
            action
        ) => ({
            ...state,
            fetchingAdminReservationMessagesMetadataResult: apiCallSuccess,
            fetchingAdminReservationMessagesMetadata: false,
            reservationMessageMetadata: action.payload,
        }),
        [actions.fetchingAdminReservationMessagesMetadataError]: (state) => ({
            ...state,
            fetchingAdminReservationMessagesMetadataResult: apiCallError,
            fetchingAdminReservationMessagesMetadata: false,
        }),

        [actions.fetchingAdminReservationMessagePending]: (state) => ({
            ...state,
            fetchingAdminReservationMessageResult: undefined,
            fetchingAdminReservationMessage: true,
        }),
        [actions.fetchingAdminReservationMessageCompleted]: (
            state,
            action
        ) => ({
            ...state,
            fetchingAdminReservationMessageResult: apiCallSuccess,
            fetchingAdminReservationMessage: false,
            adminReservationMessage: action.payload,
        }),
        [actions.fetchingAdminReservationMessageError]: (state) => ({
            ...state,
            fetchingAdminReservationMessageResult: apiCallError,
            fetchingAdminReservationMessage: false,
        }),

        [actions.fetchingAdminAdminReservationsPending]: (state) => ({
            ...state,
            fetchingAdminAdminReservationsResult: undefined,
            fetchingAdminAdminReservations: true,
        }),
        [actions.fetchingAdminAdminReservationsCompleted]: (state, action) => ({
            ...state,
            fetchingAdminAdminReservationsResult: apiCallSuccess,
            fetchingAdminAdminReservations: false,
            adminAdminReservationIndexes: action.payload,
        }),
        [actions.fetchingAdminAdminReservationsError]: (state) => ({
            ...state,
            fetchingAdminAdminReservationsResult: apiCallError,
            fetchingAdminAdminReservations: false,
        }),

        [actions.fetchingAdminAdminReservationsMetadataPending]: (state) => ({
            ...state,
            fetchingAdminAdminReservationsMetadataResult: undefined,
            fetchingAdminAdminReservationsMetadata: true,
        }),
        [actions.fetchingAdminAdminReservationsMetadataCompleted]: (
            state,
            action
        ) => ({
            ...state,
            fetchingAdminAdminReservationsMetadataResult: apiCallSuccess,
            fetchingAdminAdminReservationsMetadata: false,
            adminReservationMetadata: action.payload,
        }),
        [actions.fetchingAdminAdminReservationsMetadataError]: (state) => ({
            ...state,
            fetchingAdminAdminReservationsMetadataResult: apiCallError,
            fetchingAdminAdminReservationsMetadata: false,
        }),

        [actions.fetchingAdminReservationsPending]: (state) => ({
            ...state,
            fetchingAdminReservationsResult: undefined,
            fetchingAdminReservations: true,
        }),
        [actions.fetchingAdminReservationsCompleted]: (state, action) => ({
            ...state,
            fetchingAdminReservationsResult: apiCallSuccess,
            fetchingAdminReservations: false,
            reservationIndexes: action.payload,
        }),
        [actions.fetchingAdminReservationsError]: (state) => ({
            ...state,
            fetchingAdminReservationsResult: apiCallError,
            fetchingAdminReservations: false,
        }),

        [actions.fetchingAdminReservationsMetadataPending]: (state) => ({
            ...state,
            fetchingAdminReservationsMetadataResult: undefined,
            fetchingAdminReservationsMetadata: true,
        }),
        [actions.fetchingAdminReservationsMetadataCompleted]: (
            state,
            action
        ) => ({
            ...state,
            fetchingAdminReservationsMetadataResult: apiCallSuccess,
            fetchingAdminReservationsMetadata: false,
            reservationMetadata: action.payload,
        }),
        [actions.fetchingAdminReservationsMetadataError]: (state) => ({
            ...state,
            fetchingAdminReservationsMetadataResult: apiCallError,
            fetchingAdminReservationsMetadata: false,
        }),
        [actions.fetchingAdminReservationPending]: (state) => ({
            ...state,
            fetchingAdminReservation: true,
            fetchAdminReservationResult: undefined,
        }),
        [actions.fetchingAdminReservationCompleted]: (state, action) => ({
            ...state,
            fetchingAdminReservation: false,
            fetchAdminReservationResult: apiCallSuccess,
            reservation: action.payload,
            reservations: {
                ...state.reservations,
                [action.payload.id]: action.payload,
            },
        }),
        [actions.fetchingAdminReservationError]: (state) => ({
            ...state,
            fetchingAdminReservation: false,
            fetchAdminReservationResult: apiCallError,
        }),

        [actions.adminReservationResendReservationPending]: (state) => ({
            ...state,
            adminReservationResendingReservation: true,
            adminReservationResendReservationResult: undefined,
        }),
        [actions.adminReservationResendReservationCompleted]: (state) => ({
            ...state,
            adminReservationResendingReservation: false,
            adminReservationResendReservationResult: apiCallSuccess,
        }),
        [actions.adminReservationResendReservationError]: (state) => ({
            ...state,
            adminReservationResendingReservation: false,
            adminReservationResendReservationResult: apiCallError,
        }),
        [actions.resetAdminReservationResendReservation]: (state) => ({
            ...state,
            adminReservationResendingReservation: false,
            adminReservationResendReservationResult: undefined,
        }),

        [actions.invoicingAdminReservationPending]: (state) => ({
            ...state,
            invoicingAdminReservation: true,
            invoiceAdminReservationResult: undefined,
        }),
        [actions.invoicingAdminReservationCompleted]: (state) => ({
            ...state,
            invoicingAdminReservation: false,
            invoiceAdminReservationResult: apiCallSuccess,
        }),
        [actions.invoicingAdminReservationError]: (state) => ({
            ...state,
            invoicingAdminReservation: false,
            invoiceAdminReservationResult: apiCallError,
        }),

        [actions.fetchReservationChangesPending]: (state) => ({
            ...state,
            fetchingReservationChanges: true,
            fetchReservationChangesResult: undefined,
        }),
        [actions.fetchReservationChangesCompleted]: (state, action) => ({
            ...state,
            fetchingReservationChanges: false,
            fetchReservationChangesResult: apiCallSuccess,
            reservationChanges: action.payload,
        }),
        [actions.fetchReservationChangesError]: (state) => ({
            ...state,
            fetchingReservationChanges: false,
            fetchReservationChangesResult: apiCallError,
        }),

        [actions.fetchReservationChangeLogBodyPending]: (state) => ({
            ...state,
            fetchReservationChangeLogBodyResult: undefined,
        }),
        [actions.fetchReservationChangeLogBodyCompleted]: (state, action) => ({
            ...state,
            fetchReservationChangeLogBodyResult: apiCallSuccess,
            reservationChanges: action.payload,
        }),
        [actions.fetchReservationChangeLogBodyError]: (state, action) => ({
            ...state,
            fetchReservationChangeLogBodyResult: apiCallError,
            reservationChanges: action.payload,
        }),

        [actions.fetchingAdminReservationTripsPending]: (state) => ({
            ...state,
            fetchingAdminReservationTrips: true,
            fetchAdminReservationTripsResult: undefined,
        }),
        [actions.fetchingAdminReservationTripsCompleted]: (state, action) => ({
            ...state,
            fetchingAdminReservationTrips: false,
            fetchAdminReservationTripsResult: apiCallSuccess,
            trips: action.payload,
        }),
        [actions.fetchingAdminReservationTripsError]: (state) => ({
            ...state,
            fetchingAdminReservationTrips: false,
            fetchAdminReservationTripsResult: apiCallError,
        }),

        [actions.fetchingAdminReservationExtensibilityPending]: (state) => ({
            ...state,
            fetchingAdminReservationExtensibility: false,
            fetchAdminReservationExtensibilityResult: undefined,
        }),
        [actions.fetchingAdminReservationExtensibilityCompleted]: (
            state,
            action
        ) => ({
            ...state,
            fetchingAdminReservationExtensibility: false,
            fetchAdminReservationExtensibilityResult: apiCallSuccess,
            reservationExtendability: action.payload,
        }),
        [actions.fetchingAdminReservationExtensibilityError]: (state) => ({
            ...state,
            fetchingAdminReservationExtensibility: false,
            fetchAdminReservationExtensibilityResult: apiCallError,
        }),

        [actions.fetchingAdminReservationConflictsPending]: (state) => ({
            ...state,
            fetchingAdminReservationConflicts: true,
            fetchAdminReservationConflictsResult: undefined,
        }),
        [actions.fetchingAdminReservationConflictsCompleted]: (
            state,
            action
        ) => ({
            ...state,
            fetchingAdminReservationConflicts: false,
            fetchAdminReservationConflictsResult: apiCallSuccess,
            reservationConflicts: action.payload,
        }),
        [actions.fetchingAdminReservationConflictsError]: (state) => ({
            ...state,
            fetchingAdminReservationConflicts: false,
            fetchAdminReservationConflictsResult: apiCallError,
        }),

        [actions.fetchingAdminProblemReservationsPending]: (state) => ({
            ...state,
            fetchingAdminProblemReservations: true,
            fetchAdminProblemReservationResult: undefined,
        }),
        [actions.fetchingAdminProblemReservationsCompleted]: (
            state,
            action
        ) => ({
            ...state,
            fetchingAdminProblemReservations: false,
            fetchAdminProblemReservationResult: apiCallSuccess,
            problemReservations: action.payload,
        }),
        [actions.fetchingAdminProblemReservationsError]: (state) => ({
            ...state,
            fetchingAdminProblemReservations: false,
            fetchAdminProblemReservationResult: apiCallError,
        }),

        [actions.fetchingAdminReservationDriversPending]: (state) => ({
            ...state,
            fetchingAdminReservationDrivers: true,
            fetchReservationDriverResult: undefined,
        }),
        [actions.fetchingAdminReservationDriversCompleted]: (
            state,
            action
        ) => ({
            ...state,
            fetchingAdminReservationDrivers: false,
            fetchReservationDriverResult: apiCallSuccess,
            reservationDrivers: action.payload,
        }),
        [actions.fetchingAdminReservationDriversError]: (state) => ({
            ...state,
            fetchingAdminReservationDrivers: false,
            fetchReservationDriverResult: apiCallError,
        }),
        [actions.putReservationPending]: (state) => ({
            ...state,
            puttingReservation: true,
            putReservationResult: undefined,
        }),
        [actions.putReservationCompleted]: (state) => ({
            ...state,
            puttingReservation: false,
            putReservationResult: apiCallSuccess,
        }),
        [actions.putReservationError]: (state) => ({
            ...state,
            puttingReservation: false,
            putReservationResult: apiCallError,
        }),
        [actions.resetPutReservationResult]: (state) => ({
            ...state,
            puttingReservation: false,
            putReservationResult: undefined,
        }),
        [actions.cancelReservationPending]: (state) => ({
            ...state,
            cancellingReservation: true,
            cancelReservationResult: undefined,
        }),
        [actions.cancelReservationCompleted]: (state) => ({
            ...state,
            cancellingReservation: false,
            cancelReservationResult: apiCallSuccess,
        }),
        [actions.cancelReservationError]: (state) => ({
            ...state,
            cancellingReservation: false,
            cancelReservationResult: apiCallError,
        }),
        [actions.voidReservationPending]: (state) => ({
            ...state,
            voidingReservation: true,
            voidReservationResult: undefined,
        }),
        [actions.voidReservationCompleted]: (state) => ({
            ...state,
            voidingReservation: false,
            voidReservationResult: apiCallSuccess,
        }),
        [actions.voidReservationError]: (state) => ({
            ...state,
            voidingReservation: false,
            voidReservationResult: apiCallError,
        }),
        [actions.createAdminReservationPending]: (state) => ({
            ...state,
            creatingReservation: true,
            createReservationResult: undefined,
        }),
        [actions.createAdminReservationCompleted]: (state, action) => ({
            ...state,
            creatingReservation: false,
            createReservationResult: apiCallSuccess,
            newReservationId: action.payload.id,
        }),
        [actions.createAdminReservationError]: (state) => ({
            ...state,
            creatingReservation: false,
            createReservationResult: apiCallError,
        }),
        [actions.resetReservationReviewFlagPending]: (state) => ({
            ...state,
            resetingReviewFlag: true,
            resetReviewFlagResult: undefined,
        }),
        [actions.resetReservationReviewFlagCompleted]: (state) => ({
            ...state,
            resetingReviewFlag: false,
            resetReviewFlagResult: apiCallSuccess,
        }),
        [actions.resetReservationReviewFlagError]: (state) => ({
            ...state,
            resetingReviewFlag: false,
            resetReviewFlagResult: apiCallError,
        }),
        [actions.addReservationDriverPending]: (state) => ({
            ...state,
            addingReservationDriver: true,
            addReservationDriverResult: undefined,
        }),
        [actions.addReservationDriverCompleted]: (state) => ({
            ...state,
            addingReservationDriver: false,
            addReservationDriverResult: apiCallSuccess,
        }),
        [actions.addReservationDriverError]: (state) => ({
            ...state,
            addingReservationDriver: false,
            addReservationDriverResult: apiCallError,
        }),
        [actions.removeReservationDriverPending]: (state) => ({
            ...state,
            removingReservationDriver: true,
            removeReservationDriverResult: undefined,
        }),
        [actions.removeReservationDriverCompleted]: (state) => ({
            ...state,
            removingReservationDriver: false,
            removeReservationDriverResult: apiCallSuccess,
        }),
        [actions.removeReservationDriverError]: (state) => ({
            ...state,
            removingReservationDriver: false,
            removeReservationDriverResult: apiCallError,
        }),

        [actions.setBlockingReservation]: (state, action) => ({
            ...state,
            blockingReservation: action.payload,
        }),
        CLEAR_STATE: () => defaultReservationState,
    },
    defaultReservationState
);

const defaultMembershipState = {
    membershipSubMembers: [],
    fetchingMembershipSubMembers: false,
    fetchingMembershipSubMembersResult: undefined,
    fetchingAdminMembershipsResult: undefined,
    fetchingAdminMemberships: false,
    memberships: undefined,
    fetchingAdminMembershipsMetadataResult: undefined,
    fetchingAdminMembershipsMetadata: false,
    membershipsMetadata: undefined,
    fetchAdminMembershipResult: undefined,
    fetchingAdminMembership: false,
    membership: undefined,
    addingMembershipMember: false,
    addMembershipMemberResult: undefined,
    removingMembershipMember: false,
    removeMembershipMemberResult: undefined,
    puttingMembership: false,
    putMembershipResult: undefined,
    fetchingAdminMembershipLastReservations: false,
    fetchAdminMembershipLastReservationsResult: undefined,
    adminMembershipLastReservations: undefined,
    fetchingAdminMembershipStatesResult: undefined,
    fetchingAdminMembershipStates: false,
    membershipStates: undefined,
    fetchingAdminMembershipTypesResult: undefined,
    fetchingAdminMembershipTypes: false,
    membershipTypes: undefined,
};

const membershipReducer = handleActions(
    {
        [actions.fetchingAdminMembershipsPending]: (state) => ({
            ...state,
            fetchingAdminMembershipsResult: undefined,
            fetchingAdminMemberships: true,
        }),
        [actions.fetchingAdminMembershipsCompleted]: (state, action) => ({
            ...state,
            fetchingAdminMembershipsResult: apiCallSuccess,
            fetchingAdminMemberships: false,
            memberships: action.payload,
        }),
        [actions.fetchingAdminMembershipsError]: (state) => ({
            ...state,
            fetchingAdminMembershipsResult: apiCallError,
            fetchingAdminMemberships: false,
        }),
        [actions.fetchMembershipSubMembersPending]: (state) => ({
            ...state,
            fetchingMembershipSubMembers: true,
            fetchingMembershipSubMembersResult: undefined,
        }),
        [actions.fetchMembershipSubMembersCompleted]: (state, action) => ({
            ...state,
            fetchingMembershipSubMembers: false,
            fetchingMembershipSubMembersResult: apiCallSuccess,
            membershipSubMembers: action.payload,
        }),
        [actions.fetchMembershipSubMembersError]: (state) => ({
            ...state,
            fetchingMembershipSubMembers: false,
            fetchingMembershipSubMembersResult: apiCallError,
        }),

        [actions.setMembershipSubMembers]: (state, action) => ({
            ...state,
            membershipSubMembers: action.payload,
        }),
        [actions.fetchingAdminMembershipsMetadataPending]: (state) => ({
            ...state,
            fetchingAdminMembershipsMetadataResult: undefined,
            fetchingAdminMembershipsMetadata: true,
        }),
        [actions.fetchingAdminMembershipsMetadataCompleted]: (
            state,
            action
        ) => ({
            ...state,
            fetchingAdminMembershipsMetadataResult: apiCallSuccess,
            fetchingAdminMembershipsMetadata: false,
            membershipsMetadata: action.payload,
        }),
        [actions.fetchingAdminMembershipsMetadataError]: (state) => ({
            ...state,
            fetchingAdminMembershipsMetadataResult: apiCallError,
            fetchingAdminMembershipsMetadata: false,
        }),
        [actions.fetchAdminMembershipPending]: (state) => ({
            ...state,
            fetchAdminMembershipResult: undefined,
            fetchingAdminMembership: true,
        }),
        [actions.fetchAdminMembershipCompleted]: (state, action) => ({
            ...state,
            fetchAdminMembershipResult: apiCallSuccess,
            fetchingAdminMembership: false,
            membership: action.payload,
        }),
        [actions.fetchAdminMembershipError]: (state) => ({
            ...state,
            fetchAdminMembershipResult: apiCallError,
            fetchingAdminMembership: false,
        }),
        [actions.fetchAdminReservationSummaryPending]: (state) => ({
            ...state,
            fetchingAdminReservationSummary: true,
            fetchAdminReservationSummaryResult: undefined,
        }),
        [actions.fetchAdminReservationSummaryCompleted]: (state, action) => ({
            ...state,
            fetchingAdminReservationSummary: false,
            fetchAdminReservationSummaryResult: apiCallSuccess,
            adminReservationSummary: action.payload,
        }),
        [actions.fetchAdminReservationSummaryError]: (state) => ({
            ...state,
            fetchingAdminReservationSummary: false,
            fetchAdminReservationSummaryResult: apiCallError,
        }),
        [actions.addMembershipMemberPending]: (state) => ({
            ...state,
            addingMembershipMember: true,
            addMembershipMemberResult: undefined,
        }),
        [actions.addMembershipMemberCompleted]: (state) => ({
            ...state,
            addingMembershipMember: false,
            addMembershipMemberResult: apiCallSuccess,
        }),
        [actions.addMembershipMemberError]: (state) => ({
            ...state,
            addingMembershipMember: false,
            addMembershipMemberResult: apiCallError,
        }),
        [actions.removeMembershipMemberPending]: (state) => ({
            ...state,
            removingMembershipMember: true,
            removeMembershipMemberResult: undefined,
        }),
        [actions.removeMembershipMemberCompleted]: (state) => ({
            ...state,
            removingMembershipMember: false,
            removeMembershipMemberResult: apiCallSuccess,
        }),
        [actions.removeMembershipMemberError]: (state) => ({
            ...state,
            removingMembershipMember: false,
            removeMembershipMemberResult: apiCallError,
        }),
        [actions.putMembershipPending]: (state) => ({
            ...state,
            puttingMembership: true,
            putMembershipResult: undefined,
        }),
        [actions.putMembershipCompleted]: (state) => ({
            ...state,
            puttingMembership: false,
            putMembershipResult: apiCallSuccess,
        }),
        [actions.putMembershipError]: (state) => ({
            ...state,
            puttingMembership: false,
            putMembershipResult: apiCallError,
        }),
        [actions.resetPutMembershipResult]: (state) => ({
            ...state,
            puttingMembership: false,
            putMembershipResult: undefined,
        }),

        [actions.deleteMembershipPending]: (state) => ({
            ...state,
            deletingMembership: true,
            deleteMembershipResult: undefined,
        }),
        [actions.deleteMembershipCompleted]: (state) => ({
            ...state,
            deletingMembership: false,
            deleteMembershipResult: apiCallSuccess,
        }),
        [actions.deleteMembershipError]: (state) => ({
            ...state,
            deletingMembership: false,
            deleteMembershipResult: apiCallError,
        }),
        [actions.resetDeleteMembershipResult]: (state) => ({
            ...state,
            deletingMembership: false,
            putMembershipResult: undefined,
        }),

        [actions.refundMembershipDepositPending]: (state) => ({
            ...state,
            refundMembershipDepositResult: undefined,
            refundingMembershipDeposit: true,
        }),
        [actions.refundMembershipDepositCompleted]: (state) => ({
            ...state,
            refundMembershipDepositResult: apiCallSuccess,
            refundingMembershipDeposit: false,
        }),
        [actions.refundMembershipDepositError]: (state) => ({
            ...state,
            refundMembershipDepositResult: apiCallError,
            refundingMembershipDeposit: false,
        }),

        [actions.fetchingAdminMembershipStatesPending]: (state) => ({
            ...state,
            fetchingAdminMembershipStatesResult: undefined,
            fetchingAdminMembershipStates: true,
        }),
        [actions.fetchingAdminMembershipStatesCompleted]: (state, action) => ({
            ...state,
            fetchingAdminMembershipStatesResult: apiCallSuccess,
            fetchingAdminMembershipStates: false,
            membershipStates: action.payload,
        }),
        [actions.fetchingAdminMembershipStatesError]: (state) => ({
            ...state,
            fetchingAdminMembershipStatesResult: apiCallError,
            fetchingAdminMembershipStates: false,
        }),
        [actions.fetchingAdminMembershipTypesPending]: (state) => ({
            ...state,
            fetchingAdminMembershipTypesResult: undefined,
            fetchingAdminMembershipTypes: true,
        }),
        [actions.fetchingAdminMembershipTypesCompleted]: (state, action) => ({
            ...state,
            fetchingAdminMembershipTypesResult: apiCallSuccess,
            fetchingAdminMembershipTypes: false,
            membershipTypes: action.payload,
        }),
        [actions.fetchingAdminMembershipTypesError]: (state) => ({
            ...state,
            fetchingAdminMembershipTypesResult: apiCallError,
            fetchingAdminMembershipTypes: false,
        }),
        CLEAR_STATE: () => defaultMembershipState,
    },
    defaultMembershipState
);

const defaultBillingState = {
    fetchingAdminBillingDataPending: false,
    billingData: undefined,
    fetchingAdminBillingMetadataPending: false,
    billingMetadata: undefined,
};

const billingReducer = handleActions(
    {
        [actions.fetchingAdminBillingDataPending]: (state) => ({
            ...state,
            fetchingAdminBillingDataResult: undefined,
            fetchingAdminBillingData: true,
        }),
        [actions.fetchingAdminBillingDataCompleted]: (state, action) => ({
            ...state,
            fetchingAdminBillingDataResult: apiCallSuccess,
            fetchingAdminBillingData: false,
            billingData: action.payload,
        }),
        [actions.fetchingAdminBillingDataError]: (state) => ({
            ...state,
            fetchingAdminBillingDataResult: apiCallError,
            fetchingAdminBillingData: false,
        }),
        [actions.setBillingData]: (state, action) => ({
            ...state,
            billingData: action.payload,
        }),

        [actions.fetchProductVatCodesPending]: (state) => ({
            ...state,
            fetchProductVatCodesResult: undefined,
            fetchingProductVatCodes: true,
        }),
        [actions.fetchProductVatCodesCompleted]: (state, action) => ({
            ...state,
            fetchProductVatCodesResult: apiCallSuccess,
            fetchingProductVatCodes: false,
            productVatCodes: action.payload,
        }),
        [actions.fetchProductVatCodesError]: (state) => ({
            ...state,
            fetchProductVatCodesResult: apiCallError,
            fetchingProductVatCodes: false,
        }),

        [actions.updateProductPending]: (state) => ({
            ...state,
            updatingProduct: true,
            updateProductResult: undefined,
        }),
        [actions.updateProductCompleted]: (state) => ({
            ...state,
            updatingProduct: false,
            updateProductResult: apiCallSuccess,
        }),
        [actions.updateProductError]: (state) => ({
            ...state,
            updatingProduct: false,
            updateProductResult: apiCallError,
        }),

        [actions.fetchProductPending]: (state) => ({
            ...state,
            gettingProduct: true,
            getProductResult: undefined,
        }),
        [actions.fetchProductCompleted]: (state, action) => ({
            ...state,
            gettingProduct: false,
            getProductResult: apiCallSuccess,
            selectedProduct: action.payload,
        }),
        [actions.fetchProductError]: (state) => ({
            ...state,
            gettingProduct: false,
            getProductResult: apiCallError,
        }),

        [actions.fetchProductCategoriesPending]: (state) => ({
            ...state,
            gettingProductCategories: true,
            getProductCategoriesResult: undefined,
        }),
        [actions.fetchProductCategoriesCompleted]: (state, action) => ({
            ...state,
            gettingProductCategories: false,
            getProductCategoriesResult: apiCallSuccess,
            productCategories: action.payload,
        }),
        [actions.fetchProductCategoriesError]: (state) => ({
            ...state,
            gettingProductCategories: false,
            getProductCategoriesResult: apiCallError,
        }),

        [actions.createProductPending]: (state) => ({
            ...state,
            creatingProduct: true,
            createProductResult: undefined,
        }),
        [actions.createProductCompleted]: (state, action) => ({
            ...state,
            creatingProduct: false,
            createProductResult: apiCallSuccess,
        }),
        [actions.createProductError]: (state) => ({
            ...state,
            creatingProduct: false,
            createProductResult: apiCallError,
        }),

        [actions.fetchProductsPending]: (state) => ({
            ...state,
            fetchProductsResult: undefined,
            fetchingProducts: true,
        }),
        [actions.fetchProductsCompleted]: (state, action) => ({
            ...state,
            fetchProductsResult: apiCallSuccess,
            fetchingProducts: false,
            products: action.payload,
        }),
        [actions.fetchProductsError]: (state) => ({
            ...state,
            fetchProductsResult: apiCallError,
            fetchingProducts: false,
        }),
        [actions.fetchProductsMetadataPending]: (state) => ({
            ...state,
            fetchProductsMetadataResult: undefined,
            fetchingProductsMetadata: true,
        }),
        [actions.fetchProductsMetadataCompleted]: (state, action) => ({
            ...state,
            fetchProductsMetadataResult: apiCallSuccess,
            fetchingProductsMetadata: false,
            productsMetadata: action.payload,
        }),
        [actions.fetchProductsMetadataError]: (state) => ({
            ...state,
            fetchProductsMetadataResult: apiCallError,
            fetchingProductsMetadata: false,
        }),
        [actions.fetchingAdminBillingMetadataPending]: (state) => ({
            ...state,
            fetchingAdminBillingMetadataResult: undefined,
            fetchingAdminBillingMetadata: true,
        }),
        [actions.fetchingAdminBillingMetadataCompleted]: (state, action) => ({
            ...state,
            fetchingAdminBillingMetadataResult: apiCallSuccess,
            fetchingAdminBillingMetadata: false,
            billingMetadata: action.payload,
        }),
        [actions.fetchingAdminBillingMetadataError]: (state) => ({
            ...state,
            fetchingAdminBillingMetadataResult: apiCallError,
            fetchingAdminBillingMetadata: false,
        }),
        [actions.updateBillingData]: (state, action) => ({
            ...state,
            billingData: action.payload,
        }),
        CLEAR_STATE: () => defaultBillingState,
    },
    defaultBillingState
);

const defaultInvoiceState = {
    fetchingAdminInvoicesResult: undefined,
    fetchingAdminInvoiceMetadataResult: undefined,
    fetchingAdminInvoices: true,
    fetchingAdminInvoiceMetadata: false,
    invoices: undefined,
    invoiceMetadata: undefined,
    saveInvoiceLinePending: false,
    includeNonReservationLines: true,
};

const invoiceReducer = handleActions(
    {
        [actions.createInvoicePending]: (state) => ({
            ...state,
            createInvoiceResult: undefined,
            creatingInvoice: true,
        }),
        [actions.createInvoiceCompleted]: (state, action) => ({
            ...state,
            createInvoiceResult: apiCallSuccess,
            creatingInvoice: false,
            createInvoicesResponse: action.payload,
        }),
        [actions.createInvoiceError]: (state) => ({
            ...state,
            createInvoiceResult: apiCallError,
            creatingInvoice: false,
        }),
        [actions.resetCreateInvoicesResponse]: (state) => ({
            ...state,
            createInvoicesResponse: undefined,
        }),

        [actions.sendInvoicePending]: (state) => ({
            ...state,
            sendInvoiceResult: undefined,
            sendingInvoice: true,
        }),
        [actions.sendInvoiceCompleted]: (state, action) => ({
            ...state,
            sendInvoiceResult: apiCallSuccess,
            sendingInvoice: false,
            invoices: action.payload.invoices,
            invoiceDetails: action.payload.invoiceDetails,
        }),
        [actions.sendInvoiceError]: (state) => ({
            ...state,
            sendInvoiceResult: apiCallError,
            sendingInvoice: false,
        }),

        [actions.fetchingAdminInvoiceDetailsPending]: (state) => ({
            ...state,
            fetchingAdminInvoiceDetailsResult: undefined,
            fetchingAdminInvoiceDetails: true,
        }),
        [actions.fetchingAdminInvoiceDetailsCompleted]: (state, action) => ({
            ...state,
            fetchingAdminInvoiceDetailsResult: apiCallSuccess,
            fetchingAdminInvoiceDetails: false,
            invoiceDetails: action.payload,
        }),
        [actions.fetchingAdminInvoiceDetailsError]: (state) => ({
            ...state,
            fetchingAdminInvoiceDetailsResult: apiCallError,
            fetchingAdminInvoiceDetails: false,
        }),

        [actions.fetchingAdminInvoicesPending]: (state) => ({
            ...state,
            fetchingAdminInvoicesResult: undefined,
            fetchingAdminInvoices: true,
        }),
        [actions.fetchingAdminInvoicesCompleted]: (state, action) => ({
            ...state,
            fetchingAdminInvoicesResult: apiCallSuccess,
            fetchingAdminInvoices: false,
            invoices: action.payload,
        }),
        [actions.fetchingAdminInvoicesError]: (state) => ({
            ...state,
            fetchingAdminInvoicesResult: apiCallError,
            fetchingAdminInvoices: false,
        }),

        [actions.fetchingAdminInvoiceMetadataPending]: (state) => ({
            ...state,
            fetchingAdminInvoiceMetadataResult: undefined,
            fetchingAdminInvoiceMetadata: true,
        }),
        [actions.fetchingAdminInvoiceMetadataCompleted]: (state, action) => ({
            ...state,
            fetchingAdminInvoiceMetadataResult: apiCallSuccess,
            fetchingAdminInvoiceMetadata: false,
            invoiceMetadata: action.payload,
        }),
        [actions.fetchingAdminInvoiceMetadataError]: (state) => ({
            ...state,
            fetchingAdminInvoiceMetadataResult: apiCallError,
            fetchingAdminInvoiceMetadata: false,
        }),

        [actions.setInvoiceSummaryBeforeDate]: (state, action) => ({
            ...state,
            invoiceSummaryBeforeDate: action.payload,
        }),
        [actions.setIncludeNonReservationLines]: (state, action) => ({
            ...state,
            includeNonReservationLines: action.payload,
        }),
        [actions.clearAdminInvoiceSummary]: (state) => ({
            ...state,
            invoiceSummary: undefined,
        }),

        [actions.fetchingAdminInvoiceSummaryPending]: (state) => ({
            ...state,
            fetchingAdminInvoiceSummaryResult: undefined,
            fetchingAdminInvoiceSummary: true,
        }),
        [actions.fetchingAdminInvoiceSummaryCompleted]: (state, action) => ({
            ...state,
            fetchingAdminInvoiceSummaryResult: apiCallSuccess,
            fetchingAdminInvoiceSummary: false,
            invoiceSummary: action.payload,
        }),
        [actions.fetchingAdminInvoiceSummaryError]: (state) => ({
            ...state,
            fetchingAdminInvoiceSummaryResult: apiCallError,
            fetchingAdminInvoiceSummary: false,
        }),
        CLEAR_STATE: () => defaultInvoiceState,
    },
    defaultInvoiceState
);

const defaultAssignmentState = {
    fetchingAssignmentsData: false,
    fetchAssignmentDataResults: undefined,
    assignmentsData: undefined,
    fetchingAssignmentsMetadata: false,
    fetchAssignmentMetadataResults: undefined,
    assignmentsMetadata: undefined,
};

const assignmentsReducer = handleActions(
    {
        [actions.fetchAssignmentsDataPending]: (state) => ({
            ...state,
            fetchingAssignmentsData: true,
            fetchAssignmentDataResults: undefined,
        }),
        [actions.fetchAssignmentsDataCompleted]: (state, action) => ({
            ...state,
            fetchingAssignmentsData: false,
            fetchAssignmentDataResults: apiCallSuccess,
            assignmentsData: action.payload,
        }),
        [actions.fetchAssignmentsDataError]: (state) => ({
            ...state,
            fetchingAssignmentsData: false,
            fetchAssignmentDataResults: apiCallError,
        }),

        [actions.fetchAssignmentsMetadataPending]: (state) => ({
            ...state,
            fetchingAssignmentsMetadata: true,
            fetchAssignmentMetadataResults: undefined,
        }),
        [actions.fetchAssignmentsMetadataCompleted]: (state, action) => ({
            ...state,
            fetchingAssignmentsMetadata: false,
            fetchAssignmentMetadataResults: apiCallSuccess,
            assignmentsMetadata: action.payload,
        }),
        [actions.fetchAssignmentsMetadataError]: (state) => ({
            ...state,
            fetchingAssignmentsMetadata: false,
            fetchAssignmentMetadataResults: apiCallError,
        }),

        CLEAR_STATE: () => defaultAssignmentState,
    },
    defaultAssignmentState
);

const defaultSmsState = {
    sendingSms: false,
    sendSmsResult: undefined,
};

const smsReducer = handleActions(
    {
        [actions.sendSmsPending]: (state) => ({
            ...state,
            sendingSms: true,
            sendSmsResult: undefined,
        }),
        [actions.sendSmsCompleted]: (state) => ({
            ...state,
            sendingSms: false,
            sendSmsResult: apiCallSuccess,
        }),
        [actions.sendSmsError]: (state) => ({
            ...state,
            sendingSms: false,
            sendSmsResult: apiCallError,
        }),
        CLEAR_STATE: () => defaultSmsState,
    },
    defaultSmsState
);

const reducer = combineReducers({
    reservation: reservationReducer,
    reservationExpenses: reservationExpensesReducer,
    billing: billingReducer,
    locations: locationsReducer,
    invoice: invoiceReducer,
    invoiceLines: invoiceLineReducer,
    membership: membershipReducer,
    person: personReducer,
    car: carReducer,
    entityMessage: entityMessageReducer,
    assignments: assignmentsReducer,
    model: modelReducer,
    sms: smsReducer,
    damage: damageReducer,
    situation: situationReducer,
    specificSolutions: specificSolutionsReducer,
    membershipPreview: membershipPreviewReducer,
});

export default reducer;
