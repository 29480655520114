import React from 'react';
import { PublicId } from '../../types/common';
import { RouterLocation } from '../../types/application';
import { ReactNode } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { State } from '../../../state/store';
import {
    selectAdmin,
    selectAuthentication,
    selectSelectedOrganization,
} from '../../duck/selectors';
import { activateUpdate, isUpdateReady } from '../../../utilities/updateUtils';
import LoadingPage from '../LoadingPage/LoadingPage';

type Props = {
    path: string;
    render: (location: RouterLocation) => ReactNode;
    exact?: boolean;
    requireAdmin?: boolean;
    requireAuthentication?: boolean;
    redirectUrl?: string;

    auth?: string;
    selectedOrgId?: PublicId;
    admin?: boolean;
};

const AppRouteComponent = (props: Props) => {
    if (
        (props.requireAuthentication && !props.auth) ||
        (props.requireAdmin && !props.admin)
    )
        return <Redirect to={'/findcar'} />;

    if (isUpdateReady()) {
        activateUpdate();
        return <LoadingPage />;
    }

    return (
        <Route
            path={props.path}
            key={props.selectedOrgId}
            exact={props.exact}
            render={(location) => props.render(location as RouterLocation)}
        />
    );
};

export const AppRoute = connect((state: State) => ({
    auth: selectAuthentication(state),
    admin: selectAdmin(state),
    selectedOrgId: selectSelectedOrganization(state)?.id,
}))(AppRouteComponent);
