import { State } from '../../../../state/store';
import { Availability, Car, CarPrice } from '../../../types/search';
import {
    CarSearchFilters,
    ConcurrentReservationLimit,
    ExternalLocation,
    PublicId,
} from '../../../types/common';
import {
    ApiCallResult,
    DeleLocation,
    MapPosition,
} from '../../../types/application';
import { EntityMessage } from '../../../types/admin';
import { Moment } from 'moment';
import { CarComparatorName } from './utils';
import { CityBikeSpot } from '../../../../utilities/types';

export const selectSearchParams = (state: State) => state.searchReducer.search;
export const selectFetchingCars = (state: State): boolean =>
    state.searchReducer.searchData.fetchingCars;

export const selectCheckingConcurrentReservationLimit = (
    state: State
): boolean => state.searchReducer.searchData.checkingConcurrentReservationLimit;
export const selectCheckConcurrentReservationLimitResult = (
    state: State
): ApiCallResult =>
    state.searchReducer.searchData.checkConcurrentReservationLimitResult;
export const selectConcurrentReservationLimitResponse = (
    state: State
): ConcurrentReservationLimit | undefined =>
    state.searchReducer.searchData.concurrentReservationLimitResponse;

export const selectMessages = (state: State): EntityMessage[] =>
    state.searchReducer.searchData.messages;

export const selectFetchingSwappableCars = (state: State): boolean =>
    state.searchReducer.searchData.fetchingSwappableCars;
export const selectFetchSwappableCarsResult = (state: State): ApiCallResult =>
    state.searchReducer.searchData.fetchSwappableCarsResult;

export const selectSwappableCars = (state: State): Car[] =>
    state.searchReducer.searchData.swappableCars;

export const selectCars = (state: State): Car[] =>
    state.searchReducer.searchData.cars;

export const selectShowingSwapCarOptions = (state: State): boolean =>
    state.searchReducer.searchData.showingSwapCarOptions;

export const selectGpsPositionDisabled = (state: State): boolean =>
    state.searchReducer.search.gpsPositionDisabled;
export const selectGettingGpsPosition = (state: State): boolean =>
    state.searchReducer.search.gettingGpsPosition;

export const selectShouldShowCarFilterView = (state: State): boolean =>
    state.searchReducer.ui.showCarFilterView;

export const selectLocationSearchString = (state: State): string =>
    state.searchReducer.searchData.locationSearchString;
export const selectPreviouslySelectedLocations = (
    state: State
): DeleLocation[] => state.searchReducer.searchData.previouslySelectedLocations;
export const selectFetchingExternalLocations = (state: State): boolean =>
    state.searchReducer.searchData.fetchingExternalLocations;

export const selectExternalLocations = (state: State): ExternalLocation[] => {
    return state.searchReducer.searchData.externalLocations.map(
        (location: ExternalLocation) => {
            return { ...location };
        }
    );
};

export const selectStartTime = (state: State): Moment | undefined =>
    state.searchReducer.search.startTime;
export const selectEndTime = (state: State): Moment =>
    state.searchReducer.search.endTime;

export const selectSelectedPosition = (
    state: State
): DeleLocation | undefined => state.searchReducer.search.selectedPosition;

export const selectFilters = (state: State): CarSearchFilters | undefined =>
    state.searchReducer.search.filters;

export const selectFiltersAreActive = (state: State): boolean =>
    state.searchReducer.search.filtersAreActive;

export const selectHideUnavailableCars = (state: State): boolean =>
    state.searchReducer.search.hideUnavailableCars;

export const selectPriceOfCurrentCar = (state: State): CarPrice | undefined =>
    state.searchReducer.searchData.priceOfCurrentCar;

export const selectFetchingPriceOfCurrentCar = (state: State): boolean =>
    state.searchReducer.searchData.fetchingPriceOfCurrentCar;

export const selectSelectedCar = (state: State): Car | undefined => {
    let id = selectSelectedCarId(state);
    return state.searchReducer.searchData.cars.find(
        (car: Car) => car.id === id
    );
};

export const selectSelectedCarId = (state: State): PublicId | undefined =>
    state.searchReducer.search.selectedCarId;

export const selectHasSelectedCar = (state: State): boolean =>
    !!state.searchReducer.search.selectedCarId;

export const selectSortingParameterPriority = (
    state: State
): CarComparatorName[] => state.searchReducer.ui.sortingPriority;

export const selectMapPosition = (state: State): MapPosition | undefined =>
    state.searchReducer.ui.mapPosition;

export const selectShowCityBikes = (state: State): boolean =>
    state.searchReducer.ui.showCityBikes;
export const selectCityBikes = (state: State): CityBikeSpot[] | undefined =>
    state.searchReducer.ui.cityBikes;

export const selectReservingCar = (state: State): boolean =>
    state.searchReducer.searchData.reservingCar;

export const selectReservingCarResult = (state: State) =>
    state.searchReducer.searchData.reservingCarResult;

// Availability state
export const selectInitializingAvailability = (state: State): boolean =>
    state.searchReducer.availability.initializingAvailability;

export const selectAvailabilityStartTime = (state: State): undefined | Moment =>
    state.searchReducer.availability.startTime;
export const selectAvailabilityEndTime = (state: State): undefined | Moment =>
    state.searchReducer.availability.endTime;

export const selectIsAvailable = (state: State): boolean =>
    state.searchReducer.availability.isAvailable;

export const selectAvailablePeriods = (state: State): Availability[] =>
    state.searchReducer.availability.availablePeriods;

export const selectAvailabilityReservedDays = (state: State): Moment[] =>
    state.searchReducer.availability.reservedDays;

export const selectFetchingAvailabilityReservations = (state: State): boolean =>
    state.searchReducer.availability.fetchingReservations;

export const selectFetchingAvailabilityInfo = (state: State): boolean =>
    state.searchReducer.availability.fetchingAvailabilityInfo;

export const selectSuperDamageWaiver = (state: State): boolean =>
    state.searchReducer.search.superDamageWaiver;

export const selectKilometerEstimate = (state: State): string | undefined =>
    state.searchReducer.search.kilometerEstimate;
