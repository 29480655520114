import { createApi } from '@reduxjs/toolkit/query/react';
import {
    adminListQuery,
    authenticatedQuery,
    idQuery,
    metadataQuery,
} from '../../../../../utilities/RTK/RTKUtils';
import { CursorPaginatedResult } from '../../../../../utilities/RTK/RTKTypes';
import {
    MemberCard,
    MemberCardIndex,
    Metadata,
    PersonIndex,
} from '../../../../types/admin';
import { AdminListQueryParams } from '../../../../common/AdminList/AdminListRTK';
import { PublicId } from '../../../../types/common';

export const memberCardApi = createApi({
    reducerPath: 'admin-membercard',
    baseQuery: authenticatedQuery('/api/admin/member-cards'),
    endpoints: (builder) => ({
        getMemberCards: builder.query<
            CursorPaginatedResult<MemberCardIndex>,
            AdminListQueryParams
        >({
            query: adminListQuery,
        }),
        getMemberCardsMetadata: builder.query<Metadata<MemberCardIndex>, void>({
            query: metadataQuery,
        }),
        getMemberCard: builder.query<MemberCard, PublicId>({
            query: idQuery,
        }),
        setMemberCardPerson: builder.mutation<
            void,
            { cardId: string; person: PersonIndex }
        >({
            query: (args) => ({
                url: `${args.cardId}/person`,
                method: 'PUT',
                body: args.person,
            }),
        }),
        deleteMemberCardPerson: builder.mutation<void, string>({
            query: (cardId) => ({
                url: `${cardId}/person`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetMemberCardsQuery,
    useGetMemberCardsMetadataQuery,
    useGetMemberCardQuery,
    useSetMemberCardPersonMutation,
    useDeleteMemberCardPersonMutation,
} = memberCardApi;
