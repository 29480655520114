import {
    BaseQueryApi,
    BaseQueryFn,
    FetchArgs,
} from '@reduxjs/toolkit/dist/query/react';
import {
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { selectAuthentication } from '../../app/duck/selectors';
import { setShouldAuthenticate } from '../../app/duck/operations';
import { AdminListQueryParams } from '../../app/common/AdminList/AdminListRTK';
import { apiCallSize } from '../constants';
import { State } from '../../state/store';
import { PublicId } from '../../app/types/common';

export const authHeaders = (
    headers: Headers,
    api: Pick<
        BaseQueryApi,
        'getState' | 'extra' | 'endpoint' | 'type' | 'forced'
    >
) => {
    const auth = selectAuthentication(api.getState() as State);

    if (auth) {
        headers.set('authorization', auth);
    }
    headers.set('content-type', 'application/json');
    return headers;
};

export const unauthenticatedQuery = (
    baseUrl: string
): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> => {
    return fetchBaseQuery({
        baseUrl: baseUrl,
    });
};

export const authenticatedQuery = (
    baseUrl: string
): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> => {
    return async (args, api, extraOptions) => {
        const baseQuery = fetchBaseQuery({
            baseUrl,
            prepareHeaders: authHeaders,
        });
        const result = await baseQuery(args, api, extraOptions);

        if (result.error && result.error.status === 200) {
            api.dispatch(setShouldAuthenticate(true));
        }

        return result;
    };
};

export function metadataQuery(): string {
    return '/metadata';
}

export function idQuery(id: PublicId): string {
    return id;
}

export function constantQuery(): string {
    return '';
}

export function adminListQuery({
    cursor,
    orgId,
    filter,
}: AdminListQueryParams) {
    let ret = `?size=${apiCallSize}`;
    if (cursor) ret += `&cursor=${cursor}`;
    if (orgId) ret += `&orgId=${orgId}`;
    if (filter) ret += filter;
    return ret;
}
