import { Car } from '../../../types/search';

export type CarComparatorName =
    | 'MAX_AVAILABILITY'
    | 'PROXIMITY'
    | 'LOCATION'
    | 'PRICE';

// The return number should always be either -1, 0, or 1, but specifying that in the type system is challenging.
export type CarComparator = (car1: Car, car2: Car) => number;

export const MAX_AVAILABILITY: CarComparatorName = 'MAX_AVAILABILITY';
export const PROXIMITY: CarComparatorName = 'PROXIMITY';
export const LOCATION: CarComparatorName = 'LOCATION';
export const PRICE: CarComparatorName = 'PRICE';

export function sortCars(cars: Car[], prio: CarComparatorName[]) {
    const prioritizedComparisonFunctions: CarComparator[] = [];
    prio.forEach((sortingParameterKey) => {
        const compFunc = getCompareFunction(sortingParameterKey);
        if (!!compFunc) {
            prioritizedComparisonFunctions.push(compFunc);
        }
    });

    return cars.sort(getTieredCarComparison(prioritizedComparisonFunctions));
}

function getCompareFunction(sortingParameter: CarComparatorName) {
    switch (sortingParameter) {
        case MAX_AVAILABILITY:
            return compareCarsByMaxAvailability;
        case PRICE:
            return compareCarsByPrice;
        case PROXIMITY:
            return compareCarsByProximity;
        case LOCATION:
            return compareCarsByLocation;
        default:
            console.warn('Attempted to access undefined sorting function.');
    }
}

function getTieredCarComparison(compFuncs: CarComparator[]) {
    return (car1: Car, car2: Car) => {
        let result = 0;
        for (let i = 0; i < compFuncs.length && result === 0; i++) {
            result = compFuncs[i](car1, car2);
        }
        return result;
    };
}

function compareCarsByProximity(car1: Car, car2: Car) {
    if (car1.location.distance < car2.location.distance) return -1;
    if (car1.location.distance > car2.location.distance) return 1;
    return 0;
}

function compareCarsByLocation(car1: Car, car2: Car) {
    if (car1.location.id < car2.location.id) return -1;
    if (car1.location.id > car2.location.id) return 1;
    return 0;
}

function compareCarsByMaxAvailability(car1: Car, car2: Car) {
    if (car1.maxAvailability < car2.maxAvailability) return 1;
    if (car1.maxAvailability > car2.maxAvailability) return -1;
    return 0;
}

function compareCarsByPrice(car1: Car, car2: Car) {
    if (car1.hourlyRate < car2.hourlyRate) return -1;
    if (car1.hourlyRate > car2.hourlyRate) return 1;
    return 0;
}
