import { PublicId } from '../../types/common';

export const enum Read {
    MEMBERSHIP = 'MEMBERSHIP_READ',
    RESERVATION = 'RESERVATION_READ',
    BILLING = 'BILLING_READ',
    LIMITED_BILLING = 'LIMITED_BILLING_READ',
    FLEET_MANAGEMENT = 'FLEET_MANAGEMENT_READ',
    ADMIN_SETTINGS = 'ADMIN_SETTINGS_READ',
    FEATURE_TOGGLE = 'FEATURE_TOGGLE_READ',
    HELP = 'HELP_READ',
    MESSAGE = 'MESSAGE_READ',
}

export const enum Write {
    MEMBERSHIP = 'MEMBERSHIP_WRITE',
    RESERVATION = 'RESERVATION_WRITE',
    BILLING = 'BILLING_WRITE',
    LIMITED_BILLING = 'LIMITED_BILLING_WRITE',
    FLEET_MANAGEMENT = 'FLEET_MANAGEMENT_WRITE',
    ADMIN_SETTINGS = 'ADMIN_SETTINGS_WRITE',
    FEATURE_TOGGLE = 'FEATURE_TOGGLE_WRITE',
    HELP = 'HELP_WRITE',
    MESSAGE = 'MESSAGE_WRITE',
}

export type Permission = Read | Write;

export type GrantedPermissions = {
    organizationId: string;
    organizationName: string;
    permissions: Permission[];
};

export function hasPermission(
    granted: GrantedPermissions[],
    require: Permission,
    authorizedById?: PublicId
) {
    return hasAnyPermission(granted, require, authorizedById);
}

export function hasAnyPermission(
    granted: GrantedPermissions[] | undefined,
    requireAny: Permission | Permission[],
    authorizedById?: PublicId
) {
    const req = Array.isArray(requireAny) ? requireAny : [requireAny];
    const perms = (granted || [])
        .filter((o) => o.organizationId === authorizedById)
        .flatMap((o) => o.permissions);
    return req.reduce((ps, p) => ps || perms.includes(p), false);
}

export function isDebugMode() {
    return localStorage.getItem('debugMode') === 'true';
}

export function toggleDebugMode() {
    localStorage.setItem('debugMode', `${!isDebugMode()}`);
}
