import React from 'react';
import { ReactComponent as CrashIcon } from '../../common/icon/crash.svg';
import { Theme } from '../../../utilities/types';
import { RouterLocation } from '../../types/application';
import { clearErrors } from './duck/reducer';
import { selectUnauthorizedUrl } from './duck/selectors';
import {
    selectAdmin,
    selectOrganizations,
    selectSelectedMembership,
    selectTheme,
} from '../../duck/selectors';
import useEffectOnce from '../../../utilities/UseEffectOnce';
import { formatPhoneNumber } from '../../../utilities/utils';
import { phoneNumberToTelLink } from '../../../utilities/formatingUtils';
import { useAppSelector, useAppDispatch } from '../../../state/hooks';
import { H2 } from '../../common/Typography';

type ErrorMessageProps = {
    theme: Theme;
    icon?: boolean;
    heading: string;
    body: React.ReactNode;
    children?: React.ReactNode;
};

const ErrorMessage = ({
    theme,
    heading,
    body,
    icon = false,
    children,
}: ErrorMessageProps) => (
    <div className={'errorPage u-fullScreen'}>
        <H2 className={'errorPage__errorHeading'}>{heading}</H2>
        {icon ? (
            <div className="errorPage__icon">
                <CrashIcon />
            </div>
        ) : null}
        <div style={theme?.text?.body2} role="alert">
            {body}
        </div>
        {children}
    </div>
);

type ErrorPageProps = {
    location?: RouterLocation;
    type: ErrorKind;
};

type ErrorKind =
    | 'ERROR_BOUNDARY'
    | 'UNKNOWN_URL'
    | 'UNKNOWN_LOCATION_ID'
    | 'EXTENSION_ERROR'
    | 'EDIT_NOTE_ERROR'
    | 'UNAUTHORIZED';

const ErrorPage = ({ type, location }: ErrorPageProps) => {
    const dispatch = useAppDispatch();
    useEffectOnce(() => {
        // @ts-ignore
        window.ga('set', 'page', '/errorPage');
        // @ts-ignore
        window.ga('send', 'pageview');
        return () => {
            dispatch(clearErrors());
        };
    });
    const unauthorizedUrl = useAppSelector(selectUnauthorizedUrl);
    const isAdmin = useAppSelector(selectAdmin);
    const membership = useAppSelector(selectSelectedMembership);
    const theme = useAppSelector(selectTheme);
    const orgs = useAppSelector(selectOrganizations);
    const org = orgs.find((o) => o.name === membership?.organizationName);

    switch (type) {
        case 'ERROR_BOUNDARY':
            return (
                <ErrorMessage
                    theme={theme}
                    heading={'Oida, her skjedde det visst en feil!'}
                    icon={true}
                    body={
                        'Vi beklager så mye! Forsøk å ta en refresh av nettsiden, om det fortsatt ikke fungerer har vi mest sannsynlig et større problem som vi håper å få løst innen kort tid. Om du har lyst kan du kan kontakt med oss på telefon så hjelper vi deg så godt vi kan.'
                    }
                >
                    <a href="/findcar" style={{ marginTop: '16px' }}>
                        Klikk her for å gå til forsiden
                    </a>
                </ErrorMessage>
            );
        case 'UNKNOWN_URL':
            return (
                <ErrorMessage
                    theme={theme}
                    heading={`404 Ukjent URL (${location?.history.createHref(
                        location.location
                    )})`}
                    body={'Denne url-en er dessverre ukjent for oss'}
                >
                    <a href="/findcar" style={{ marginTop: '16px' }}>
                        Klikk her for å gå til forsiden
                    </a>
                </ErrorMessage>
            );
        case 'UNKNOWN_LOCATION_ID':
            return (
                <ErrorMessage
                    theme={theme}
                    heading={'Ukjent lokasjonsID'}
                    body={'Finner dessverre ikke denne lokasjonen'}
                />
            );
        case 'EXTENSION_ERROR':
            return (
                <ErrorMessage
                    theme={theme}
                    heading={'Noe gikk galt!'}
                    body={
                        'Vi fikk ikke til å utvide reservasjonen. Vennligst prøv igjen senere.'
                    }
                />
            );
        case 'EDIT_NOTE_ERROR':
            return (
                <ErrorMessage
                    theme={theme}
                    heading={'Noe gikk galt!'}
                    body={
                        'Vi fikk ikke til å endre notatet. Vennligst prøv igjen senere.'
                    }
                />
            );
        case 'UNAUTHORIZED':
            return (
                <ErrorMessage
                    theme={theme}
                    heading={`403 Uautorisert spørring ${
                        unauthorizedUrl ? `(${unauthorizedUrl})` : ''
                    }`}
                    body={
                        <>
                            <p>
                                Oi da, her har det skjedd noe feil! Du har ikke
                                tillatelse til å se eller gjøre dette.
                            </p>
                            <p>
                                Dobbeltsjekk at linken er riktig, at du er
                                logget inn som riktig bruker, har valgt riktig
                                organisasjon, etc.
                            </p>
                            <p>
                                Om problemet vedvarer, og du mener dette er
                                feil, kan du kontakte medlemsstøtte.
                            </p>
                            {org && (
                                <div
                                    style={{
                                        paddingTop: '1rem',
                                        lineHeight: '1.5em',
                                    }}
                                >
                                    <p>Kontaktinfo til {org.name}:</p>
                                    <p>
                                        Telefon:{' '}
                                        <a
                                            href={phoneNumberToTelLink(
                                                org.supportPhone
                                            )}
                                        >
                                            {formatPhoneNumber(
                                                org.supportPhone
                                            )}
                                        </a>
                                    </p>
                                    <p>
                                        Mail:{' '}
                                        <a href={`mailto:${org.supportEmail}`}>
                                            {org.supportEmail}
                                        </a>
                                    </p>
                                </div>
                            )}
                        </>
                    }
                >
                    <a
                        style={{ paddingTop: '1rem' }}
                        href={isAdmin ? '/admin' : '/findcar'}
                        onClick={() => dispatch(clearErrors())}
                    >
                        Klikk her for å gå tilbake oversikten
                    </a>
                </ErrorMessage>
            );
        default:
            return (
                <ErrorMessage
                    theme={theme}
                    heading={'Ukjent feil'}
                    body={'Ukjent feil'}
                />
            );
    }
};

export default ErrorPage;
